<div class="form-fields-box filter" [ngClass]="!filter ? 'closed' : ''">
    <h2 (click)="Filter()">{{'imports-csv.parentTitle' | translate}} </h2>
    <hr [ngClass]="!filter ? 'hr-open' : ''" />

    <div class="form-step">
        <div class="circle-number circle-number-ready">1</div>
        <mat-form-field class="parameter" appearance="outline">
            <mat-label>{{'imports-csv.parentTitle' | translate}}</mat-label>
            <mat-select [(ngModel)]="typesFilter" (selectionChange)="Router([value.value])" #value>
                <mat-option class="form-control" value="/importsCSV">{{'imports-csv.select-csv-form-none' |
                    translate}}</mat-option>
                <mat-option class="form-control" value="importsCSV/installationsImport/equipment">
                    {{'imports-csv.select-csv-form-installations-link-equipment' | translate}}
                </mat-option>
                <mat-option class="form-control" value="importsCSV/installationsImport/installations-create">
                    {{'imports-csv.select-csv-form-installations-create' | translate}}
                </mat-option>
                <mat-option class="form-control" value="importsCSV/installationsImport/installations-update">
                    {{'imports-csv.select-csv-form-installations-update' | translate}}
                </mat-option>
                <mat-option class="form-control" value="importsCSV/installationsImport/installations-inactivate">
                    {{'imports-csv.select-csv-form-inactivate-installations' | translate}}
                </mat-option>
                <mat-option class="form-control" value="importsCSV/equipmentsImport/equipments-create">
                    {{'imports-csv.select-csv-form-equipments-create' | translate}}
                </mat-option>

                <mat-option class="form-control" value="importsCSV/equipmentsImport/equipments-update">
                    {{'imports-csv.select-csv-form-equipment-update' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
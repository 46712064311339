<div class="main-container">
  <div class="col-lg-7 left-panel">
    <div class="row left-panel-content">
      <div class="col-md-12 logo-sg">
        <img src="../../../../assets/imgs/logo-sg" class="logo-sg-img" alt="">
      </div>
      <hr class="divider">
      <form class="login-form">
        <img src="" alt="">
        <input [formControl]="inputUser" type="text" id="email" placeholder="Usuário" (keydown.enter)="login()">
        <div class="password-field">
          <input [formControl]="inputPassword" (keydown.enter)="login()"
            [type]="isShowingPassword ? 'text' : 'password'" id="password" placeholder="Senha" class="passw-input">

          <mat-icon class="eye-icon" (click)="showPassword()" *ngIf="!isShowingPassword"
            svgIcon="bx-show-green"></mat-icon>

          <mat-icon class="eye-icon" (click)="showPassword()" *ngIf="isShowingPassword"
            svgIcon="bx-hide-green"></mat-icon>


        </div>
        <div class="buttons">
          <mat-spinner *ngIf="loading$ | async; else buttonBox" [diameter]="50"></mat-spinner>
          <ng-template #buttonBox>
            <button type="button" class="btn btn-primary" id="loginBtn" (click)="login()">Entrar</button>
            <p>Recuperar senha</p>
          </ng-template>
        </div>
        <p *ngIf="error$" class="error-message">{{error$ | async | grahpqlErrorLib}}</p>
      </form>
    </div>
  </div>
  <div class="col-lg-5 right-panel">
    <div class="right-panel-text">
      <h1>Telegestão de iluminação pública</h1>
      <p>Automação da gestão de iluminação
        pública com redução de custos operacionais
        e redução do consumo de energia.</p>
    </div>
    <div class="round-box1">
    </div>
    <div class="circle1">
    </div>
    <div class="round-box2">
    </div>
    <div class="circle2">
    </div>
    <div class="triangle">
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_HARDWARES, CREATE_HARDWARES, GET_ALL_FIRMWARES, CREATE_NEW_COMPATIBLE_FIRMWARE } from 'src/app/graphql/hardwares.queries';


@Injectable({
  providedIn: 'root'
})
export class HardwareService {

  constructor(
    private apollo: Apollo) { }

  /** Requisição do filtro de Hardwares **/
  filterHardwares(
    name: string | null,
    major: number | null,
    minor: number | null,
    revision: number | null,
    cursor: string | null,
    pageSize: number | null
  ): any {
    /** Retorna a resposta da requisição **/
    return this.apollo.watchQuery({
      query: FILTER_HARDWARES,
      fetchPolicy: 'network-only',
      variables: {
        major,
        minor,
        name,
        revision,
        pageSize,
        cursor
      }
    })
  }

  /** Requisição responsável por criar Hardwares **/
  createHardware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any,
    compatibleHardwares: string[]
  ) {
    /** Envia a requisição **/
    return this.apollo.mutate({
      mutation: CREATE_HARDWARES,
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate,
        compatibleHardwares
      }
    })
  }

  /** Requisição que retorna a lista de todos os Firmwares **/
  getAllFirmwares(): any {
    /** Envia a requisição **/
    return this.apollo.watchQuery({
      query: GET_ALL_FIRMWARES,
      fetchPolicy: 'network-only',
      variables: {
        pageSize: 100
      }
    })
  }

  /** Requisição para criar um novo Firmware compatível **/
  createNewCompatibleFirmware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any
  ) {
    /** Inicia a requisição **/
    return this.apollo.mutate({
      mutation: CREATE_NEW_COMPATIBLE_FIRMWARE,
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate,
      }
    })
  }
}
import { gql } from 'apollo-angular';

const LOGIN_QUERY = gql`
  mutation Login(
    $username: String!,
    $password: String!

  ){
    login(input: {
      username: $username,
      password: $password

     }){
      user {
        id
        preferences
        email
        token
        password
        username
        expiredPassword
        geoserverPassword
      }
    }
  }
`;

const GET_USER_COMPANIES = gql`
  query All($username: String) {
    user(username: $username) {
      edges {
        node {
          id
          username
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_INSTALLATIONS_BY_COMPANY_ID = gql`
  query MapNodes(
    $first: Int
    $cursor: String
    $company: ID!
    #$installations: [ID]
    $applications: [ApplicationTags]
    $latitude: Float
    $longitude: Float
    $radius: Float
    $street: String
    $district: String
    $city: String
    $state: String
    $country: String
    $gateway: ID
    $equipmentTypes: [ID]
    $locationCode: String
    $referenceContains: String
  ) {
    mapNodes(
      first: $first
      after: $cursor
      company: $company
      #installations: $installations
      applications: $applications
      latitude: $latitude
      longitude: $longitude
      radius: $radius
      street: $street
      district: $district
      city: $city
      state: $state
      country: $country
      gateway: $gateway
      equipmentTypes: $equipmentTypes
      locationCode: $locationCode
      referenceContains: $referenceContains
    ) {
      count
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          reference
          indicators {
            status
            lastTransmissionTimestamp
            lastPowerOnTimestamp
            lastPowerOffTimestamp
          }
          site {
            #timezone
            geoposition {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

const GET_GATEWAY_BY_COMPANY_ID = gql`
  query gateway($first: Int, $cursor: String, $company: ID!) {
    mapNodes(first: $first, after: $cursor, company: $company) {
      count
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          gateway {
            id
            reference
          }
        }
      }
    }
  }
`;

const GET_EQUIPMENT_TYPE_BY_COMPANY_ID = gql`
  query equipmentType {
    equipmentType {
      count
      edges {
        node {
          id
          reference
        }
      }
    }
  }
`;

const USER_PROFILE_UPDATE_MUTATION = gql`
  mutation UserProfileUpdate(
    $firstName: String
    $username: String
    $email: String
  ) {
    userProfileUpdate(
      input: { firstName: $firstName, username: $username, email: $email }
    ) {
      user {
        id
      }
    }
  }
`;
const USER_INFO_QUERY = gql`
  query User($username: String) {
    user(username: $username) {
      edges {
        node {
          id
          username
          firstName
          email
          isActive
        }
      }
    }
  }
`;

const GET_ALL_REPORTS = gql`
  query All(
    $company: ID!
    $reference: String
    $reportType: PromiseReportType
    $requestedBy: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    promiseReports(
      company: $company
      reference_Icontains: $reference
      reportType: $reportType
      requestedBy_Icontains: $requestedBy
      First: $first
      After: $after
      Before: $before
      Last: $last
      orderBy: { direction: DESC, field: GENERATION_DATE }
    ) {
      count
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          generationDate
          requestedBy
          reference
          reportType
          ready
        }
      }
    }
  }
`;

const LAST_TRANSMISSION_MUTATION = gql`
  mutation LastTransmissionReport($company: ID!, $reference: String!) {
    generateLastTransmissionReport(
      input: { company: $company, reference: $reference }
    ) {
      reportPromise {
        id
        ready
        reportType
        ready
        generationDate
        requestedBy
        reference
      }
    }
  }
`;

const REGISTER_INSTALLATIONS_REPORT = gql`
  mutation installationsListReport(
    $reference: String!
    $company: ID!
    ){
      generateInstallationLogs (input:{
      reference: $reference
      company: $company
      }){
        reportPromise{
          id
          generationDate
          requestedBy
          reference
          reportType
          ready
      }
    }
  }
`;

const LAST_DATA_MUTATION = gql`
  mutation generateLastDataReport (
    $company: ID!
    $reference: String!
  ) {
    generateLastDataReport(input:{
      company: $company
      reference: $reference
    }){
      reportPromise{
        id
        ready
        reportType
        ready
        generationDate
        requestedBy
        reference
      }
    }
}
`;

const ALERT_MUTATION = gql`
  mutation generateGeneralAlerts(
    $company: ID!
    $reference: String!
    $installations: [ID]
    $startDatetime: DateTime!
    $endDatetime: DateTime!
  ) {
    generateGeneralAlerts(
      input: {
        company: $company
        reference: $reference
        installations: $installations
        startDatetime: $startDatetime
        endDatetime: $endDatetime
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const INDIVIDUAL_CONSUMPTION_MUTATION = gql`
  mutation IndividualConsumption(
    $company: ID!
    $reference: String!
    $macAddress: String!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
    $kind: IndividualConsumptionKind!
  ) {
    generateIndividualConsumption(
      input: {
        macAddress: $macAddress
        company: $company
        reference: $reference
        startDatetime: $startDatetime
        endDatetime: $endDatetime
        kind: $kind
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const INDIVIDUAL_READ_CONSUMPTION_MUTATION = gql`
  mutation IndividualConsumption(
    $company: ID!
    $reference: String!
    $macAddress: String!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
  ) {
    generateReadIndividualConsumption(
      input: {
        macAddress: $macAddress
        company: $company
        reference: $reference
        startDatetime: $startDatetime
        endDatetime: $endDatetime
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const INDIVIDUAL_PULSE_CONSUMPTION_MUTATION = gql`
  mutation IndividualConsumption(
    $company: ID!
    $reference: String!
    $macAddress: String!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
  ) {
    generatePulseIndividualConsumption(
      input: {
        macAddress: $macAddress
        company: $company
        reference: $reference
        startDatetime: $startDatetime
        endDatetime: $endDatetime
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const CIRCUIT_BOX_CONSUMPTION_MUTATION = gql`
mutation CircuitBoxConsumption(
  $company: ID!
  $reference: String!
  $macAddress: String!
  $startDatetime: DateTime!
  $endDatetime: DateTime!
) {
  generateCircuitBoxConsumption(
    input: {
      macAddress: $macAddress
      company: $company
      reference: $reference
      startDatetime: $startDatetime
      endDatetime: $endDatetime
    }
  ) {
    reportPromise {
      id
      generationDate
      requestedBy
      reference
      reportType
      ready
    }
  }
}
`;

const INDIVIDUAL_STATUS_MUTATION = gql`
  mutation generateIndividualStatus(
    $company: ID!
    $reference: String!
    $macAddress: String!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
  ) {
    generateIndividualStatus(
      input: {
        macAddress: $macAddress
        company: $company
        reference: $reference
        startDatetime: $startDatetime
        endDatetime: $endDatetime
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const EVENTS_REPORT_MUTATION = gql`
  mutation EventsReport(
    $company: ID!
    $reference: String!
    $macAddress: String!
    $startDatetime: DateTime!
    $endDatetime: DateTime!
  ) {
    generateInstallationEventsReport(
      input: {
        macAddress: $macAddress
        company: $company
        reference: $reference
        startDatetime: $startDatetime
        endDatetime: $endDatetime
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const METEOROLOGICAL_REPORT_MUTATION = gql`
  mutation meteorologicalReport(
    $reference: String!
    $company: ID!
    $macAddress: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    generateWeatherTelemetry(
      input: {
        reference: $reference
        company: $company
        deviceMac: $macAddress
        startDatetime: $startDate
        endDatetime: $endDate
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const COMMAND_LIST_REPORT_MUTATION = gql`
  mutation CommandList(
    $reference: String!
    $company: ID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    generateCommandsList(
      input: {
        reference: $reference
        company: $company
        startDatetime: $startDate
        endDatetime: $endDate
      }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const INSTALLATION_LIST_REPORT = gql`
  mutation installationsListReport($reference: String!, $company: ID!) {
    generateInstallationsList(
      input: { reference: $reference, company: $company }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const NO_TRANSMISSION_REPORT_MUTATION = gql`
  mutation noTransmissionReport(
    $company: ID!
    $reference: String!
    $hours: Int!
  ) {
    generateNoTransmissionDevices(
      input: { company: $company, reference: $reference, hours: $hours }
    ) {
      reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const FIRST_ON_FIRST_OFF_REPORT_MUTATION = gql`
  mutation FOFO(
    $reference: String!
    $company: ID!
    $targetDate: Date!
  ){
    generateFirstOnFirstOff(input:{
      reference: $reference
      company: $company
      targetDate: $targetDate
    }){
      reportPromisse{
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const POSSIBLE_STATUS_REPORT_MUTATION = gql`
  mutation possibleStatus(
    $company: ID!
    $reference: String!
    $targetDate: Date!
  ){
    generatePossibleStatus(input:{
      company: $company
      reference: $reference
      targetDate: $targetDate
    }){
      reportPromisse{
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
      }
    }
  }
`;

const GET_REPORT_DOWNLOAD_URL = gql`
  query generatedReport($report: ID!, $company: ID!) {
    generatedReport(report: $report, company: $company) {
      preSignedUrl
    }
  }
`;

const REPORT_DELETE = gql`
    mutation ReportDelete(
        $company: ID!
        $report: [ID]!
        ) {
        deleteDumpReport(input:{
            company: $company,
        dumpReports: $report
        }
        ){
            result
        }
    }
`;

const GET_INSTALLATION_BY_REFERENCE = gql`
  query Installation($company: ID!, $referenceContains: String) {
    installation(company: $company, referenceContains: $referenceContains) {
      edges {
        node {
          id
          reference
          device {
            equipmentType {
              reference
              major
              minor
              revision
            }
            serialNumber
            macAddress
          }
        }
      }
    }
  }
`;

const GET_INDIVIDUAL_CONSUMPTION_CHART = gql`
  query consumption(
    $startDatetime: DateTime!
    $endDatetime: DateTime!
    $deviceMac: String!
    $equipmentType: String!
  ) {
    individualConsumption(
      startDatetime: $startDatetime
      endDatetime: $endDatetime
      deviceMac: $deviceMac
      equipmentType: $equipmentType
      applicationType: LIGHT
    ) {
      date
      total
      paMaxmin
      expectedEnergy
      metadata {
        datetime
        macAddress
        operatingVoltage
        currentConsumption
        powerFactor
        accumulatedEnergy
        proportionalPower
        diff
      }
    }
  }
`;
//Query Usuários
const GET_ALL_USERS = gql`
  query User(
    $company: ID!
    $username_Icontains: String
    $email_Icontains: String
    $isSuperuser: Boolean
    $after: String
    $first: Int
    $before: String
    $last: Int
  ) {
    user(
      isSuperuser: $isSuperuser
      company: $company
      username_Icontains: $username_Icontains
      email_Icontains: $email_Icontains
      after: $after
      first: $first
      before: $before
      last: $last
    ) {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          isActive
          lastLogin
          isSuperuser
          userPermissions {
            edges {
              node {
                id
              }
            }
          }
          groups {
            edges {
              node {
                id
              }
            }
          }
          cpf
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        cursor
      }
      total
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

//Mutation Criar Usuários
const USER_CREATE = gql`
  mutation UserCreate(
    $firstname: String!
    $lastname: String
    $companiesIds: [ID]
    $username: String!
    $password: String!
    $email: String!
    $isSuperuser: Boolean!
  ) {
    userCreate(
      input: {
        firstName: $firstname
        lastName: $lastname
        companiesIds: $companiesIds
        username: $username
        password: $password
        email: $email
        isSuperuser: $isSuperuser
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        lastLogin
        cpf

      }
    }
  }
`;
const GET_ALL_COMPANIES = gql`
  query All {
    company(orderBy: { field: NAME, direction: DESC }) {
      edges {
        node {
          id
          name
          cnpj
        }
      }
    }
  }
`;

const SET_PERMISSION = gql`
  mutation setPermission($userId: ID!, $permissionIds: [ID]!) {
    permissionSet(input: { userId: $userId, permissionIds: $permissionIds }) {
      user {
        id
        userPermissions {
          edges {
            node {
              id
              name
              codename
            }
          }
        }
      }
    }
  }
`;

const REMOVE_PERMISSION = gql`
mutation permissionUnset (
  $userId: ID!,
  $permission_rem: [ID]!,
){
  permissionUnset(input: {
      userId: $userId,
      permissionIds: $permission_rem
  }) {
  user {
      id
      userPermissions {
      edges {
          node {
          id
          name
          codename
          }
      }
      }
  }
  }
}`;

const AVAILABLE_PERMISSIONS = gql`
  {
    availablePermissions {
      edges {
        node {
          id
          name
          codename
        }
      }
    }
  }
`;

const AVAILABLE_GROUPS = gql`
  {
    availableGroups {
      edges {
        node {
          id
          name
          userSet {
            count
          }
        }
      }
    }
  }
`;

const GROUP_SET_USER = gql`
  mutation groupSetUser($id: ID!, $groupIds: [ID]!) {
    groupSetUser(input: { userId: $id, groupIds: $groupIds }) {
      groups {
        id
        name
        permissions {
          edges {
            node {
              id
              name
            }
          }
        }
        userSet {
          edges {
            node {
              username
            }
          }
        }
      }
    }
  }
`;

// Requisição usada para fazer consultas de comandos já enviados (Tela de comandos)
const SEND_QUERY_COMMAND = gql`
  mutation SendQueryCommand(
    $devices: [String]!
    $type: QueryCommandType!
    $requestDt: DateTime!
  ) {
    sendQueryCommand(
      input: { deviceMacs: $devices, type: $type, requestDt: $requestDt }
    ) {
      commandBulkRequest {
        id
        commands {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`;

// requisição usada para fazer consultas de agendamentos ativos (tela de comandos)
const SEND_QUERY_SCHEDULE_COMMAND = gql`
  mutation SendQueryScheduleCommand(
    # $channel: Int,
    $devices: [String]!
    $requestDt: DateTime!
    $scheduleSlot: Int!
  ) {
    sendQueryScheduleCommand(
      input: {
        # channel: $channel,
        deviceMacs: $devices
        requestDt: $requestDt
        scheduleSlot: $scheduleSlot
      }
    ) {
      commandBulkRequest {
        id
        type
        user {
          username
        }
        commands {
          edges {
            node {
              statusDetail
              id
              status
            }
          }
        }
      }
    }
  }
`;

// Requisição de instalações com todos os dados existentes nela (Multiplas telas)
const QUERY_INSTALLATIONS = gql`
  query Installation(
    $first: Int
    $cursor: String
    $company: ID!
    $installations: [ID]
    $applications: [ApplicationTags]
    $gateway: ID
    $equipmentTypes: [ID]
    $locationCode: String
    $referenceContains: String
    $street: String
    $city: String
    $district: String
    $state: String
    $country: String
    $latitude: Float
    $longitude: Float
    $radius: Float
    $devices: [ID]
  ) {
    installation(
      first: $first
      after: $cursor
      company: $company
      installations: $installations
      applications: $applications
      gateway: $gateway
      equipmentTypes: $equipmentTypes
      locationCode: $locationCode
      referenceContains: $referenceContains
      street: $street
      city: $city
      district: $district
      state: $state
      country: $country
      latitude: $latitude
      longitude: $longitude
      radius: $radius
      devices: $devices
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      total
      count
      edges {
        node {
          id
          reference
          applications
          device {
            macAddress
            serialNumber
            equipmentType {
              reference
            }
          }
          ##################
          parentInstallation {
            id
          }
          circuitBox {
            id
            sgIpc {
              id
              macAddress
            }
          }
        }
      }
    }
  }
`;

// Requisição de instalações com todos os dados existentes nela (Multiplas telas)
const GET_CIRCUIT_BOXES = gql`
  query all(
    $company: ID!,
    $referenceContains: String,
    $first: Int,
    $sgIpcMacAddresses: [String]
  ){
    circuitBox(
      company: $company,
      referenceContains:$referenceContains,
      first: $first,
      sgIpcMacAddresses: $sgIpcMacAddresses
    ) {
      edges {
        node {
          id
          reference
          sgIpc{
            id,
            serialNumber,
            macAddress
            equipmentType {
              reference
              major
              minor
              revision
            }
          }
          sgGateway{
            id,
            reference
          }
          sgConsumption{
            id,
            serialNumber
          }
        }
        cursor
      }
      total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
  }
`;


// Mutation para desativar um equipamento por comando (Tela de comandos)
const SEND_SWITCH_ON_COMMAND = gql`
  mutation sendSwitchOnCommand($devices: [String]!, $requestDt: DateTime!) {
    sendSwitchOnCommand(
      input: { deviceMacs: $devices, requestDt: $requestDt }
    ) {
      commandBulkRequest {
        id
        commands {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`;

// Mutation para desativar equipamentos através de comando (Tela de comandos)
const SEND_SWITCH_OFF_COMMAND = gql`
  mutation sendSwitchOffCommand($devices: [String]!, $requestDt: DateTime!) {
    sendSwitchOffCommand(
      input: { deviceMacs: $devices, requestDt: $requestDt }
    ) {
      commandBulkRequest {
        id
        commands {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`;
// Mutation para enviar um novo comando para equipamentos (tela de comandos)
const SEND_SET_PARAMETER_COMMAND = gql`
  mutation SendSetParameterCommand(
    $devices: [String]!
    $parameter: String!
    $requestDt: DateTime!
    $value: GenericScalar!
  ) {
    sendSetParameterCommand(
      input: {
        deviceMacs: $devices
        parameter: $parameter
        value: $value
        requestDt: $requestDt
      }
    ) {
      commandBulkRequest {
        id
        commands {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`;

// Mutation para fazer um agendamento novo (Tela de comandos)
const SET_SCHEDULE = gql`
  mutation setSchedule(
    $channel: Int
    $devices: [String!]!
    $scheduleSlot: Int!
    $dimming: Int!
    $switchOnTime: Time!
    $switchOffTime: Time!
    $requestDt: DateTime!
  ) {
    sendSetScheduleCommand(
      input: {
        channel: $channel
        deviceMacs: $devices
        scheduleSlot: $scheduleSlot
        dimming: $dimming
        switchOnTime: $switchOnTime
        switchOffTime: $switchOffTime
        requestDt: $requestDt
      }
    ) {
      commandBulkRequest {
        id
        type
        user {
          id
          username
        }
        company {
          id
          name
        }
        requestDt
        commands {
          edges {
            node {
              macAddress
              status
              statusDetail
              responseGenerationDt
              responseReceiptDt
              responseData
              commandReference
              parameterReference
              layout
            }
          }
        }
        parameter
        value
        channel
        dimming
        queryType
        scheduleSlot
        switchOffTime
        switchOnTime
      }
    }
  }
`;

// Requisição de Filtrar comandos (Tela de comandos)
const COMMAND_BULK_REQUESTS = gql`
  query CommandBulkRequests(
    $company: ID!
    $commandType: String
    $first: Int
    $last: Int
    $customBefore: String
    $customAfter: String
    $sort_field: CommandBulkRequestOrderingFields!
    $sort_dir: CommandBulkDirection!
    $total:Boolean
  ) {
    commandBulkRequests(
      company: $company
      commandType: $commandType
      first: $first
      last: $last
      customBefore: $customBefore
      customAfter: $customAfter
      orderBy: { direction: $sort_dir, field: $sort_field }
      total:$total
    ) {
      edges {
        node {
          id
          type
          requestDt
          parameter
          value
          channel
          dimming
          queryType
          scheduleSlot
          switchOnTime
          switchOffTime
          commands {
            edges {
              node {
                id
                reference
                macAddress
                status
                statusDetail
                responseGenerationDt
                responseReceiptDt
                responseData
                commandReference
                parameterReference
                layout
              }
            }
          }
          user {
            firstName
            lastName
          }
        }
        cursor
      }
      total
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
              }
    }
  }
`;


const GATEWAY_INSTALLATION = gql`
query gatewayType($company: ID!) {
	gatewayInstallation(company: $company) {
		count
		edges {
			node {
				id
				reference
			}
		}
	}
}`;

// requisição para gerar relatório de consumo por rede (tela relatórios)
const GENERATE_GATEWAY_CONSUMPTION = gql`
mutation GenerateGatewayConsumption(

$company: ID!
$reference: String!
$gateway: ID!
$startDatetime: DateTime!
$endDatetime: DateTime!
) {
generateGatewayConsumption(
    input: {
        company: $company
        reference: $reference
        gateway: $gateway
        startDatetime: $startDatetime
        endDatetime: $endDatetime
    }
) {
    clientMutationId
    reportPromise {
        id
        generationDate
        requestedBy
        reference
        reportType
        ready
        auxInfo
    }
}
}`;

export {
  LOGIN_QUERY,
  GET_USER_COMPANIES,
  GET_INSTALLATIONS_BY_COMPANY_ID,
  USER_PROFILE_UPDATE_MUTATION,
  USER_INFO_QUERY,
  GET_ALL_REPORTS,
  GET_REPORT_DOWNLOAD_URL,
  REPORT_DELETE,
  LAST_TRANSMISSION_MUTATION,
  REGISTER_INSTALLATIONS_REPORT,
  LAST_DATA_MUTATION,
  GET_INSTALLATION_BY_REFERENCE,
  ALERT_MUTATION,
  INDIVIDUAL_CONSUMPTION_MUTATION,
  INDIVIDUAL_READ_CONSUMPTION_MUTATION,
  INDIVIDUAL_PULSE_CONSUMPTION_MUTATION,
  CIRCUIT_BOX_CONSUMPTION_MUTATION,
  INDIVIDUAL_STATUS_MUTATION,
  EVENTS_REPORT_MUTATION,
  METEOROLOGICAL_REPORT_MUTATION,
  COMMAND_LIST_REPORT_MUTATION,
  FIRST_ON_FIRST_OFF_REPORT_MUTATION,
  INSTALLATION_LIST_REPORT,
  NO_TRANSMISSION_REPORT_MUTATION,
  GET_GATEWAY_BY_COMPANY_ID,
  GET_EQUIPMENT_TYPE_BY_COMPANY_ID,
  GET_ALL_USERS,
  QUERY_INSTALLATIONS,
  SEND_SWITCH_ON_COMMAND,
  SEND_SWITCH_OFF_COMMAND,
  SEND_SET_PARAMETER_COMMAND,
  SET_SCHEDULE,
  COMMAND_BULK_REQUESTS,
  SEND_QUERY_COMMAND,
  SEND_QUERY_SCHEDULE_COMMAND,
  GET_INDIVIDUAL_CONSUMPTION_CHART,
  USER_CREATE,
  GET_ALL_COMPANIES,
  SET_PERMISSION,
  REMOVE_PERMISSION,
  AVAILABLE_PERMISSIONS,
  AVAILABLE_GROUPS,
  GROUP_SET_USER,
  GATEWAY_INSTALLATION,
  GENERATE_GATEWAY_CONSUMPTION,
  POSSIBLE_STATUS_REPORT_MUTATION,
  GET_CIRCUIT_BOXES
};

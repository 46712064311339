import { Injectable, EventEmitter } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { USER_INFO_QUERY, USER_PROFILE_UPDATE_MUTATION } from 'src/app/graphql/graphql.queries';

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  cancelEdit = new EventEmitter<boolean>();

  constructor(private apollo: Apollo) { }

  editProfile(email: string | null, username: string | null, firstName: string | null){
    return this.apollo.mutate({
      mutation: USER_PROFILE_UPDATE_MUTATION,               
      variables: {
        username: username,
        email: email,
        firstName: firstName
      }
    })
  }

  getUserInfo(username : any) {
    //Query for USER_INFO_QUERY
    return this.apollo.watchQuery<any>({
      query: USER_INFO_QUERY,
      variables: {
        username: username
      }
    })
  }
  
}

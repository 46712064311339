import { gql } from "apollo-angular";

const GET_ALL_USERS = gql`
query User(
						$company: ID!,
						$sort_dir: Direction!,
						$sort_field: UserOrdenationFields!
					){
						user (
							company: $company
							orderBy:{
								direction: $sort_dir,
								field: $sort_field
							}){
							total
							count
							edges {
								node {
									id
									firstName
									lastName
									email
								}
							}
						}
					}
`

const GET_ALERT_RULE_PROPERTIES = gql`
query Property {
		property (
			orderBy:{
				direction: ASC,
				field: NAME
				}
			){
			edges {
				node {
					id
					name
					sourceName
					measurementUnit {
						symbol
					}
				}
			}
		}
	}`

const GET_EQUIPMENT_TYPE = gql`
  query EquipmentType {
      equipmentType (
				orderBy:{
					direction: ASC
					field: REFERENCE
				}
			) {
          edges {
              node {
                  id
                  reference
                  major
                  minor
                  revision
              }
          }
      }
  }`;

const CREATE_ALERT_RULE = gql`
mutation AlertRuleCreate(
			$company: ID!,
			$name: String!,
			$alerttimewindow: Int!,
			$alerteventmaxcount: Int!,
			$startdate: Date,
			$enddate: Date,
			$dailystarttime: Time,
			$dailystoptime: Time,
			$weekdays: Int!,
			$installations: [ID]!,
			$users: [ID],
			$fullCoverage: Boolean,
			$monitoredProperties:  [MonitoredPropertyInput]!) {
				alertRuleCreate	(
					input: {
						company: $company,
						name: $name,
						alertTimeWindow: $alerttimewindow,
						alertEventMaxCount: $alerteventmaxcount,
						startDate: $startdate,
						endDate: $enddate,
						dailyStartTime: $dailystarttime,
						dailyStopTime: $dailystoptime,
						weekDayBitmask: $weekdays,
						installations: $installations,
						fullCoverage: $fullCoverage,
						users: $users,
						monitoredProperties: $monitoredProperties,
					}) {
				alertRule {
					id
				}
			}
		}`

const GET_ALL_ALERT_RULES = gql`
query AlertRule(
		$company: ID!,
		$reference: String,
		) {
		alertRules(
			company: $company,
			name_Icontains: $reference,
			) {
			edges {
				node {
					id
					name
					emails
					fullCoverage
					startDate
					endDate
					dailyStartTime
					dailyStopTime
					weekDayBitmask
					isActive
					alertTimeWindow
					alertEventMaxCount
					installations{
						edges{
							node{
								id
								reference
						}
					}
					}
					propertyRules{
						comparisonRule
						comparisonValue
						monitoredProperty{
							id
							name
							measurementUnit {
								symbol
							}
						}
					}
				}
				cursor
			}
			total
			count
		}
	}`

const GET_ALERT_RULE_BY_ID = gql`
query node($id: ID!) {
			node(id: $id) {
				... on AlertRule {
					id
					name
					emails
					fullCoverage
					startDate
					endDate
					dailyStartTime
					dailyStopTime
					weekDayBitmask
					alertTimeWindow
					alertEventMaxCount
					isActive
					installations {
						edges {
							node {
								id
								reference
							}
						}
					}
					propertyRules{
						comparisonRule
						comparisonValue
						monitoredProperty{
							id
							sourceName
							name
							measurementUnit {
								symbol
							}
						}
					}
				}
			}
		}`

const UPDATE_ALERT_RULE = gql`
mutation AlertRuleUpdate(
			$id: ID!
			$name: String!
			$installations: [ID]!
			) {
				alertRuleUpdate	(
					input: {
						id: $id,
						name: $name,
						installations: $installations,
					}) {
				alertRule {
					id
				}
			}
		}`

const ACTIVATE_DEACTIVATE_ALERT_RULE = gql`
mutation AlertRuleUpdate(
			$id: ID!
			$name: String!
			$isActive: Boolean
			$installations: [ID]!
			) {
				alertRuleUpdate	(
					input: {
						id: $id,
						name: $name,
						installations: $installations,
						isActive: $isActive
					}) {
				alertRule {
					id
				}
			}
		}`

const GET_INSTALLATION_BY_REFERENCE = gql`
query
							installation(
								$company: ID,
								$reference: String
							) {
								installation (
									company: $company,
									referenceContains: $reference,
								) {
									count
									total
									edges {
										node {
											id
											reference
											device{
												id
												macAddress
											}
										}
									}
								}
							}
`


export {
  GET_ALL_USERS, GET_ALERT_RULE_PROPERTIES, CREATE_ALERT_RULE, GET_ALL_ALERT_RULES, GET_ALERT_RULE_BY_ID, UPDATE_ALERT_RULE, ACTIVATE_DEACTIVATE_ALERT_RULE,
  GET_INSTALLATION_BY_REFERENCE, GET_EQUIPMENT_TYPE
}


<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'commands.parentTitle' | translate }}"></app-page-header>
  </div>

  <app-painel-fav *ngIf="selectedInstallationsView" [options]="selectedInstallations"
    [selectedOption]="selectedInstallations"></app-painel-fav>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <!-- Sidebar responsavel por filtrar as instalações -->
        <div class="form-fields-box" [ngClass]="!filterInstallation ? 'closed' : ''">
          <h2 (click)="isFilterInstallation()">
            {{ "commands.title-form-filter" | translate }}
          </h2>
          <hr [ngClass]="!filterInstallation ? 'hr-open' : ''" />

          <form [formGroup]="installationForm">
            <div class="form-step">
              <div class="circle-number">1</div>
              <mat-form-field appearance="outline">
                <mat-label>{{
                  "commands.form-reference" | translate
                  }}</mat-label>
                <input matInput formControlName="reference" />
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">2</div>
              <mat-form-field appearance="outline">
                <mat-label>{{ "commands.form-gateway" | translate }}</mat-label>
                <mat-select formControlName="gateway">
                  <mat-option *ngFor="let gateway of gatewayList" [value]="gateway.id">
                    {{gateway.reference}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="form-step inputFile">
              <div class="circle-number">3</div>
              <input type="file" class="form-control" id="inputGroupFile04" [accept]="'.csv'"
                aria-describedby="inputGroupFileAddon04" aria-label="Upload" #fileUploadSimple
                (change)="getTextFromFile($event)">
              <br>
            </div>

            <div class="form-step">
              <div class="circle-number" *ngIf="installationList.length > 0">
                4
              </div>
              <div *ngIf="installationList.length > 0" class="btn btn-success view-installations"
                (click)="openModalPage()">
                {{ "commands.installationList" | translate }}
              </div>
            </div>

            <button class="btn btn-secondary" type="button" id="inputGroupFileAddon04" (click)="importDataFromCSV()"
              [disabled]="isDisabledImportButton" *ngIf="!isLoadingImportFilter" type="submit">
              Importar
            </button>

            <mat-spinner [diameter]="30" *ngIf="isLoadingImportFilter"></mat-spinner>
            <button title="Filtrar" *ngIf="!isLoadingInstallationFilter" (click)="getInstallationsByCompanyId()"
              [disabled]="checkInstallationFilter()" class="btn btn-success mb-2 filter">
              {{ "commands.btn-filter" | translate }}
            </button>
            <br *ngIf="isLoadingInstallationFilter" />
            <mat-spinner [diameter]="30" *ngIf="isLoadingInstallationFilter && filterInstallation"></mat-spinner>
          </form>
        </div>

        <!-- Select responsavel pelas consultas -->
        <div class="form-fields-box" [ngClass]="!consultSidebar ? 'closed' : ''" *ngIf="installationList.length > 0">
          <h2 (click)="consultFilter()">
            {{ "commands.consult-filter" | translate }}
          </h2>
          <hr [ngClass]="!consultSidebar ? 'hr-open' : ''" />

          <div class="options-consultation">
            <mat-slide-toggle class="mat-slide-toggle-content" *ngIf="!setUp && !turnOn"
              (change)="checkConsult('consult')" [color]="color" [(ngModel)]="consultIsChecked">{{ "commands.consult" |
              translate }}</mat-slide-toggle>
            <section class="consult" *ngIf="consultIsChecked">
              <mat-radio-group [(ngModel)]="consultValue" [color]="color">
                <mat-radio-button class="mat-radio" value="STATUS">{{
                  "commands.consult-status" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="DIAGNOSTIC">{{
                  "commands.consult-diagnostic" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="PARAMETERS">{{
                  "commands.consult-parameters" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="MEASUREMENT">{{
                  "commands.commands-measurement" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="LATEST_MEASUREMENT">{{
                  "commands.consult-last-measurement" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="VERSION">{{
                  "commands.consult-version" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="GET_SCHEDULE">{{
                  "commands.consult-get-schedule" | translate
                  }}</mat-radio-button>
              </mat-radio-group>

            </section>

            <!-- Sessão Ligar/Desligar -->
            <mat-slide-toggle class="mat-slide-toggle-content" *ngIf="!setUp && !consultIsChecked"
              (change)="checkConsult('turnOn')" [color]="color" [(ngModel)]="turnOn">{{ "commands.turn-on-off" |
              translate }}</mat-slide-toggle>

            <section class="turn-on" *ngIf="turnOn">
              <mat-radio-group [(ngModel)]="turnOnValue" [color]="color">
                <mat-radio-button class="mat-radio" value="on">{{
                  "commands.turn-on" | translate
                  }}</mat-radio-button>
                <mat-radio-button class="mat-radio" value="off">{{
                  "commands.turn-off" | translate
                  }}</mat-radio-button>
              </mat-radio-group>
            </section>

            <!-- Sessão Configurar -->

            <mat-slide-toggle class="mat-slide-toggle-content" *ngIf="!turnOn && !consultIsChecked"
              (change)="checkConsult('setUp')" [color]="color" [(ngModel)]="setUp">{{ "commands.configure" | translate
              }}</mat-slide-toggle>
            <br />
            <mat-form-field class="parameter" appearance="outline" *ngIf="setUp"><br />
              <mat-label>{{
                "commands.configure-parameter" | translate
                }}</mat-label>
              <mat-select [(ngModel)]="settingsForm">
                <!-- <mat-option class="form-control" value="TGE">{{
                  "commands.configure-parameter-tge" | translate
                  }}</mat-option> -->
                <mat-option class="form-control" value="MCA">{{
                  "commands.configure-parameter-mca" | translate
                  }}</mat-option>
                <mat-option class="form-control" value="DIM">{{
                  "commands.configure-parameter-dim" | translate
                  }}</mat-option>
                <mat-option class="form-control" value="PSL">{{
                  "commands.configure-parameter-psl" | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Div responsável por coletar o valor de intervalo de msn que será enviado por comando
            <div *ngIf="settingsForm === 'TGE' && setUp">
              <mat-form-field appearance="fill">
                <mat-label>{{ "commands.min-tge" | translate }}</mat-label>
                <input matInput type="number" min="0" max="32767" [(ngModel)]="minTGE" />
              </mat-form-field>
            </div> -->

            <!-- Div responsável por coletar a prioridade de atuação do usuário que será enviada por comando -->
            <div *ngIf="settingsForm === 'MCA' && setUp">
              <mat-radio-group [color]="color" [(ngModel)]="valueMCA">
                <mat-radio-button [value]="false">{{
                  "commands.command-schedule" | translate
                  }}</mat-radio-button>
                <mat-radio-button [value]="true">{{
                  "commands.command-brightness-sensor" | translate
                  }}</mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- Div responsável por coletar o valor de dimerização que será enviado por comando -->
            <div *ngIf="settingsForm === 'DIM' && setUp">
              <label class="custom-range-p">
                {{ "commands.value-dim" | translate }} {{ dimmingValue }}%
              </label>
              <input type="range" class="form-range" id="customRange1" [(ngModel)]="dimmingValue" />
            </div>

            <!-- Div responsável por coletar a presença de luminosidade do usuário que será enviada por comando -->
            <div *ngIf="settingsForm === 'PSL' && setUp">
              <mat-radio-group [color]="color" [(ngModel)]="valuePSL">
                <mat-radio-button [value]="false">{{
                  "commands.not-light-sensor" | translate
                  }}</mat-radio-button>
                <mat-radio-button [value]="true">{{
                  "commands.light-sensor" | translate
                  }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <button title="Enviar" (click)="consultationForm()" *ngIf="!isLoadingInstallationFilter"
            class="btn btn-success mb-2">
            {{ "commands.btn-send" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="isLoadingInstallationFilter && consultSidebar"></mat-spinner>
        </div>

        <!-- sidebar responsavel pelos agendamentos -->
        <div class="form-fields-box" [ngClass]="!schedulingSidebar ? 'closed' : ''" *ngIf="installationList.length > 0">
          <h2 (click)="schedulingSidebarOpen()">
            {{ "commands.sidebar-scheduling" | translate }}
          </h2>
          <hr [ngClass]="!schedulingSidebar ? 'hr-open' : ''" />

          <div class="options-scheduling">
            <!-- canais -->
            <br />

            <!-- Sessão que envia comando por hora -->
            <section class="scheduling" *ngIf="!setUp && !turnOn">
              <br />
              <label>{{ "commands.scheduling-turn-on" | translate }}</label>
              <ngx-timepicker-field [format]="24" [defaultTime]="'00:00'"
                [(ngModel)]="switchOnTime"></ngx-timepicker-field>
              <br />

              <label>{{ "commands.scheduling-turn-off" | translate }}</label>
              <ngx-timepicker-field [format]="24" [defaultTime]="'00:00'"
                [(ngModel)]="switchOffTime"></ngx-timepicker-field>

              <label class="dimming">
                {{ "commands.dimming" | translate }} {{ dimmingValue }}%
              </label>
              <input type="range" class="form-range" id="customRange1" [(ngModel)]="dimmingValue" />
            </section>
          </div>
          <button title="Enviar" (click)="scheduleQuery()" *ngIf="!isLoadingInstallationFilter"
            class="btn btn-success mb-2">
            {{ "commands.btn-success" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="isLoadingInstallationFilter && schedulingSidebar"></mat-spinner>
        </div>

        <!-- Sessão de filtro da tabela -->
        <div class="form-fields-box" [ngClass]="!isFilterTable ? 'closed' : ''">
          <h2 (click)="filterTable()">
            {{ "commands.filter-commands" | translate }}
          </h2>
          <hr [ngClass]="!isFilterTable ? 'hr-open' : ''" />

          <mat-form-field class="parameter" appearance="outline">
            <mat-label>{{ "commands.filter-parameter" | translate }}</mat-label>
            <mat-select [(ngModel)]="tableFilterForm">
              <mat-option class="form-control" [value]="null">{{
                "commands.filter-parameter-none" | translate
                }}</mat-option>
              <mat-option class="form-control" value="SWITCH_ON">{{
                "commands.filter-parameter-turn-on" | translate
                }}</mat-option>
              <mat-option class="form-control" value="SWITCH_OFF">{{
                "commands.filter-parameter-turn-off" | translate
                }}</mat-option>
              <mat-option class="form-control" value="QUERY">{{
                "commands.filter-parameter-consult" | translate
                }}</mat-option>
              <mat-option class="form-control" value="SET_SCHEDULE">{{
                "commands.filter-parameter-schedule" | translate
                }}</mat-option>
              <mat-option class="form-control" value="SET_PARAMETER">{{
                "commands.filter-parameter-configure" | translate
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button title="Filtrar" (click)="filterCommands(null)" *ngIf="!isLoadingInstallationFilter"
            class="btn btn-success mb-2">
            {{ "commands.filter-parameter-btn-filter" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="isLoadingInstallationFilter && isFilterTable"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="length">
      <!-- Sessão tabela -->
      <app-table-header [itensNumber]="length" [totalNumber]="length" [hasCommands]="true" [hasCSV]="true"
        [hasPDF]="true" (downloadCSV)="downloadCSV()" (downloadPDF)="downloadPDF()"></app-table-header>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
          <!-- date Column -->
          <ng-container matColumnDef="demo-date">
            <th mat-header-cell *matHeaderCellDef>
              {{ "commands.table-requested-date" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
                {{ element.requestDt }}
              </span>
            </td>
          </ng-container>

          <!-- type Column -->
          <ng-container matColumnDef="demo-type">
            <th mat-header-cell *matHeaderCellDef>
              {{ "commands.table-type" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{ element.type }}
            </td>
          </ng-container>

          <!-- sendBy Column -->
          <ng-container matColumnDef="demo-sendBy">
            <th mat-header-cell *matHeaderCellDef>
              {{ "commands.table-sent-by" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{ element.user.firstName }} {{ element.user.lastName }}
            </td>
          </ng-container>

          <!-- answer Column -->
          <ng-container matColumnDef="demo-answer">
            <th mat-header-cell *matHeaderCellDef>
              {{ "commands.table-response" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <div class="progress">
                <div class="progress-bar" role="progressbar" attr.style="width: {{
                    (element.equipmentsQueued / element.equipments.length) * 100
                  }}%;" attr.aria-valuenow="{{ element.equipmentsQueued }}" aria-valuemin="0"
                  attr.aria-valuemax="{{ element.equipments.length }}"></div>
              </div>
              <!-- <mat-progress-bar mode="determinate" attr.value="{{equipmentsQueued}}" attr.aria-valuemax="{{element.equipments.length}}"></mat-progress-bar> -->
              {{ element.equipmentsQueued }} /
              {{ element.equipments.length }}
            </td>
          </ng-container>

          <!-- view Column -->
          <ng-container matColumnDef="demo-view">
            <th mat-header-cell *matHeaderCellDef>Visualizar</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button [ngClass]="element.view === true ? 'hover' : ' '" title="Visualizar comando"
                (click)="sendCommandsToModal(element.equipments, element)" class="btn btn-success view">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye"
                  viewBox="0 0 16 16">
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10]"
          [pageIndex]="pageIndex" aria-label="Selecione as páginas de Comandos" class="mat-paginator-sticky"
          (page)="pageChanged($event)">
        </mat-paginator>

      </div>
    </div>
  </div>
</div>
export class auditTotals {
    constructor(
        public icti: number | null,
        public icvi: number | null,
        public register: number | null,
        public state: number | null,
        public variables: number | null,
        public opdiu: number | null,
        public opnot: number | null,
        //public mprev: number | null,
        public actuation: number | null,
    ) { }
}
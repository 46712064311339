import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SidebarService } from 'src/shared/services/sidebar.service';
import { Company } from '../models/companies.model';
import Swal from 'sweetalert2';
import { LoginService } from 'src/shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { PageHeaderService } from 'src/shared/services/page-header.service';
import { environment } from 'src/environments/environment.prod';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent extends Unsubscriber implements OnInit {
  //flags
  //Flag que controla o estado do menu, se está aberto ou fechado.
  public isClosed: boolean = true;
  public isReportMenuOpen: boolean = false;
  public isUserMenuOpen: boolean = false;
  public isAlertsMenuOpen: boolean = false;
  public companies: Company[] = [];

  filteredCompanies: Company[] = [];
  //Variáveis de form control
  public companyFormControl = new FormControl({ value: '', disabled: false });
  public selectedLanguage = '';
  public lastSelectedLanguage = localStorage.getItem('language');

  public fullName = localStorage.getItem('username')!.split(/\./);

  //flag que checka se a empresa selecionada é a IPMINAS
  public isIpMinas: boolean = false;

  //Logo da empresa selecionada
  public logoImg: string | null = ''

  //Checa se a logo da empresa selecionada existe
  async checkImagePath() {
    const imagePath = `../../../../assets/imgs/logos_companies/${localStorage.getItem('lastCompanySelected')}.png`;
    //Utiliza a função que verifica se a imagem existe
    const exists = await this.checkImageExistence(imagePath);

    if (exists) {
      this.logoImg = imagePath
    } else {
      this.logoImg = null
    }
  }

  constructor(private sidebarService: SidebarService,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    private pageHeaderService: PageHeaderService) {
    super();
  }

  /**
   * Recebe o resultado da requisição da lista de empresas do usuário, e atribui o resultado à variável companies.
   * Caso haja erro de invalid token ou token expirado, redireciona para a página de login.
   */
  ngOnInit() {
    //Inicia a função de checkImagePath
    this.checkImagePath()

    this.subscriptions = this.sidebarService.getUserCompanies(`${localStorage.getItem('username')}`)
      .valueChanges
      .subscribe(
        {
          next: (result: any) => {
            result.data.user.edges[0].node.companies.edges.forEach((company: any) => {
              this.companies.push(
                new Company(company.node.id, company.node.name)
              )
            })
            this.companyFormControl.setValue(this.checkLastCompany());
            if (this.companyFormControl.value === 'Q29tcGFueToyMA==') {
              this.isIpMinas = true;
            }
          },
          error: (error: any) => {
            if (error.message === "Invalid token for this user" || error.message === "Token signature has expired") {
              this.loginService.showSideBar.emit(false);
              this.loginService.showSideBarUpdateUser.emit(false);
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Seu login expirou',
                showConfirmButton: false,
                timer: 2500
              }).then(() => {
                this.router.navigate(['/login']);
              });
            }
          }
        });

    this.filteredCompanies = this.companies;

    this.subscriptions = this.pageHeaderService.openSideBar.subscribe({
      next: ((open: boolean) => {
        this.isClosed = !open;
      })
    });

    this.subscriptions = this.pageHeaderService.openSubMenu.subscribe({
      next: ((submenu: string) => {
        switch (submenu) {
          case "Relatórios":
            this.openCloseReportSubMenu();
            break;
          case "Usuários":
            this.openCloseUserSubMenu();
        }
      })
    })

    if (this.lastSelectedLanguage !== null) {
      this.selectedLanguage = this.lastSelectedLanguage;
    } else {
      this.selectedLanguage = 'pt';
    }
  }

  /**
   * @description Alterna a variável isClosed para true ou false, controlando o estado do menu entre aberto e fechado, respectivamente.
   * Também alterna a classe do botão de menu, para que o ícone mude de acordo com o estado do menu.
   *
   */
  public toggleSidebar(): void {
    if (!this.isClosed) {
      this.isClosed = true;
      this.closeAllSubMenus();
    } else this.isClosed = false;
  }

  /**
   * Envia o id da empresa selecionada para o serviço de sidebar, que emite esse evento, deixando o ID desta empresa disponível para outros componentes.
   */
  public companySelect(): void {
    localStorage.setItem('lastCompanySelected', (this.companyFormControl.value != null ? this.companyFormControl.value : ''));
    localStorage.removeItem('selectedInstallations');
    this.sidebarService.sendCompany.emit(this.companyFormControl.value);
    if (this.companyFormControl.value === 'Q29tcGFueToyMA==') {
      this.isIpMinas = true;
    } else this.isIpMinas = false;
    location.reload();
  }

  // Função que realiza o filtro no campo de concentradores da sidebar
  filterCompanies(searchTerm: any) {
    this.filteredCompanies = this.companies.filter(company => company.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
  }

  /**
   * Redireciona para tela de login, limpando todos os dados do localStorage.
   */
  public logout(): void {
    Swal.fire({
      title: 'Gostaria de deslogar?',
      text: "Você será redirecionado para a página de Login",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.loginService.showSideBar.emit(false);
        this.loginService.showSideBarUpdateUser.emit(false);
        this.router.navigate(['/login']);
      }
    })
  }

  /**
   * Redireciona para a página do usuário.
   */
  public goToProfile(): void {
    this.router.navigate(['/user']);
    this.isClosed = true;
  }

  public openCloseReportSubMenu(): void {
    this.isReportMenuOpen = !this.isReportMenuOpen;
    this.isUserMenuOpen = false;
    this.isAlertsMenuOpen = false;
    this.isClosed = false;
  }

  public openCloseUserSubMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
    this.isReportMenuOpen = false;
    this.isAlertsMenuOpen = false;
    this.isClosed = false;
  }

  public goToLogs(): void {
    this.router.navigate(['/logs']);
    this.isClosed = true;
  }

  public goToImportsCSV(): void {
    this.router.navigate(['/importsCSV']);
    this.isClosed = true;
  }

  public goToRegister(): void {
    this.router.navigate(['/register']);
    this.isClosed = true;
  }

  public openCloseAlertSubMenu(): void {
    this.isAlertsMenuOpen = !this.isAlertsMenuOpen;
    this.isReportMenuOpen = false;
    this.isUserMenuOpen = false;
    this.isClosed = false;
  }

  public goToReport(): void {
    this.router.navigate(['/report']);
    this.openCloseReportSubMenu()
    this.isClosed = true;
  }

  public goToAlertRules(): void {
    this.openCloseAlertSubMenu();
    this.isClosed = true;
    this.router.navigate(['/alertrules']);
  }

  public goToAlerts(): void {
    this.openCloseAlertSubMenu();
    this.isClosed = true;
    this.router.navigate(['/alerts']);
  }

  public goToCommands(): void {
    this.router.navigate(['/commands']);
    this.openCloseAlertSubMenu();
    this.isClosed = true;
  }

  public goToReportCharts(): void {
    this.router.navigate(['/reportcharts']);
    this.openCloseReportSubMenu();
    this.isClosed = true;
  }

  public goToAudit(): void {
    this.router.navigate(['/audit']);
    this.openCloseReportSubMenu();
    this.isClosed = true;
  }

  public goToManageUser(): void {
    this.router.navigate(['/manageuser']);
    this.openCloseUserSubMenu();
    this.isClosed = true;
  }

  public goToPermissionsGroup(): void {
    this.router.navigate(['/permissionsGroup']);
    this.openCloseUserSubMenu();
    this.isClosed = true;
  }

  public goToServiceOrder(): void {
    this.router.navigate(['/serviceorder']);
    this.isClosed = true;
  }

  public goHome(): void {
    this.router.navigate(['/map']);
    this.closeAllSubMenus();
    this.isClosed = true;
  }

  public checkLastCompany(): (string | null) {

    let lastCompanySelected = localStorage.getItem('lastCompanySelected');
    if (lastCompanySelected != null && lastCompanySelected != '') {
      return lastCompanySelected;
    }
    else {
      localStorage.setItem('lastCompanySelected', this.companies[0].id);
      return this.companies[0].id;
    }
  }

  public closeAllSubMenus(): void {
    this.isReportMenuOpen = false;
    this.isUserMenuOpen = false;
    this.isAlertsMenuOpen = false;
  }

  public useLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.selectedLanguage = language;
    this.translate.use(language);
  }

  //Verifica se uma imagem existe baseado no path informado
  public checkImageExistence(path: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true); // A imagem foi carregada com sucesso, portanto, existe
      };
      img.onerror = () => {
        resolve(false); // A imagem não pôde ser carregada, portanto, não existe
      };
      img.src = path;
    });
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_EQUIPMENT_TYPE_BY_COMPANY_ID, GET_GATEWAY_BY_COMPANY_ID, GET_INSTALLATIONS_BY_COMPANY_ID } from 'src/app/graphql/graphql.queries';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  installationByCompanyIdEvent = new EventEmitter<boolean>();

  constructor(private apollo: Apollo) { }

  getInstallationsByCompanyId(id: string | null, reference: string | null, gateway: string | null, equipment_type: Array<string | null>) {
    //Query for GET_INSTALLATIONS_BY_COMPANY_ID
    return this.apollo.watchQuery<any>({
      query: GET_INSTALLATIONS_BY_COMPANY_ID,
      variables: {
        company: id,
        first: 150,
        referenceContains: reference,
        gateway: gateway,
        equipmentTypes: equipment_type
      }
    })
  }

  getGatewayByCompanyId(id: string | null) {
    //Query for GET_GATEWAY_BY_COMPANY_ID
    return this.apollo.watchQuery<any>({
      query: GET_GATEWAY_BY_COMPANY_ID,
      variables: {
        company: id,
        first: 150
      }
    })
  }

  getEquipmentTypeByCompanyId(id: string | null) {
    return this.apollo.watchQuery<any>({
      query: GET_EQUIPMENT_TYPE_BY_COMPANY_ID,
      variables: {
        company: id,
        first: 150
      }
    })
  }


}

import { gql } from "apollo-angular";

/** Filtro de todos os Hardwares da tabela (Filtro Inicial) **/

/** Filtro de Hardwares **/
const FILTER_HARDWARES = gql`
query Hardware(
    $major: Int,
    $minor: Int,
    $name: String,
    $revision: Int,
	$pageSize: Int,
	$cursor: String
    ) {
    hardware(
        major: $major,
        minor: $minor,
        name: $name,
        revision: $revision,
		pageSize: $pageSize,
		cursor: $cursor
        ) {
        edges {
            node {
                id
                name
                major
                minor
                revision
                description
                releaseDate
            }  
        }
		count
        total
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
    }
}`;

/** Criar Hardwares **/
const CREATE_HARDWARES = gql`
mutation
	HardwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		hardwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			hardware {
				id
		}
	}
}`;

/** Lista Todos os Firmwares  */
const GET_ALL_FIRMWARES = gql`
query
	Firmware (
		$pageSize: Int
	){
		firmware (
			pageSize: $pageSize	 
			){
			edges {
				node {
					id
					name
					minor
					major
					revision
					description
					releaseDate
				}
			}
		}
}`;

/** Criar novo Firmware compatível */
const CREATE_NEW_COMPATIBLE_FIRMWARE = gql`
mutation
	FirmwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		firmwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			firmware {
				id
			}
		}
}`;

export {
	FILTER_HARDWARES,
	CREATE_HARDWARES,
	GET_ALL_FIRMWARES,
	CREATE_NEW_COMPATIBLE_FIRMWARE
}
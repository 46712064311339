<div class="component-main-container">
  <app-page-header pageTitle="{{'alert-rules.pageTitle' | translate}}"
    parentTitle="{{'alert-rules.parentTitle' | translate}}">
  </app-page-header>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-4">
      <div class="component-forms-box">

        <!-- Box de registro de regras de alertas -->
        <div class="form-fields-box p-0" [ngClass]="isCreateMenuOpen ? 'form-fields-box-open' : ''">
          <h2 class="pt-3 pe-2 pl-2" (click)="openCloseCreateMenu()"> {{"alert-rules.create-form" | translate}}</h2>
          <hr [ngClass]="!isCreateMenuOpen ? 'hr-open' : ''">

          <ng-template #formLoader>
            <div class="component-spinner-box-sm">
              <mat-spinner [diameter]="50"></mat-spinner>
              <p class="m-1"> {{"alert-rules.loading-properties" | translate}}</p>
            </div>
          </ng-template>

          <mat-accordion class="example-headers-align p-0"
            *ngIf="!(userLoading$ | async) && !(propertiesLoading$ | async) && !(equipmentTypesLoading$ | async); else formLoader">
            <mat-expansion-panel [expanded]="formStep === 1" (opened)="setStep(1)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{"alert-rules.create-form-data" | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{"alert-rules.basic-data" | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-form-field appearance="outline">
                <mat-label> {{"alert-rules.data-reference" | translate}}</mat-label>
                <input matInput [formControl]="alertRuleReferenceForm">
              </mat-form-field>

              <div class="d-flex justify-content-start align-items-center mt-3 mb-3">
                <mat-checkbox color="primary" (change)="setFullCoverage($event)" [formControl]="fullCoverageForm">
                </mat-checkbox>
                <p> {{"alert-rules.full-coverage" | translate}}</p>
              </div>

              <mat-form-field appearance="outline">
                <mat-label> {{"alert-rules.notified-users" | translate}} </mat-label>
                <mat-select value="default">
                  <mat-option disabled value="default">
                    {{selectedUsers.length}} {{"alert-rules.users-selected" | translate}}
                  </mat-option>
                  <span *ngFor="let user of users$ | async" class="d-flex justify-content-start align-items-center">
                    <mat-checkbox class="ms-2 me-2" (change)="selectUser($event, user.id)">{{user.firstName}}
                      {{user.lastName}}</mat-checkbox>
                  </span>
                </mat-select>
              </mat-form-field>

              <mat-action-row>
                <button mat-flat-button color="primary" (click)="nextStep()">{{"alert-rules.next-page" |
                  translate}}
                </button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="formStep === 2" (opened)="setStep(2)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{"alert-rules.create-form-rules" | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{"alert-rules.parameters-rules" | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- Parâmetros das regras -->
              <div *ngFor="let rule of rules; index as i">
                <span class="d-flex">
                  <!-- Select de Tipos de Equipamentos -->
                  <mat-form-field appearance="outline" class="w-25 p-2">
                    <mat-label> {{"alert-rules.equipment_type" | translate}} </mat-label>
                    <mat-select (selectionChange)="selectEquipmentType($event, i)">
                      <mat-option [value]="">
                        {{"alert-rules.equipment_type_all" | translate}}
                      </mat-option>
                      <mat-option *ngFor="let equipment_type of equipment_types$ | async" [value]="equipment_type.id">
                        {{equipment_type.reference}}
                        ({{equipment_type.major}}.{{equipment_type.minor}}.{{equipment_type.revision}})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
                <span class="d-flex">

                  <!-- Select das propriedades -->
                  <mat-form-field appearance="outline" class="w-25 p-2">
                    <mat-label> {{"alert-rules.monitoring-properties" | translate}} </mat-label>
                    <mat-select (selectionChange)="selectProperty($event, i)">
                      <mat-option *ngFor="let property of properties$ | async" [value]="property.id">
                        {{property.name}}
                        ({{property.measurementUnit}})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>

                <!-- Operadores Lógicos -->
                <span class="d-flex">
                  <mat-form-field class="w-25 p-2" appearance="outline">
                    <mat-label> {{"alert-rules.logical-operators" | translate}} </mat-label>
                    <mat-select (selectionChange)="selectRule($event, i)">
                      <mat-option value="EQUALS">=</mat-option>
                      <mat-option value="GREATER_THAN">&gt;</mat-option>
                      <mat-option value="LESS_THAN">&lt;</mat-option>
                      <mat-option value="NOT_EQUALS">&ne;</mat-option>
                      <mat-option value="GREATER_EQUAL">&ge;</mat-option>
                      <mat-option value="LESS_EQUAL">&le;</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Valor -->
                  <mat-form-field class="w-25 p-2" *ngIf="!isNetworkIdSelectedArray[i]; else installationSelect"
                    appearance="outline">
                    <mat-label> {{"alert-rules.value" | translate}}</mat-label>
                    <input [(ngModel)]="rules[i].comparisonValue" (input)="selectValue($event, i)" matInput
                      type="number">
                  </mat-form-field>

                  <ng-template #installationSelect>
                    <div *ngIf="isNetworkIdSelectedArray[i]" class="w-25 p-2 d-flex">
                      <mat-form-field>
                        <mat-label> {{"alert-rules.search-installation" | translate}} </mat-label>
                        <input matInput [formControl]="installationByReferenceForm" type="text">
                      </mat-form-field>

                      <div class="d-flex flex-column">
                        <mat-icon (click)="searchInstallationByReference(i)" class="search-btn">search</mat-icon>
                        <div *ngIf="isSearchingInstallation[i]">
                          <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </span>

                <mat-icon *ngIf="i > 0" (click)="removeRule(i)" class="clear-btn">clear</mat-icon>

                <div *ngIf="isNetworkIdSelectedArray[i] && installationListNetworkId[i]?.length != 0"
                  class="d-flex justify-content-evenly">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"alert-rules.select-installation" | translate}} </mat-label>
                    <mat-select (selectionChange)="selectInstallationMacAddress($event, i)">
                      <mat-option [value]="installation.installationMacAddress"
                        *ngFor="let installation of installationListNetworkId[i]">
                        {{installation.installationReference}} -
                        {{installation.installationMacAddress}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <span class="d-flex">
                    <!-- Janela de Análise -->
                    <mat-form-field class="w-25 p-2" appearance="outline">
                      <mat-label> {{"alert-rules.alert-time-window" | translate}} </mat-label>
                      <input matInput [formControl]="alertTimeWindowForm" type="number">
                    </mat-form-field>
                    <!-- N° de Eventos -->
                    <mat-form-field class="w-25 p-2" appearance="outline">
                      <mat-label> {{"alert-rules.number-events" | translate}} </mat-label>
                      <input [formControl]="alertEventMaxCountForm" matInput type="number">
                    </mat-form-field>
                  </span>
                </div>
              </div>

              <!-- Grupo de botões -->
              <mat-action-row>
                <button mat-button color="primary" (click)="addNewRule()">{{"alert-rules.add-parameters" |
                  translate}}</button>
                <button mat-flat-button color="primary" (click)="prevStep()"> {{"alert-rules.previous-page" |
                  translate}}
                </button>
                <button mat-flat-button color="primary" (click)="nextStep()">{{"alert-rules.next-page" |
                  translate}}</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="formStep === 3" (opened)="setStep(3)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{"alert-rules.create-form-days" | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{"alert-rules.range-days-and-time" | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <p><small> {{"alert-rules.default-date" | translate}} </small></p>

              <div class="row">
                <!-- Data de Início -->
                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline" class="example-form-field">
                    <mat-label>{{"alert-rules.start-date" | translate}} </mat-label>
                    <input matInput [matDatepicker]="datepickerstart" [formControl]="startDateForm">
                    <mat-datepicker-toggle matIconSuffix [for]="datepickerstart"></mat-datepicker-toggle>
                    <mat-datepicker #datepickerstart>
                      <mat-datepicker-actions>
                        <button mat-raised-button class="btn btn-success"
                          matDatepickerApply>{{"alert-rules.button-apply" | translate}}</button>
                        <button mat-button matDatepickerCancel
                          class="btn btn-warning me-1">{{"alert-rules.button-cancel" | translate}}</button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- Data de Término -->
                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline" class="example-form-field">
                    <mat-label> {{"alert-rules.end-date" | translate}} </mat-label>
                    <input matInput [matDatepicker]="datepickerend" [formControl]="endDateForm">
                    <mat-datepicker-toggle matIconSuffix [for]="datepickerend"></mat-datepicker-toggle>
                    <mat-datepicker #datepickerend>
                      <mat-datepicker-actions>
                        <button mat-raised-button class="btn btn-success"
                          matDatepickerApply>{{"alert-rules.button-apply" | translate}}</button>
                        <button mat-button matDatepickerCancel
                          class="btn btn-warning me-1">{{"alert-rules.button-cancel" | translate}}</button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">

                <!--Hora de Inicio  -->
                <div class="col-md-4 col-xl-6 col-sm-6">
                  <div class="form-step mt-2 mb-2">
                    <label> {{"alert-rules.start" | translate}} </label>
                    <ngx-timepicker-field [format]="24" [defaultTime]="'12:00'" class="p-2"
                      [formControl]="startTimeForm">
                    </ngx-timepicker-field>
                  </div>
                </div>

                <!-- Hora Final -->
                <div class="col-md-4 col-xl-6 col-sm-6">
                  <div class="form-step mt-2 mb-2">
                    <label> {{"alert-rules.end" | translate}} </label>
                    <ngx-timepicker-field [format]="24" [defaultTime]="'12:00'" class="p-2" [formControl]="endTimeForm">
                    </ngx-timepicker-field>
                  </div>
                </div>

                <!-- Dias da semana -->
                <div class="col-md-4 col-xl-12 col-sm-12">
                  <p> {{"alert-rules.week-days" | translate}} </p>
                  <mat-button-toggle-group name="dias" aria-label="Dias" multiple>
                    <mat-button-toggle value="1" [checked]="true" (change)="calculateWeekDays($event, 1)">D
                    </mat-button-toggle>
                    <mat-button-toggle value="2" [checked]="true" (change)="calculateWeekDays($event, 2)">S
                    </mat-button-toggle>
                    <mat-button-toggle value="4" [checked]="true" (change)="calculateWeekDays($event, 4)">T
                    </mat-button-toggle>
                    <mat-button-toggle value="8" [checked]="true" (change)="calculateWeekDays($event, 8)">Q
                    </mat-button-toggle>
                    <mat-button-toggle value="16" [checked]="true" (change)="calculateWeekDays($event, 16)">Q
                    </mat-button-toggle>
                    <mat-button-toggle value="32" [checked]="true" (change)="calculateWeekDays($event, 32)">S
                    </mat-button-toggle>
                    <mat-button-toggle value="64" [checked]="true" (change)="calculateWeekDays($event, 64)">S
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <mat-action-row>
                <button mat-flat-button color="primary" (click)="prevStep()"> {{"alert-rules.previous-page" |
                  translate}}
                </button>
                <button mat-flat-button color="primary" (click)="nextStep()">{{"alert-rules.next-page" |
                  translate}}</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="formStep === 4" (opened)="setStep(4)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{"alert-rules.create-form-installations" | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{"alert-rules.search-and-select-installations" | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- Campo para busca de instalações -->
              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label> {{"alert-rules.search-installation" | translate}} </mat-label>
                <input [formControl]="searchInstallationForm" (keydown.enter)="searchInstallation()" matInput
                  type="text">
                <button matSuffix mat-icon-button aria-label="Clear" class="component-search-icon-button"
                  (click)="searchInstallation()">
                  <mat-icon svgIcon="bx-search" *ngIf="!isLoadingNewInstallation"></mat-icon>
                  <mat-spinner *ngIf="isLoadingNewInstallation" [diameter]="30"></mat-spinner>
                </button>
              </mat-form-field>

              <p *ngIf="installationList.length === 0" class="text-danger">{{"alert-rules.no-installation-selected" |
                translate}}
              </p>
              <p *ngIf="installationList.length > 0">{{installationList.length}} {{"alert-rules.installations-selected"
                | translate}}
              </p>

              <button *ngIf="installationList.length > 0" (click)="openModalWithInstallations()"
                class="btn btn-primary">
                {{"alert-rules.installations-selected" | translate}}
              </button>

              <ng-template #loader>
                <div class="component-spinner-box-sm">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </ng-template>

              <mat-action-row *ngIf="!creatingAlertRuleMutation; else loader">
                <button mat-flat-button color="primary" (click)="prevStep()"> {{"alert-rules.previous-page" |
                  translate}}
                </button>
                <button mat-button color="primary" (click)="createAlertRule()">
                  {{"alert-rules.button-create-rule" | translate}} </button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <!-- Box de filtro de regras de alertas -->
        <div class="form-fields-box" [ngClass]="isFilterMenuOpen ? 'form-fields-box-open' : ''">
          <h2 (click)="openCloseFilterMenu()"> {{"alert-rules.filter-rules" | translate}} </h2>
          <hr [ngClass]="!isFilterMenuOpen ? 'hr-open' : ''">

          <!-- Campo de busca por referencia da instalação -->
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab label="{{'alert-rules.mat-tab-filter-reference' | translate}}">
              <br>
              <div class="form-step">
                <div class="circle-number"> 1 </div>
                <mat-form-field [formGroup]="filterForm" appearance="outline">
                  <mat-label> {{"alert-rules.filter-form-reference" | translate}} </mat-label>
                  <input matInput formControlName="referenceFilter">
                </mat-form-field>
              </div>

              <!-- Botões do filtro. -->
              <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterAlertRules()">
                {{"alert-rules.button-filter" | translate}}
              </button>
              <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
            </mat-tab>

            <!-- Campo para realizar a busca de equipamentos por numero de série -->
            <mat-tab label="{{'alert-rules.mat-tab-filter-status' | translate}}">
              <br>
              <div class="form-step">
                <mat-button-toggle-group class="filter-btn-group" (change)="filterAlertRulesByStatus($event.value)"
                  value="active" name="fontStyle" aria-label="Filtrar dados da tabela">
                  <mat-button-toggle value="active"> {{"alert-rules.button-active" | translate}} </mat-button-toggle>
                  <mat-button-toggle value="inactive"> {{"alert-rules.button-inactive" | translate}}
                  </mat-button-toggle>
                  <mat-button-toggle value="all"> {{"alert-rules.button-all" | translate}} </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <!-- Tabela -->
    <div class="col-lg-7 col-xl-8 col-sm-8">
      <div class="component-table-box">
        <ng-template #tableLoader>
          <div class="component-spinner-box d-flex flex-column">
            <mat-spinner color="success"></mat-spinner>
            <p class="m-2">{{"alert-rules.loading-alert-rules" | translate}}</p>
          </div>
        </ng-template>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{"alert-rules.column-name" | translate}} </th>
            <td mat-cell *matCellDef="let AlertRule"> {{AlertRule.reference}} </td>
          </ng-container>

          <ng-container matColumnDef="weekdays">
            <th mat-header-cell *matHeaderCellDef>{{"alert-rules.column-week-days" | translate}} </th>
            <td mat-cell *matCellDef="let AlertRule">
              <mat-button-toggle-group *ngFor="let days of AlertRule.weekdays" name="dias" aria-label="Dias" multiple>
                <mat-button-toggle [disabled]="true" [checked]="days.selected">{{days.day}}</mat-button-toggle>
              </mat-button-toggle-group>
            </td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef> {{"alert-rules.column-start-date" | translate}} </th>
            <td mat-cell *matCellDef="let AlertRule">
              <span matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
                {{AlertRule.startDate}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef> {{"alert-rules.column-end-date" | translate}} </th>
            <td mat-cell *matCellDef="let AlertRule">
              <span matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
                {{AlertRule.endDate ? AlertRule.endDate : '-'}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef sticky> {{"alert-rules.column-actions" | translate}} </th>
            <td mat-cell *matCellDef="let AlertRule">
              <mat-icon (click)="manageAlertRule(AlertRule.id, 'edit')" svgIcon="bx-edit-black"></mat-icon>
              <mat-icon (click)="manageAlertRule(AlertRule.id, 'view')" svgIcon="bx-show"></mat-icon>
              <mat-icon (click)="manageAlertRule(AlertRule.id, 'copy')" svgIcon="bx-paste"></mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
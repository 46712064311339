<div class="sidebar" [ngClass]="isClosed ? 'closedSidebar' : ''">
  <div class="sidebar-body">
    <div class="logo-details">
      <mat-icon class="text-white" (click)="toggleSidebar()" svgIcon="bx-menu-white"
        [ngClass]="isClosed ? 'arrow closedArrow' : 'arrow'"></mat-icon>

      <!-- <mat-icon (click)="toggleSidebar()" class="text-white"
        [ngClass]="isClosed ? 'arrow closedArrow' : 'arrow'">reorder</mat-icon> -->
      <img class="logo_company" src="{{logoImg}}" *ngIf="logoImg" alt="logo company" (click)="goHome()" width="100">
      <span class="logo_name" (click)="goHome()" *ngIf="!logoImg"> SmartGreen </span>
    </div>

    <div class="company-select" [ngClass]="{'company-select-open': !isClosed}">
      <mat-form-field appearance="outline">
        <mat-label> {{"sidebar.company" | translate}} </mat-label>
        <mat-select class="mat-select" [formControl]="companyFormControl" (selectionChange)="companySelect()">
          <mat-form-field [style.width.%]="100" *ngIf="companies.length > 1" appearance="outline">
            <mat-label>Filtrar</mat-label>
            <input matInput #searchCompany class="input-filter" focused="true"
              (keyup)="filterCompanies(searchCompany.value)" (keydown)="$event.stopPropagation()">
          </mat-form-field>
          <mat-option *ngFor="let company of filteredCompanies" value="{{ company.id }}">{{ company.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <hr />

    <li>
      <div class="icon-link" (click)="goHome()">
        <mat-icon class="menu-icon" svgIcon="bx-globe-white"></mat-icon>
        <span class="link_name"> {{'sidebar.map' | translate}} </span>
      </div>
    </li>

    <li>
      <div class="icon-link" (click)="openCloseReportSubMenu()">
        <mat-icon class="menu-icon" svgIcon="bx-chart-white"></mat-icon>
        <span class="link_name"> {{"sidebar.reports" | translate}} </span>
      </div>

      <ul class="sub-menu" [ngClass]="isReportMenuOpen ? 'sub-menu-open' : ''">
        <li (click)="goToReport()"><a> {{"sidebar.generate-reports" | translate}} </a></li>
        <li (click)="goToReportCharts()"><a> {{"sidebar.report-charts" | translate}} </a></li>
        <li *ngIf="isIpMinas" (click)="goToAudit()"><a> {{"sidebar.audit" | translate}} </a></li>
      </ul>
    </li>

    <li (click)="goToCommands()">
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-cogs-white"></mat-icon>

        <span class="link_name"> {{"sidebar.commands" | translate}} </span>
      </div>
    </li>

    <li (click)="goToServiceOrder()">
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-task-white"></mat-icon>
        <span class="link_name"> {{"sidebar.service-order" | translate}} </span>
      </div>
    </li>

    <li>
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-warn-white" (click)="openCloseAlertSubMenu()"></mat-icon>
        <span class="link_name" (click)="openCloseAlertSubMenu()"> {{"sidebar.alerts" | translate}} </span>
      </div>

      <ul class="sub-menu" [ngClass]="isAlertsMenuOpen ? 'sub-menu-open' : ''">
        <li (click)="goToAlertRules()"><a> {{"sidebar.alert-rules" | translate}} </a></li>
      </ul>

      <ul class="sub-menu" [ngClass]="isAlertsMenuOpen ? 'sub-menu-open' : ''">
        <li (click)="goToAlerts()"><a> {{"sidebar.register-alert" | translate}} </a></li>
      </ul>
    </li>

    <li>
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-user-white" (click)="openCloseUserSubMenu()"></mat-icon>
        <span class="link_name" (click)="openCloseUserSubMenu()"> {{"sidebar.users" | translate}} </span>
      </div>

      <ul class="sub-menu" [ngClass]="isUserMenuOpen ? 'sub-menu-open' : ''">
        <li (click)="goToManageUser()"><a> {{"sidebar.manage-user" | translate}} </a></li>
        <li (click)="goToPermissionsGroup()"><a> Grupos de Permissões </a></li>
      </ul>
    </li>

    <li>
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-file-white" (click)="goToLogs()"></mat-icon>
        <span class="link_name" (click)="goToLogs()"> {{"sidebar.logs" | translate}} </span>
      </div>
    </li>

    <li>
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-imports-csv" (click)="goToImportsCSV()"></mat-icon>
        <span class="link_name" (click)="goToImportsCSV()"> Importações CSV </span>
      </div>
    </li>

    <li>
      <div class="icon-link">
        <mat-icon class="menu-icon" svgIcon="bx-add-white" (click)="goToRegister()"></mat-icon>
        <span class="link_name" (click)="goToRegister()"> {{"sidebar.register" | translate}} </span>
      </div>
    </li>

    <div class="translations">
      <div class="pt-btn" [ngClass]="selectedLanguage === 'pt' ? 'selected-language' : null"
        (click)="useLanguage('pt')"></div>
      <div class="es-btn" [ngClass]="selectedLanguage === 'es' ? 'selected-language' : null"
        (click)="useLanguage('es')"></div>
      <div class="us-btn" [ngClass]="selectedLanguage === 'us' ? 'selected-language' : null"
        (click)="useLanguage('us')"></div>
    </div>

    <div [ngClass]="isClosed ? 'profile-details isClosed' : 'profile-details'">
      <div class="name-job">
        <mat-icon class="profile-icon" svgIcon="bx-exit-white" (click)="logout()"></mat-icon>
        <div class="profile_name" (click)="goToProfile()" title="Ir para seu perfil">
          <span>{{fullName[0]}} {{fullName[1]}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
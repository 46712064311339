import { EventEmitter, Injectable, } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subject, BehaviorSubject, take, tap, catchError, map } from 'rxjs';
import { Complainant, Installation, Problem, ServiceOrder, PaginationInfo } from '../components/service-order/models/service-order.model';
import { CREATE_SERVICE_ORDER, FILTER_SERVICE_ORDER, GET_INSTALLATIONS, UPDATE_SERVICE_ORDER } from 'src/app/graphql/service-order.queries';
import { ErrorLibService } from './error-lib.service';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})

export class ServiceOrderService {
  constructor(private apollo: Apollo, private errorLibService: ErrorLibService) {
    /** Verifica a empresa selecionada **/
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }
  /** Armazena ID da empresa selecionada **/
  company: string | null;

  status: any;

  priority: any;

  // Realiza a busca por ordens de serviço na empresa selecionada
  filterServiceOrder(identifier: string | null, cursor: string | null, pageSize: number | null): any {
    return this.apollo.watchQuery({
      query: FILTER_SERVICE_ORDER,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        serviceIdentifier: identifier,
        company: this.company,
        cursor: cursor,
        first: pageSize,
        sort_dir: 'DESC',
        sort_field: 'STATUS'
      }
    })
    // .valueChanges
    // .pipe(
    //   take(1),
    //   tap((res: any) => {
    //     // Preenche a variável utilizada para controle de paginação
    //     let paginator = new PaginationInfo(
    //       res.data.serviceOrder.count,
    //       res.data.serviceOrder.total,
    //       res.data.serviceOrder.pageInfo.hasNextPage,
    //       res.data.serviceOrder.pageInfo.hasPreviousPage,
    //       res.data.serviceOrder.pageInfo.startCursor,
    //       res.data.serviceOrder.pageInfo.endCursor
    //     )
    //     // Emite a variável de controle de paginação
    //     this.paginatorFilterEvent.emit(paginator);

    //     let serviceOrderTableList: ServiceOrder[]= [];
    //     res.data.serviceOrder.edges.forEach((node: any) => {
    //       // Preenche a lista de resultados com as OS retornadas na query
    //       if(node.node.installation){
    //       serviceOrderTableList.push(
    //         new ServiceOrder(
    //           node.node.id, 
    //           node.node.serviceIdentifier,
    //           new Problem(
    //             node.node.problem.reference,
    //             node.node.problem.details,
    //             node.node.problem.address
    //           ),
    //           new Complainant(
    //             node.node.complainant?.name ?? '' ,
    //             node.node.complainant?.cpf ?? '',
    //             node.node.complainant?.landlineNumber ?? '',
    //             node.node.complainant?.phoneNumber ?? ''
    //           ),
    //           node.node?.status, 
    //           new Installation(
    //             node.node.installation?.id,
    //             node.node.installation?.reference,
    //           ),
    //           node.node.priority))
    //         }
    //     })

    //     this.serviceOrderSubject.next(serviceOrderTableList);

    //     this.serviceOrderGetter();

    //     // Desativa o loading da página
    //     this.serviceOrderFilterLoading.emit(false);
    //   }), catchError((err) => {throw err})
    // ).subscribe({
    //   error: (error)=>{
    //     console.log(error);
    //     this.serviceOrderFilterLoading.emit(false);
    //     this.errorLibService.errorAlert(error);
    //   }
    // })
  }
  handlePreviousPage(cursor: string | null, pageSize: number | null): any {
    return this.apollo.watchQuery({
      query: FILTER_SERVICE_ORDER,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        company: this.company,
        before: cursor,
        last: pageSize,
        sort_dir: 'DESC',
        sort_field: 'STATUS'
      }
    })
  }
  // Método de busca das instalações para preencher nos campos de cadastro
  getInstallations(reference: string) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        reference: reference,
        first: 20,
        sort_dir: 'DESC',
        sort_field: 'REFERENCE'
      }
    })
  }

  createServiceOrder(
    installation: string,
    status: string | null,
    priority: string | null,
    problem_reference: string | null,
    problem_details: string | null,
    problem_address: string | null,
    name: string | null,
    landlineNumber: string | null,
    phoneNumber: string | null,
    cpf: string | null,
  ) {

    return this.apollo.mutate({
      mutation: CREATE_SERVICE_ORDER,
      variables: {
        company: this.company,
        installation: installation,
        status: status,
        priority: priority,
        problem_reference: problem_reference,
        problem_details: problem_details,
        problem_address: problem_address,
        name: name,
        landlineNumber: landlineNumber,
        phoneNumber: phoneNumber,
        cpf: cpf,
      }
    })
    // .pipe(
    //   tap(() => {
    //     this.serviceOrderLoading.next(false);

    //     this.filterServiceOrder(null)
    //     /** Ao criar a ordem de serviço exibe mensagem de sucesso ao usuário **/
    //     Swal.fire({
    //       title: 'Ordem de serviço Criada',
    //       text: 'Ordem de serviço criada com sucesso',
    //       icon: 'success',
    //       confirmButtonText: 'Ok'
    //     });
    //   }), catchError((err) => { throw err })
    // ).subscribe({
    //   error: (error) => {
    //     console.log(error)

    //     this.serviceOrderLoading.next(false);

    //     this.errorLibService.errorAlert(error);
    //   }
    // });
  }
  // Função criada para atualização de OS
  updateServiceOrder(
    id: string,
    status: string | null,
    priority: string | null,
    problem_reference: string | null,
    problem_details: string | null,
    problem_address: string | null,
    name: string | null,
    landlineNumber: string | null,
    phoneNumber: string | null,
    cpf: string | null,
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_SERVICE_ORDER,
      variables: {
        id,
        status,
        priority,
        problem_reference,
        problem_details,
        problem_address,
        name,
        landlineNumber,
        phoneNumber,
        cpf
      }
    })
    // .pipe(
    //   tap(() => {
    //     this.updateLoadingSubject.next(false);
    //     this.filterServiceOrder(null);

    //     Swal.fire({
    //       title: 'Ordem de Serviço alterada',
    //       text: 'Ordem de serviço alterada com sucesso',
    //       icon: 'success',
    //       confirmButtonText: 'Ok'
    //     });
    //   }),
    //   catchError((err) => { throw err })
    // ).subscribe({
    //   error: (error) => {
    //     this.updateLoadingSubject.next(false);
    //     this.errorLibService.errorAlert(error);
    //   }
    // });
  }

}               

import { gql } from "apollo-angular";

const FILTER_USER_LOG = gql`
query UserLogs(
  $company: ID!,
  $operation: String,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $requestedField: String,
  $username: String,
	$first: Int,
	$cursor: String
  ){
  userLogs(
		companyId: $company,
    operationType: $operation,
    requestDatetime_Gte: $startDatetime,
    requestDatetime_Lte: $endDatetime,
    requestedField: $requestedField,
    user_Username: $username,
		first: $first,
		after: $cursor
	){
    pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges{
			node{
				requestDatetime
				operationType
				requestedField
				user{
					username
				}
			}
		}
	}
}`;

export {
    FILTER_USER_LOG
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { CircuitBoxService } from 'src/shared/services/circuit-box.service';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import Swal from 'sweetalert2';
import { CircuitBox, EquipmentTypeCircuitBox, GatewayCircuitBox, PaginationInfo } from './assets/circuit-box-model';

@Component({
  selector: 'app-circuit-box',
  templateUrl: './circuit-box.component.html',
  styleUrls: ['./circuit-box.component.less']
})
export class CircuitBoxComponent extends Unsubscriber implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public errorLibService: ErrorLibService,
    private circuitBoxService: CircuitBoxService
  ) {
    super();
  }

  ngOnInit(): void {
    this.filterGateway()
  }

  // Variaveis responáveis por abrir e fechar as boxes
  public register: boolean = false;
  public registerLoading: boolean = false;

  public filter: boolean = false;
  public filterLoading: boolean = false;

  // Armazena os dados do formulário de filtro das circuitBox, o valor é setado automáticamente pelo HTML
  public filterCircuitBoxForm: FormGroup = this.formBuilder.group({
    reference: [null],
  });

  // Armazena os dados do formulário de registro das circuitBox, o valor é setado automáticamente pelo HTML
  public registerCircuitBoxForm: FormGroup = this.formBuilder.group({
    reference: [null, Validators.required],
    ipc: [null, Validators.required],
    searchIPC: [null, Validators.required],
    searchCorte: [null, Validators.required],
    gateway: [null, Validators.required],
    consumption: [null, Validators.required],
  });

  // Armazena os dados do formulário de registro das circuitBox, o valor é setado automáticamente pelo HTML
  public updateCircuitBoxForm: FormGroup = this.formBuilder.group({
    id: null,
    reference: [null, Validators.required],
    searchUpdateIPC: [null, Validators.required],
    searchUpdateCorte: [null, Validators.required],
    sgIpcSerialNumber: [null, Validators.required],
    sgIpcID: [null, Validators.required],
    sgGatewayReference: [null, Validators.required],
    sgGatewayID: [null, Validators.required],
    sgConsumptionSerialNumber: [null, Validators.required],
    sgConsumptionID: [null, Validators.required],
  });

  public filteredReference: string;

  public loadingEquipmentsIPC = false;
  public loadingEquipmentsCORTE = false;

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: CircuitBox[] = [];

  // Variável que guarda o número de relatórios retornados do backend.
  public resultsLength = this.ELEMENT_DATA.length;

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ['reference', 'sgIpc', 'sgGateway', 'sgConsumption', 'actions'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<CircuitBox>(this.ELEMENT_DATA);

  //Função que configura o paginatior
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  //Variáveis usadas para o paginator e para o sort.
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 10;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /**
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     *
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.previousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.previousPage(this.paginationProperties.endCursor)
    }
  }

  // Função responsável por mudar o estado da box de registro e fechar a de filtro
  Register(): void {
    this.filter = false;
    this.register = !this.register;
  }

  Filter(): void {
    this.register = false;
    this.filter = !this.filter;
  }

  /**
 * Função responsavel por criar as caixas de circuitos
 */
  public registerCircuitBox() {
    if (this.registerCircuitBoxForm.valid) {

      this.registerLoading = true;

      this.circuitBoxService.handlerRegister(
        this.registerCircuitBoxForm.get("reference")?.value,
        this.registerCircuitBoxForm.get("consumption")?.value,
        this.registerCircuitBoxForm.get("gateway")?.value,
        this.registerCircuitBoxForm.get("ipc")?.value
      ).subscribe({
        next: ((response) => {
          Swal.fire({
            title: 'Caixa de circuito criada',
            text: 'Caixa de circuito criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          this.filterCircuitBox()
          this.registerLoading = false;
        }),
        error: (error: any) => {
          console.log("CreateCircuitBox", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
  }

  /**
* Função responsavel por filtrar as caixas de circuitos
*/
  public filterCircuitBox() {
    this.filteredReference = this.filterCircuitBoxForm.get('reference')?.value;
    if (this.filterCircuitBoxForm.valid) {

      this.circuitBoxService.handlerFilter(
        this.filteredReference,
        null,
        null
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Adiciona as informações de paginação da API na página.
          this.paginationProperties = new PaginationInfo(
            response.data?.circuitBox?.count,
            response.data?.circuitBox?.total,
            response.data?.circuitBox?.pageInfo?.hasNextPage,
            response.data?.circuitBox?.pageInfo?.hasPreviousPage,
            response.data?.circuitBox?.pageInfo?.startCursor,
            response.data?.circuitBox?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          // Adiciona cada empresa retornada pela requisição na lista
          response.data.circuitBox.edges.forEach((node: any) => {
            this.ELEMENT_DATA.push(
              new CircuitBox(
                node.node.id,
                node.node.reference,
                node.node.sgIpc.serialNumber,
                node.node.sgIpc.id,
                node.node.sgGateway.reference,
                node.node.sgGateway.id,
                node.node.sgConsumption.serialNumber,
                node.node.sgConsumption.id
              ));
          })

          /** Atualiza a tabela com os novos dados **/
          this.setDataSourceAttributes();

          if (this.ELEMENT_DATA.length === 0) {
            /** Retorna alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }

          this.filterLoading = false;
        }),
        error: (error: any) => {
          console.log("referenceCircuitBox", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
  }

  public previousPage(cursor: string | null) {

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.filteredReference = this.filterCircuitBoxForm.get('reference')?.value;

    if (this.filterCircuitBoxForm.valid) {

      this.circuitBoxService.handlerPrevious(
        this.filteredReference,
        cursor,
        this.pageSize
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Adiciona as informações de paginação da API na página.
          this.paginationProperties = new PaginationInfo(
            response.data?.circuitBox?.count,
            response.data?.circuitBox?.total,
            response.data?.circuitBox?.pageInfo?.hasNextPage,
            response.data?.circuitBox?.pageInfo?.hasPreviousPage,
            response.data?.circuitBox?.pageInfo?.startCursor,
            response.data?.circuitBox?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          // Adiciona cada empresa retornada pela requisição na lista
          response.data.circuitBox.edges.forEach((node: any) => {
            this.ELEMENT_DATA.push(
              new CircuitBox(
                node.node.id,
                node.node.reference,
                node.node.sgIpc.serialNumber,
                node.node.sgIpc.id,
                node.node.sgGateway.reference,
                node.node.sgGateway.id,
                node.node.sgConsumption.serialNumber,
                node.node.sgConsumption.id
              ));
          })

          /** Atualiza a tabela com os novos dados **/
          this.setDataSourceAttributes();
          //this.isReady = false;

          if (this.ELEMENT_DATA.length === 0) {
            /** Retorna alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }

          this.filterLoading = false;
        }),
        error: (error: any) => {
          console.log("referenceCircuitBox", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
  }

  public gatewayInstallationList: GatewayCircuitBox[] = [];

  public filterGateway() {
    this.circuitBoxService.handleFilterGateway().subscribe({
      next: ((response: any) => {
        response.data.gatewayInstallation.edges.forEach((node: any) => {
          this.gatewayInstallationList.push(
            new GatewayCircuitBox(node.node.id, node.node.reference));
        });
      }),
      error: (error: any) => {
        console.log("filterGateway", error)
        this.errorLibService.errorAlert(error);
      }
    })
  }

  equipmentsTypesID: any = []

  public equipmentTypeCorte: EquipmentTypeCircuitBox[] = [];
  public equipmentTypeIPC: EquipmentTypeCircuitBox[] = []
  public equipmentTypeIPCUpdate: EquipmentTypeCircuitBox[] = []
  public equipmentTypeCorteUpdate: EquipmentTypeCircuitBox[] = []

  public typeFilter = ""

  public filterEquipmentBySerialNumber(searchField: string, type: string, typeFilter: string) {
    this.typeFilter = typeFilter

    const search = this.registerCircuitBoxForm.get(searchField)?.value || this.updateCircuitBoxForm.get(searchField)?.value

    if (!search) {
      return
    }

    this.loadingEquipmentsIPC = type == "ipc" ? true : false
    this.loadingEquipmentsCORTE = type == "corte" ? true : false

    this.circuitBoxService.handlerFilterEquipment(search).valueChanges.subscribe({
      next: ((response: any) => {
        if (type == "ipc") {

          this.equipmentTypeIPCUpdate = typeFilter == "update" ? [] : this.equipmentTypeIPCUpdate
          this.equipmentTypeIPC = typeFilter == "update" ? this.equipmentTypeIPC : []

          response.data.equipment.edges.forEach((node: any) => {
            if (node?.node?.equipmentType?.reference?.toLowerCase() == "sgipc") {
              if (typeFilter == "update") {
                this.equipmentTypeIPCUpdate.push(
                  new EquipmentTypeCircuitBox(node.node.id, node.node.equipmentType.reference, node.node.serialNumber, node.node.equipmentType.major, node.node.equipmentType.minor, node.node.equipmentType.revision));
              } else {
                this.equipmentTypeIPC.push(
                  new EquipmentTypeCircuitBox(node.node.id, node.node.equipmentType.reference, node.node.serialNumber, node.node.equipmentType.major, node.node.equipmentType.minor, node.node.equipmentType.revision));
              }
            }
          });
        } else if (type == "corte") {

          this.equipmentTypeCorteUpdate = typeFilter == "update" ? [] : this.equipmentTypeCorteUpdate
          this.equipmentTypeCorte = typeFilter == "update" ? this.equipmentTypeCorte : []

          response.data.equipment.edges.forEach((node: any) => {
            if (node?.node?.equipmentType?.reference?.toLowerCase() == "sgcorte") {
              if (typeFilter == "update") {
                this.equipmentTypeCorteUpdate.push(
                  new EquipmentTypeCircuitBox(node.node.id, node.node.equipmentType.reference, node.node.serialNumber, node.node.equipmentType.major, node.node.equipmentType.minor, node.node.equipmentType.revision));
              } else {
                this.equipmentTypeCorte.push(
                  new EquipmentTypeCircuitBox(node.node.id, node.node.equipmentType.reference, node.node.serialNumber, node.node.equipmentType.major, node.node.equipmentType.minor, node.node.equipmentType.revision));
              }
            }
          });
        }

        this.loadingEquipmentsIPC = false
        this.loadingEquipmentsCORTE = false
      }),
      error: (error: any) => {
        console.log(error)
        this.errorLibService.errorAlert(error);
      }
    })
  }

  /**
* Função responsavel por criar as caixas de circuitos
*/
  // Controlador para apresentação da label edição de empresas.
  public editorController: boolean = false;

  public updateGetter(element: any) {
    delete element.view
    this.updateCircuitBoxForm.setValue({
      ...element,
      searchUpdateIPC: element.sgIpcSerialNumber,
      searchUpdateCorte: element.sgConsumptionSerialNumber
    });
    this.editorController = true;
  }
  // Fecha a barra lateral quando clicado no botão.
  closeEditionSideBar() {
    // Fecha a tela lateral.
    this.editorController = false;
  }

  public updateCircuitBox() {
    if (this.updateCircuitBoxForm.valid) {

      this.subscriptions = this.circuitBoxService.handlerUpdate(
        this.updateCircuitBoxForm.get("id")?.value,
        this.updateCircuitBoxForm.get("reference")?.value,
        this.updateCircuitBoxForm.get("sgConsumptionID")?.value,
        this.updateCircuitBoxForm.get("sgGatewayID")?.value,
        this.updateCircuitBoxForm.get("sgIpcID")?.value
      ).subscribe({
        next: ((response: any) => {
          console.log(response)
          this.ELEMENT_DATA = [
            new CircuitBox(
              response.data.circuitBoxUpdate.circuitBox.id,
              response.data.circuitBoxUpdate.circuitBox.reference,
              response.data.circuitBoxUpdate.circuitBox.sgIpc.serialNumber,
              response.data.circuitBoxUpdate.circuitBox.sgIpc.id,
              response.data.circuitBoxUpdate.circuitBox.sgGateway.reference,
              response.data.circuitBoxUpdate.circuitBox.sgGateway.device.id,
              response.data.circuitBoxUpdate.circuitBox.sgConsumption.serialNumber,
              response.data.circuitBoxUpdate.circuitBox.sgConsumption.id
            )
          ];
          this.setDataSourceAttributes();
          this.editorController = false;
          Swal.fire({
            title: 'Caixa de circuito atualizada',
            text: 'Caixa de circuito atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        }),
        error: (error: any) => {
          console.log("UpdateCircuitBox", error)
          this.errorLibService.errorAlert(error);
        }
      })
    } else {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  public FILE_DATA: CircuitBox[] = [];
  public fileLoading = false;
  public isReady = false;

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.circuitBoxService.handlerFilter(
        this.filteredReference,
        cursor,
        50
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Adiciona as informações de paginação da API na página.
          this.paginationProperties = new PaginationInfo(
            response.data?.circuitBox?.count,
            response.data?.circuitBox?.total,
            response.data?.circuitBox?.pageInfo?.hasNextPage,
            response.data?.circuitBox?.pageInfo?.hasPreviousPage,
            response.data?.circuitBox?.pageInfo?.startCursor,
            response.data?.circuitBox?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          // Adiciona cada empresa retornada pela requisição na lista
          response.data.circuitBox.edges.forEach((node: any) => {
            this.FILE_DATA.push(
              new CircuitBox(
                node.node.id,
                node.node.reference,
                node.node.sgIpc.serialNumber,
                node.node.sgIpc.id,
                node.node.sgGateway.reference,
                node.node.sgGateway.id,
                node.node.sgConsumption.serialNumber,
                node.node.sgConsumption.id
              ));
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.circuitBox?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.circuitBox?.pageInfo?.endCursor, fileType);

          this.filterLoading = false;
        }),
        error: (error: any) => {
          console.log("referenceCircuitBox", error)
          this.errorLibService.errorAlert(error);
        }
      })
    } else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).toString().replace(',', ';');
      })
      .join('\n');
  }

  /** Método que realiza o download do arquivo em CSV **/
  public downloadCSV() {

    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((circuitBox) => {
      content.push({
        "Referência": circuitBox.reference,
        "IPC(Serial Number)": circuitBox.sgIpcSerialNumber,
        "Gateway": circuitBox.sgGatewayReference,
        "Corte(Serial Number)": circuitBox.sgConsumptionSerialNumber
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-circuit-box.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
    return

  }

  // Função que realiza o download do CSV.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = ['Referência', 'IPC(Serial Number)', "Gateway", "Corte(Serial Number)"];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((circuitBox) => {
      content.push([circuitBox.reference, circuitBox.sgIpcSerialNumber, circuitBox.sgGatewayReference, circuitBox.sgConsumptionSerialNumber]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-circuit-box.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }
}

import { gql } from "apollo-angular";
/**  Requisição do filtro de tipos de luminárias **/
const FILTER_LAMPS_TYPE = gql`
query LampType(
    $manufacturer: String,
    $power: Int,
    $pageSize: Int,
    $cursor: String,
    ) {
    lampType(
        pageSize: $pageSize,
        cursor: $cursor,
        manufacturer: $manufacturer,
        power: $power,
        ) {
        edges {
            node {
                id
				power
				manufacturer
				model
				brightness
				voltage
				current
				consumption
				dimmingType
				powerFactor
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

/** Requisição para criação de tipos de luminárias **/
const CREATE_LAMPS_TYPE = gql`
mutation 
LampTypeCreate(
			$brightness: Float,
			$consumption: Float,
			$current: Float,
			$dimmingType: DimmingTypes!,
			$manufacturer: String!,
			$model: String!,
			$power: Int,
			$powerFactor: Float,
			$voltage: Float
			) {
			lampTypeCreate(
				input: {
					brightness: $brightness,
					consumption: $consumption,
					current: $current,
					dimmingType: $dimmingType,
					manufacturer: $manufacturer,
					model: $model,
					power: $power,
					powerFactor: $powerFactor,
					voltage: $voltage
				}) {
				lampType {
					id
				}
			}
		}`;
/** Requisição para edição de tipos de luminária **/
const UPDATE_LAMPS_TYPE = gql`
mutation
LampTypeUpdate(
			$id: ID!,
            $brightness: Float!,
            $consumption: Float!,
            $current: Float!,
            $dimmingType: DimmingTypes!,
			$manufacturer: String!,
            $model: String!,
			$power: Int!,
            $powerFactor: Float!,
            $voltage: Float!
			) {
			lampTypeUpdate(
				input: {
					id: $id,
                    brightness: $brightness,
                    consumption: $consumption,
                    current: $current,
                    dimmingType: $dimmingType,
					manufacturer: $manufacturer,
                    model: $model,
					power: $power,
                    powerFactor: $powerFactor,
                    voltage: $voltage
				}) {
				lampType {
					id
                    power
                    manufacturer
                    model
                    brightness
                    voltage
                    current
                    consumption
                    dimmingType
                    powerFactor
				}
			}
		}`;
export {
    FILTER_LAMPS_TYPE,
    CREATE_LAMPS_TYPE,
    UPDATE_LAMPS_TYPE,
} 
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuditorshipService } from 'src/shared/services/auditorship.service';
import Swal from 'sweetalert2';
import { AuditInstallations, InstallationImports } from '../../models/auditInstallations.model';
import { AuditData } from '../../models/auditData.model';
import { AuditList } from '../../models/auditList.model';
import { MatDialog } from '@angular/material/dialog';
import { Installation } from 'src/shared/components/report-audit/models/auditInstallations.model';
import { InstallationsModalComponent } from 'src/shared/components/installations-modal/installations-modal.component';
import { InstallationService } from 'src/shared/services/installation.service';
import { auditTotals } from './models/auditTotals.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-audit-edit',
  templateUrl: './audit-edit.component.html',
  styleUrls: ['./audit-edit.component.less']
})
export class AuditEditComponent extends Unsubscriber implements OnInit {

  @Input() editedAuditId: string = '';

  constructor(private auditorshipService: AuditorshipService,
    public dialog: MatDialog,
    private installationService: InstallationService,
    private router: Router,
    private acivatedRoute: ActivatedRoute,
    private errorLibService: ErrorLibService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.editedAuditIdParam = this.acivatedRoute.snapshot.paramMap.get('id');
    this.getAuditorshipInstallations();
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Propriedades utilizadas para manipulação dos arquivos de importação **/
  public data: string;
  public dataToImport: Array<any>;

  /** Lista que armazena a lista de instalações não encontradas no arquivo de importação **/
  public installationsNotFound: Array<string> = new Array<string>();
  /** Propriedade que manipula o botão de importação **/
  public disabledImportButton: boolean;
  /** Loading de importação **/
  public isLoadingImportFilter: boolean = false;

  public editedAuditIdParam: string | null = '';
  //Vetor que guarda os dados da auditoria selecionada
  public auditInstallationSet: AuditInstallations[] = [];
  // Variáveis do mat-table da auditoria selecionada
  public displayedColumns: string[] = ['delete', 'installationReference', 'transmissionComplianceIndicator', 'icvi', 'sdft', 'register',
    'status', 'variables', 'daytimeOperation', 'nighttimeOperation', 'actuation'];
  public dataSource = new MatTableDataSource<AuditInstallations>(this.auditInstallationSet);

  // Variável que guarda os dados da auditoria selecionada
  public auditListData: AuditList;

  public auditIsOpen: boolean;
  public selectedInstallations: string[] = [];
  public installationDataWasModified: boolean = false;
  public auditDataWasModified: boolean = false;
  public auditInstallationCopy: AuditInstallations[] = [];
  public auditCopy: AuditList;
  public auditDataTotal: auditTotals;
  public installationList: Installation[] = [];
  public installationDeletionList: any[] = [];

  public isLoadingNewInstallation: boolean = false;

  // variáveis do formcontrol
  public referenceForm: FormControl = new FormControl('', Validators.required);
  public searchInstallationForm: FormControl = new FormControl('');
  public startDateForm: FormControl = new FormControl('');
  public endDateForm: FormControl = new FormControl('', Validators.required);

  public getAuditorshipInstallations() {
    this.subscriptions = this.auditorshipService.getAuditorshipInstallations(this.editedAuditIdParam)
      .valueChanges
      .subscribe({
        next: (auditData: any) => {
          let auditPayload = auditData.data.auditorship.edges[0].node;
          let auditInstallationSet = auditPayload.installationauditSet.edges;
          auditInstallationSet.forEach((installation: any) => {
            this.auditInstallationSet.push(
              this.createInstallationSet(installation.node)
            );
          });
          this.dataSource.data = this.auditInstallationSet;
          this.auditIsOpen = auditPayload.isOpen;
          this.auditInstallationCopy = this.auditInstallationSet;
          this.auditListData = new AuditList(
            auditPayload.id,
            auditPayload.auditReference,
            auditPayload.installationauditSet.total,
            new AuditData(
              auditPayload.totals.icti,
              auditPayload.totals.icvi,
              auditPayload.totals.idt,
              auditPayload.totals.opdiu,
              auditPayload.totals.opnot,
              auditPayload.totals.sdft,
              auditPayload.totals.sdot,
              auditPayload.totals.mprev,
            ),
            auditPayload.accountable,
            auditPayload.creationDate,
            auditPayload.startDate,
            auditPayload.endDate,
            (auditPayload.isOpen ? 'Aberta' : 'Fechada')
          )
          this.auditCopy = this.auditListData;
          this.referenceForm.setValue(auditPayload.auditReference);
          this.startDateForm.setValue(new Date(JSON.stringify(auditPayload.startDate)).toISOString());
          auditPayload.endDate ? this.endDateForm.setValue(new Date(JSON.stringify(auditPayload.endDate)).toISOString()) : null;
          if (!this.auditIsOpen) {
            this.disableAllFormFields();
            Swal.fire({
              title: 'Auditoria fechada',
              text: 'Não será possível editar esta auditoria pois ela já foi finalizada!',
              timer: 3000
            })
          }
          this.calculateAuditTotal();
        }
      })
  }

  public backToAuditTables() {
    if (this.auditDataWasModified || this.installationDataWasModified || this.installationList.length > 0) {
      Swal.fire({
        title: 'Deseja sair?',
        text: 'As alterações não salvas serão perdidas!',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['audit']);
        }
      })
    } else this.router.navigate(['audit']);
  }

  public disableAllFormFields() {
    this.referenceForm.disable();
    this.searchInstallationForm.disable();
    this.startDateForm.disable();
    this.endDateForm.disable();
  }

  public changeInstallationField(checkedValue: any, changedField: any, installationId: any): void {
    let indexOfChangedData: number = this.auditInstallationCopy.findIndex(
      (installation: AuditInstallations) =>
        installation.installationId === installationId);
    this.auditInstallationCopy[indexOfChangedData][changedField as keyof AuditInstallations] = checkedValue.checked;
    this.installationDataWasModified = true;

    this.calculateAuditTotal();
  }

  public changeAuditField(): void {
    this.auditCopy.reference = this.referenceForm.value;
    this.auditDataWasModified = true;
  }

  public thisInstallationExists(installationNodeId: string | null): AuditInstallations | Installation | undefined {
    let installationExists: AuditInstallations | Installation | undefined = this.installationList.find(installationFound => installationFound.installationId === installationNodeId);
    if (!installationExists) {
      installationExists = this.auditInstallationSet.find(installationFound => installationFound.installationId === installationNodeId);
    }

    return installationExists;
  }

  public searchInstallation(): void {
    this.isLoadingNewInstallation = true;
    let company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : '';
    if (company) {
      this.subscriptions = this.installationService.getInstallationByReference(this.searchInstallationForm.value, null, 20)
        .valueChanges
        .subscribe({
          next: (response: any) => {
            let responseData = response.data.installation.edges;
            responseData.forEach((installation: any) => {
              if (!this.thisInstallationExists(installation.node.id)) {
                this.installationList.push(
                  new Installation(
                    installation.node.id,
                    installation.node.reference
                  )
                )
              }
            });
            if (this.installationList.length > 0) {
              const dialogRef = this.dialog.open(InstallationsModalComponent, { data: { installationList: this.installationList }, disableClose: true });
              this.subscriptions = dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                  this.installationList = result;
                }
              });
            } else {
              /** Exibe alerta ao usuário **/
              Swal.fire({
                title: 'Instalação não encontrada',
                text: 'Não foi encontrada nenhuma instalação com a referência informada.',
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            }
            this.isLoadingNewInstallation = false;
            this.searchInstallationForm.reset();
          },
          error: (error: any) => {
            console.log(error.message);
            this.isLoadingNewInstallation = false;
            this.errorLibService.errorAlert(error);
          }
        })
    }
  }

  public openModalWithInstallations(): void {
    this.dialog.open(InstallationsModalComponent, { data: { installationList: this.installationList }, disableClose: true });
  }

  public calculateAuditTotal(): void {
    let ictiTotal = 0;
    let icviTotal = 0;
    let registerTotal = 0;
    let statusTotal = 0;
    let variablesTotal = 0;
    let daytimeOperationTotal = 0;
    let nighttimeOperationTotal = 0;
    let actuationTotal = 0;

    this.auditInstallationCopy.forEach((installation: AuditInstallations) => {
      if (installation.transmissionComplianceIndicator) {
        ictiTotal++;
      }
      if (installation.register) {
        registerTotal++;
      }
      if (installation.status) {
        statusTotal++;
      }
      if (installation.variables) {
        variablesTotal++;
      }
      if (installation.daytimeOperation) {
        daytimeOperationTotal++;
      }
      if (installation.nighttimeOperation) {
        nighttimeOperationTotal++;
      }
      if (installation.actuation) {
        actuationTotal++;
      }
    });
    ictiTotal = Number((ictiTotal / this.auditInstallationCopy.length).toFixed(2));
    registerTotal = Number((registerTotal / this.auditInstallationCopy.length).toFixed(2));
    statusTotal = Number((statusTotal / this.auditInstallationCopy.length).toFixed(2));
    variablesTotal = Number((variablesTotal / this.auditInstallationCopy.length).toFixed(2));
    daytimeOperationTotal = Number((daytimeOperationTotal / this.auditInstallationCopy.length).toFixed(2));
    nighttimeOperationTotal = Number((nighttimeOperationTotal / this.auditInstallationCopy.length).toFixed(2));
    actuationTotal = Number((actuationTotal / this.auditInstallationCopy.length).toFixed(2));
    this.auditDataTotal = new auditTotals(
      ictiTotal,
      icviTotal,
      registerTotal,
      statusTotal,
      variablesTotal,
      daytimeOperationTotal,
      nighttimeOperationTotal,
      actuationTotal,
    )
  }

  public saveInstallationAuditData(): void {
    this.subscriptions = this.auditorshipService.updateAuditInstallationData(this.auditCopy.id, this.auditInstallationSet)
      .subscribe({
        next: () => {
        },
        error: (error: any) => {
          console.log(error.message);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar',
            text: `O sistema retornou: ${error}}`,
          })
        }
      })
  }

  public saveAuditNewData(): void {
    if (this.installationDataWasModified) {
      this.saveInstallationAuditData();
    }
    if (this.auditDataWasModified) {
      this.saveBasicAuditData();
    }
    if (this.installationList.length > 0) {
      this.addNewInstallationToAudit()
    }
    Swal.fire({
      icon: 'success',
      title: 'Dados salvos',
      text: 'Os dados da auditoria foram salvos com sucesso!',
      timer: 2000,
      showConfirmButton: false
    });
    this.installationDataWasModified = false;
    this.auditDataWasModified = false;
    this.installationList = [];
  }

  public saveBasicAuditData(): void {
    if (this.auditDataWasModified) {
      let startDate = new Date(this.startDateForm.value)
      let formatedStartDate = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
      let endDate;
      let formatedEndDate = null;
      if (this.endDateForm.value) {
        endDate = new Date(this.endDateForm.value);
        formatedEndDate = endDate.getFullYear() + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);
      }
      this.subscriptions = this.auditorshipService.updateAuditBasicData(
        this.editedAuditIdParam,
        this.referenceForm.value,
        formatedStartDate,
        formatedEndDate,
        this.auditIsOpen)
        .subscribe({
          next: () => { },
          error: (error: any) => {
            Swal.fire({
              title: 'Oops...',
              text: 'Algo de errado aconteceu, por favor tente novamente!',
              timer: 2000,
              icon: 'error'
            })
          }
        })
    }
  }

  public cleanEndDate(): void {
    this.auditDataWasModified = true;
    this.endDateForm.setValue(null);
  }

  public createInstallationSet(installation: any): AuditInstallations {
    let newInstallationSet: AuditInstallations = new AuditInstallations(
      installation.installationId,
      installation.installationReference,
      installation.transmissionComplianceIndicator,
      installation.register,
      installation.status,
      installation.variables,
      installation.daytimeOperation,
      installation.nighttimeOperation,
      installation.actuation
    )
    return newInstallationSet
  }

  public addNewInstallationToAudit(): void {
    this.subscriptions = this.auditorshipService.addInstallationToAudit(this.editedAuditIdParam, this.installationList)
      .subscribe({
        next: ((response: any) => {
          this.installationList = [];
          location.reload();
        }),
        error: ((error: any) => {
          console.log(error.message);
          this.errorLibService.errorAlert(error);
        })
      })
  }

  public closeAudit(): void {
    let startDate = new Date(this.startDateForm.value)
    let formatedStartDate = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
    // get todat date and format it
    let endDate;
    let formatedEndDate: any = null;
    if (this.endDateForm.value) {
      endDate = new Date(this.endDateForm.value);
      formatedEndDate = endDate.getFullYear() + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);
    }
    Swal.fire({
      title: 'Tem certeza que deseja fechar a auditoria?',
      showDenyButton: true,
      confirmButtonText: 'Fechar auditoria',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.subscriptions = this.auditorshipService.closeAudit(this.editedAuditIdParam, formatedStartDate, formatedEndDate)
          .subscribe({
            next: () => {
              let company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : '';
              this.subscriptions = this.auditorshipService.generateIcti(company, this.editedAuditIdParam)
                .subscribe({
                  next: () => { },
                  error: (error: any) => {
                    this.subscriptions = this.auditorshipService.openAudit(this.editedAuditIdParam, formatedStartDate, formatedEndDate)
                      .subscribe({
                        next: () => { },
                        error: (error: any) => {
                          console.log(error.message);
                          this.errorLibService.errorAlert(error);
                        }
                      });
                    console.log(error.message);
                    this.errorLibService.errorAlert(error);
                  }
                })
              this.auditorshipService.generateIcvi(company, this.editedAuditIdParam)
                .subscribe({
                  next: () => { },
                  error: (error: any) => {
                    this.subscriptions = this.auditorshipService.openAudit(this.editedAuditIdParam, formatedStartDate, formatedEndDate)
                      .subscribe({
                        next: () => { },
                        error: (error: any) => {
                          console.log(error.message);
                          this.errorLibService.errorAlert(error);
                        }
                      })
                    console.log(error.message);
                    this.errorLibService.errorAlert(error);
                  }
                })
              this.auditorshipService.generateMprev(company, this.editedAuditIdParam)
                .subscribe({
                  next: () => { },
                  error: (error: any) => {
                    this.subscriptions = this.auditorshipService.openAudit(this.editedAuditIdParam, formatedStartDate, formatedEndDate)
                      .subscribe({
                        next: () => { },
                        error: (error: any) => {
                          console.log(error.message);
                          this.errorLibService.errorAlert(error);
                        }
                      })
                    console.log(error.message);
                    this.errorLibService.errorAlert(error);
                  }
                })
              this.auditorshipService.generateSdot(company, this.editedAuditIdParam).subscribe({
                next: (() => {
                  Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Auditoria fechada com sucesso! Enviado comando para gerar relatórios ICTI, ICVI, SDOT, SDFT e MPREV, esses estarão disponíveis em breve para download.',
                  }).then(() => {
                    this.router.navigate(['/audit']);
                  });
                }), error: (error: any) => {
                  this.subscriptions = this.auditorshipService.openAudit(this.editedAuditIdParam, formatedStartDate, formatedEndDate)
                    .subscribe({
                      next: () => { },
                      error: (error: any) => {
                        console.log(error.message);
                        this.errorLibService.errorAlert(error);
                      }
                    })
                  console.log(error.message);
                  this.errorLibService.errorAlert(error);
                }
              })
            }, error: (error: any) => {
              console.log(error.message);
              this.errorLibService.errorAlert(error);
            }
          })
      } else if (result.isDenied) {
        Swal.fire('Auditoria não fechada', '', 'info')
      }
    })
  }

  public selectInstallationForDeletion(event: any, installationId: any): void {
    console.log(event.checked, installationId);
    if (event.checked) {
      let checkedInstallationIndex = this.auditInstallationSet.findIndex((installation) => installation.installationId === installationId);
      this.auditInstallationSet[checkedInstallationIndex].checked = true;
      this.installationDeletionList.push(installationId);
    } else {
      let checkedInstallationIndex = this.auditInstallationSet.findIndex((installation) => installation.installationId === installationId);
      delete this.auditInstallationSet[checkedInstallationIndex].checked;
      this.installationDeletionList = this.installationDeletionList.filter((installation) => installation !== installationId);
    }
    console.log(this.installationDeletionList);
  }

  public selectAllinstallationForDeletion(event: any): void {
    this.installationDeletionList = [];
    if (event.checked) {
      this.auditInstallationSet.forEach((installation) => {
        installation.checked = true;
        this.installationDeletionList.push(installation.installationId);
      })
    } else {
      this.auditInstallationSet.forEach((installation) => {
        delete installation.checked;
        this.installationDeletionList = [];
      })
    }
    console.log(this.installationDeletionList);
  }

  public deleteInstallations(): void {
    Swal.fire({
      title: 'Tem certeza que deseja deletar as instalações selecionadas?',
      showDenyButton: true,
      confirmButtonText: 'Deletar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log(this.acivatedRoute.snapshot.paramMap.get('id'));
        this.subscriptions = this.auditorshipService.deleteInstallations(this.editedAuditIdParam, this.installationDeletionList)
          .subscribe({
            next: (response: any) => {
              Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Instalação(ões) deletada(s) com sucesso!',
              }).then(() => {
                this.installationDeletionList = [];
                this.auditInstallationSet = this.auditInstallationSet.filter((installation) => !installation.checked);
                this.dataSource.data = this.auditInstallationSet;
                this.calculateAuditTotal();
              });
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
              console.log(error.message);
            }
          })
      } else if (result.isDenied) {
        Swal.fire('Instalação(ões) não deletada(s)', '', 'info')
      }
    })
  }

  /** Método que lê o arquivo CSV para que possa ser filtrado as instalações */
  public async getTextFromFile(event: any) {
    /** Constante do tipo File */
    const file: File = event.target.files[0];
    let fileContent = await file.text();
    /** Propriedade que recebe os dados do arquivo  */
    this.data = fileContent;
    /** Habilita o botão de importação */
    this.disabledImportButton = true;

  }

  /** Método que realiza a leitura e validação dos dados vindos do arquivo csv (referências das instalações)  **/
  public async importDataFromCSV() {
    /** Armazena os dados do CSV */
    this.dataToImport = this.auditorshipService.importDataFromCSV(this.data);
    /** Armazena o objeto que contém a propriedade Reference que representa a referência da instalação */
    const referenceInstallation: any = this.dataToImport.map(row => ({
      reference: row.reference,
    }
    ));

    /** Para cada elemento do arquivo */
    for (const data of referenceInstallation) {
      /** Caso o cabeçalho seja inválido */
      if (!data.reference) {
        /** exibe um alerta ao usuário */
        await Swal.fire({
          title: 'Cabeçalho ou formato de arquivo incorreto',
          text: 'Por favor, verifique o arquivo e tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });

        /** Desativa o Loading */
        this.isLoadingImportFilter = false;
        return;
      }

      /** Desabilita o botão de Importação */
      this.disabledImportButton = false;

      /** Ativa o Loading */
      this.isLoadingImportFilter = true;

      /** Variável que armazena as referências das instalações */
      let installationReferences = data.reference

      this.installationService.getInstallationByReference(installationReferences)
        .valueChanges.subscribe({
          next: ((res: any) => {
            /** Ativa o botão de Importação */
            this.disabledImportButton = true;

            /** Variável que armazena a resposta da requisição **/
            let responseData = res.data.installation.edges;

            /** Itera sobre os elementos do response (caso existam as referências) **/
            responseData.forEach((installation: any) => {
              /** Adiciona as instalações existentes na lista onde a partir dela o modal de visualização de instalações é exibido **/
              this.installationList.push(
                new Installation(
                  installation.node.id,
                  installationReferences
                )
              )

              /** Desativa o Loading */
              this.isLoadingImportFilter = false;
            })

            /** Constante que armazena o nó de todas as referências 
             * (Está sendo utilizado para filtrar e comparar todas as referências do arquivo com as referências existentes na api) */
            const installationReferenceNode: InstallationImports[] = responseData.map((edge: any) => edge.node)
              /** Filtro comparando a referência do arquivo com a referência exata já existente **/
              .filter((installation: InstallationImports) =>
                installation?.reference === installationReferences
              )

            /** Caso a referencia da instalação existente no arquivo não seja encontrada **/
            if (installationReferenceNode.length === 0) {
              /** Adiciona a(s) referência(s) em uma lista **/
              this.installationsNotFound.push(installationReferences)
              /** Caso a lista de referência(s) não encontrada(s) seja igual a quantidade de linhas do arquivo **/
              if (this.installationsNotFound.length === referenceInstallation.length) {
                /** Exibe alerta ao usuário **/
                Swal.fire({
                  title: 'Nenhuma instalação encontrada',
                  text: 'Não foi encontrada nenhuma instalação correspondente à referência informada. Por favor, verifique o arquivo e tente novamente.',
                  icon: 'warning',
                  confirmButtonText: 'Ok'
                });

                /** Desativa o Loading */
                this.isLoadingImportFilter = false;
              }
            }
            /** Limpa o valor do Input de importação */
            this.fileUploadSimple.nativeElement.value = null;
          })
        })
    }
  }
}

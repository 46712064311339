import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ADD_INSTALLATIONS_ON_AUDIT, AUDITORSHIP_QUERY, CREATE_AUDITORSHIP, DELETE_AUDIT_INSTALLATION, EDIT_AUDITORSHIP_BASIC_DATA, GET_AUDIT_BY_ID, GET_AUDIT_INSTALLATIONS_DATA, GET_ICTI_URL, GET_ICVI_URL, GET_MPREV_URL, GET_SDOT_URL, GET_MINAS_GENERATED_REPORTS, QUERY_INSTALLATIONS, UPDATE_AUDIT_INSTALLATION_DATA } from 'src/app/graphql/audit.graphql';
import { AuditInstallations } from '../components/report-audit/models/auditInstallations.model';

@Injectable({
  providedIn: 'root'
})
export class AuditorshipService {

  editingAudit: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  selectedAuditId: EventEmitter<string> = new EventEmitter<string>();
  seeingAudit: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(private apollo: Apollo) { }

  company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  getAudits() {
    return this.apollo.watchQuery({
      query: AUDITORSHIP_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        id: "",
        sort_dir: "DESC",
        sort_field: "START_DATE",
        company: this.company
      }
    })
  }

  generateIcti(company: string | null, auditorship: string | null) {
    return this.apollo.mutate({
      mutation: GET_ICTI_URL,
      variables: {
        company: company,
        auditorship: auditorship
      }
    })
  }

  generateIcvi(company: string | null, auditorship: string | null) {
    return this.apollo.mutate({
      mutation: GET_ICVI_URL,
      variables: {
        company: company,
        auditorship: auditorship
      }
    })
  }

  generateMprev(company: string | null, auditorship: string | null) {
    return this.apollo.mutate({
      mutation: GET_MPREV_URL,
      variables: {
        company: company,
        auditorship: auditorship
      }
    })
  }

  generateSdot(company: string | null, auditorship: string | null) {
    return this.apollo.mutate({
      mutation: GET_SDOT_URL,
      variables: {
        company: company,
        auditorship: auditorship
      }
    })
  }

  getIctiIcvi() {
    return this.apollo.watchQuery({
      query: GET_MINAS_GENERATED_REPORTS,
      variables: {
        company: this.company,
        direction: "DESC",
        field: "GENERATION_DATE"
      }
    })
  }

  createAuditorship(reference: string | null) {
    return this.apollo.mutate({
      mutation: CREATE_AUDITORSHIP,
      fetchPolicy: 'no-cache',
      variables: {
        reference: reference,
        company: this.company
      }
    })
  }

  getAuditorshipById(id: string | null) {
    return this.apollo.watchQuery({
      query: GET_AUDIT_BY_ID,
      variables: {
        id: id,
        company: this.company
      }
    })
  }

  getAuditorshipInstallations(id: string | null) {
    return this.apollo.watchQuery({
      query: GET_AUDIT_INSTALLATIONS_DATA,
      variables: {
        id: id,
        company: this.company
      }
    })
  }

  updateAuditInstallationData(auditorshipId: string | null, installations: AuditInstallations[]) {
    return this.apollo.mutate({
      mutation: UPDATE_AUDIT_INSTALLATION_DATA,
      variables: {
        auditorship: auditorshipId,
        installations: installations,
      }
    })
  }

  addInstallationToAudit(auditorshipId: string | null, installations: any[]) {
    return this.apollo.mutate({
      mutation: ADD_INSTALLATIONS_ON_AUDIT,
      variables: {
        auditorship: auditorshipId,
        installations: installations
      }
    })
  }

  updateAuditBasicData(id: string | null, auditReference: string | null, startDate: string | null, endDate: String | null, isOpen: boolean | null) {
    return this.apollo.mutate({
      mutation: EDIT_AUDITORSHIP_BASIC_DATA,
      variables: {
        id: id,
        auditReference: auditReference,
        startDate: startDate,
        endDate: endDate,
        isOpen: isOpen,
        company: this.company
      }
    })
  }

  closeAudit(id: string | null, startDate: string | null, endDate: string | null) {
    return this.apollo.mutate({
      mutation: EDIT_AUDITORSHIP_BASIC_DATA,
      variables: {
        id: id,
        startDate: startDate,
        endDate: endDate,
        isOpen: false,
        company: this.company
      }
    })
  }

  openAudit(id: string | null, startDate: string | null, endDate: string | null) {
    return this.apollo.mutate({
      mutation: EDIT_AUDITORSHIP_BASIC_DATA,
      variables: {
        id: id,
        startDate: startDate,
        endDate: endDate,
        isOpen: false,
        company: this.company
      }
    })
  }

  deleteInstallations(auditorship: string | null, installations: string[] | null) {
    return this.apollo.mutate({
      mutation: DELETE_AUDIT_INSTALLATION,
      variables: {
        auditorship: auditorship,
        installations: installations
      }
    })
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }

  downloadCsv(auditInstallationSet: AuditInstallations[]): void {
    //const selectedAudit = this.auditListData.filter((audit) => audit.id === id)
    //console.log(this.auditInstallationSet[0])

    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    auditInstallationSet.forEach((audit) => {

      /** Os 4 testes realizados pela IP Minas forem válidos, SDFT = Sim, caso um seja Não, SDFT = Não **/
      let sdft = (audit.register && audit.status && audit.variables && audit.actuation)

      content.push({
        'Referência': audit.installationReference,
        'ICTI': audit.transmissionComplianceIndicator ? "Sim" : "Não",
        'SDFT Cadastro': audit.register ? "Sim" : "Não",
        'SDFT Status': audit.status ? "Sim" : "Não",
        'SDFT Variáveis': audit.variables ? "Sim" : "Não",
        'SDFT Atuação': audit.actuation ? "Sim" : "Não",
        'OPDIU': audit.daytimeOperation ? "Sim" : "Não",
        'OPNOT': audit.nighttimeOperation ? "Sim" : "Não",
        'SDFT': sdft ? "Sim" : "Não"
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `SDFT_${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    // window.URL.revokeObjectURL(url);

    auditInstallationSet = [];
  }

  public importDataFromCSV(csvText: string): Array<any> {
    // Remover caracteres de retorno de carro
    csvText = csvText.replace(/\r/g, '');

    // Pega a primeira linha do arquivo e define como o cabeçalho do arquivo, realizando a quebra de linha
    const propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');

    // Constante que armazena as linhas com os dados
    const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');

    // Array para armazenar a lista de objetos
    let dataArray: any[] = [];

    // Para cada linha do arquivo
    dataRows.forEach((row: any) => {
      // Instancia um novo objeto abstrato
      let obj: any = new Object();

      // Pega o valor da linha
      let val: any = row

      // Seta nulo para itens vazios
      if (val === '') {
        val = null;
      }

      // Seta no objeto uma propriedade passando o valor do índice compatível
      obj[propertyNames[0]] = val;

      // Adiciona o objeto referente a linha de dados
      dataArray.push(obj);
    });

    // Retorna uma lista de objetos referente aos dados do CSV
    return dataArray;
  }

  getInstallationByReference(referenceContains: string) {
    return this.apollo.watchQuery({
      query: QUERY_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains
      }
    })
  }
}


<div class="component-main-container">
  <div class="header">
    <app-page-header pageTitle="{{ 'alerts.alerts-title-label' | translate }}"
      parentTitle="{{ 'alerts.alerts-parent-label' | translate }}">
    </app-page-header>
  </div>

  <div class="row">
    <!-- Form Div -->
    <div class="col-lg-5 col-xl-4 col-md-12">
      <!-- Filtrar registro de alerta -->
      <div class="component-forms-box">
        <div class="form-fields-box" *ngIf="!isEditingAlert" [formGroup]="filterForm"
          [ngClass]="!filter ? 'closed' : ''">
          <h2 (click)="FilterBox()">{{ "alerts.form-filter-alerts" | translate }}</h2>
          <hr [ngClass]="!filter ? 'hr-open' : ''" />
          <!-- Campo de busca de instalação -->
          <div class="form-step">
            <app-circle-number stepNumber="1"></app-circle-number>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "alerts.form-filter-search-installation" | translate}}
              </mat-label>
              <input (keydown.enter)="searchInstallation()" [formControl]="installationSearchFormControl" matInput />
              <button matSuffix mat-icon-button aria-label="Search" class="component-search-icon-button"
                (click)="searchInstallation()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <mat-progress-bar *ngIf="(loadingInstallations$ | async)" mode="indeterminate"></mat-progress-bar>
          <br *ngIf="(loadingInstallations$ | async)">
          <!-- Campo de seleção de instalação -->
          <div class="form-step" *ngIf="searchedInstallations.length > 0">
            <app-circle-number stepNumber="1.1" (isSubstep)="(true)"></app-circle-number>
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{
                "alerts.form-filter-installation" | translate
                }}</mat-label>
              <mat-select [formControl]="installationSelectFormControl">
                <mat-progress-bar *ngIf="(loadingAlertRules$ | async)" mode="indeterminate">
                </mat-progress-bar>
                <mat-option [value]="">
                  {{ "alerts.form-nothing" | translate }}
                </mat-option>
                <mat-option *ngFor="let installation of searchedInstallations"
                  [value]="installation.installationReference">
                  {{ installation.installationReference }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Campo data -->
          <div class="form-step">
            <app-circle-number stepNumber="2"></app-circle-number>
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-date" | translate }}</mat-label>

              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDate" name="startDate" placeholder="Start date" />
                <input matEndDate formControlName="endDate" name="endDate" placeholder="End date" />
              </mat-date-range-input>

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <!-- Campo status -->
          <div class="form-step">
            <app-circle-number stepNumber="3"></app-circle-number>
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{ "alerts.form-filter-options-os" | translate }}</mat-label>

              <mat-select formControlName="status" name="status">
                <mat-option [value]="">{{ "alerts.form-nothing" | translate }}</mat-option>
                <mat-option value="NEW">Novo</mat-option>
                <mat-option value="AWARE">Ciente</mat-option>
                <mat-option value="MAINTENANCE_REQUESTED">O.S. aberta</mat-option>
                <mat-option value="RESOLVED">Resolvido</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Campo regra de alerta -->
          <div class="form-step">
            <app-circle-number stepNumber="4"></app-circle-number>
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{
                "alerts.form-filter-alert-rule" | translate
                }}</mat-label>

              <mat-select formControlName="alertRule">
                <mat-progress-bar *ngIf="loadingAlertRules$ | async" mode="indeterminate">
                </mat-progress-bar>
                <mat-option [value]="">{{ "alerts.form-nothing" | translate }}</mat-option>
                <mat-option *ngFor="let alertRule of alertRules$ | async" [value]="alertRule.id">
                  {{ alertRule.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button title="{{ 'alerts.form-button-filter-alert' | translate }}" class="btn btn-success"
            (click)="filterAlerts()" *ngIf="!(loadingAlerts$ | async)">
            {{ "alerts.form-button-filter-alert" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="(loadingAlerts$ | async)"></mat-spinner>
        </div>

        <app-alert-edit *ngIf="isEditingAlert" [editedAlertId]="editedAlertId" (editCancelation)="onCancelEdit($event)"
          (editAlert)="onEditAlert($event)"></app-alert-edit>
      </div>
    </div>

    <!-- TABLE  -->
    <div class="col-lg-7 col-xl-8 col-md-12" *ngIf="pageInfo.total">
      <app-table-header [itensNumber]="pageInfo.count" [totalNumber]="pageInfo.total" [hasCSV]="true" [hasPDF]="true"
        (downloadCSV)="onDownloadCSV()" (downloadPDF)="onDownloadPDF()">
      </app-table-header>

      <div class="component-table-box">
        <mat-progress-bar *ngIf="(loadingAlerts$ | async)" mode="indeterminate"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{ "alerts.column-alert-date" | translate }} </th>
            <td mat-cell *matCellDef="let row">
              {{ row.date | customDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="point">
            <th mat-header-cell *matHeaderCellDef> {{ "alerts.column-point" | translate }} </th>
            <td mat-cell *matCellDef="let row">
              {{row.point }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rule">
            <th mat-header-cell *matHeaderCellDef>
              {{ "alerts.column-rule" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{
              row.rule }}</td>
          </ng-container>

          <ng-container matColumnDef="occurrences">
            <th mat-header-cell *matHeaderCellDef>
              {{ "alerts.column-occurrences" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{
              row.occurrences }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ "alerts.column-status" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{
              row.status }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef=>
              {{"alerts.column-actions" | translate}}
            </th>
            <td mat-cell *matCellDef="let row" on-mouseover="row.view = true" on-mouseleave="row.view = false">
              <button *ngIf="row.status != 'CLOSED' && !isEditingAlert" (click)="editAlert(row.id)"
                [ngClass]="row.view === true ? 'hover' : ' '" class="btn btn-success mb-2 w-100 view">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                  <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z" />
                  <path
                    d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                </svg>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10]"
          aria-label="Selecione as páginas de Alertas" class="mat-paginator-sticky" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
import { gql } from "apollo-angular";

export const GET_ALL_ALERTS = gql`
query Alert(
  $company: ID!
  $First: Int
  $cursor: String
  $sort_dir: RatatoskrDirection!
  $sort_field: AlertOrdenationFields!
  $installation: String
  $startDateTime: DateTime
  $endDateTime: DateTime,
  $status: [AlertStatus]
  $alertRule: ID
  ) {
  alert(
    company: $company
    First: $First
    After: $cursor
    orderBy:{
      direction: $sort_dir
      field: $sort_field
    }
    startDatetime: $startDateTime
    endDatetime: $endDateTime
    installation: {
		reference: $installation
	}
    status: $status
    alertRule: $alertRule
    ) {
      count
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          alertDatetime
          status
          installation {
            reference
          }
          alertRule {
            name
          }
          occurrences
        }
        cursor
    }
  }
}`

export const GET_ALERT_RULES = gql`
query AlertRule(
			$company: ID!,
			$first: Int,
			$cursor: String,
			$sort_dir: RatatoskrDirection!,
			$sort_field: AlertRuleOrderFields!
			) {
			alertRules(
				company: $company,
				first: $first,
				after: $cursor,
				orderBy:{
					direction: $sort_dir,
					field: $sort_field
					}
				) {
				edges {
					node {
						id
						name
						#propertyRules{
						#	comparisonRule
						#	comparisonValue
						#	monitoredProperty{
						#		name
						#		measurementUnit {
						#			symbol
						#		}
						#	}
						#}
					}
					cursor
				}
				total
				count
				pageInfo {
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				}
			}
		}`

export const GET_ALERT_BY_ID = gql`
query node($id: ID!) {
			node(id: $id) {
				...on Alert {
					id
					alertDatetime
					deviceMac
					status
					installation {
						id
						reference
						applications
						site {
							timezone
						}
					}
					alertRule {
						name
						propertyRules {
							comparisonRule
							comparisonValue
							monitoredProperty {
								name
								measurementUnit {
									symbol
								}
							}
						}
					}
					generatedEvents {
						timestamp
						properties
					}
				}
			}
		}
`

export const CREATE_SERVICE_ORDER = gql`mutation ServiceOrderCreate(
  $priority: Priority!,
  $status: ServiceOrderStatus!,
  $installation: ID!,
  $alerts: [ID],
  $company: ID!,
  $problem_reference: String,
  $problem_details: String,
  $problem_address: String,
  $complainant_name: String,
  $complainant_landlineNumber: String,
  $complainant_phoneNumber: String,
  $complainant_cpf: String,
  $solution_closure_date: Date,
  $solution_details: String,
  $solution_address: String
  ){
  serviceOrderCreate(input:{
    priority:$priority,
    status:$status,
    installation: $installation,
    alerts: $alerts,
    company:$company,
    problem:{
      reference: $problem_reference,
      details: $problem_details,
      address: $problem_address
    },
    complainant: {
      name: $complainant_name,
      landlineNumber: $complainant_landlineNumber,
      phoneNumber: $complainant_phoneNumber,
      cpf: $complainant_cpf
    },
    solution: {
      closureDate: $solution_closure_date,
      details: $solution_details,
      address: $solution_address
    }
  }){
    serviceOrder{
    id
    status
    installation{
        id
      }
    }
  }
}`

export const GET_SERVICE_ORDERS = gql`query ServiceOrder(
	$company: ID!
	$field: ServiceOrderOrdenationFields!
	$direction: RatatoskrDirection!
  ) {
	serviceOrder(
	  company: $company,
	  orderBy: {
		field: $field,
		direction: $direction
	  }
	) {
	  count
	  edges {
		node {
		  id
		  serviceIdentifier
		  creationDate
		  status
		}
	  }
	}
  }`

export const GET_SERVICE_ORDER_INFO = gql`
  query ServiceOrder(
	$company: ID!,
	$identifier: String,
	$alerts: [ID]
	) {
	serviceOrder(
		serviceIdentifier: $identifier,
		company: $company,
		alerts: $alerts
		) {
		count
		edges {
			node{
				id
				operator{
					id
					username
				}
				installation{
					id
					reference
				}
				serviceIdentifier
				creationDate
				priority
				status
				problem{
					reference
					details
				}
				complainant{
					name
				}
			}
		}
	}
}`

export const ADD_SERVICE_ORDER_TO_ALERT = gql`
mutation ServiceOrderAddAlert (
							$id: ID!,
							$alerts: [ID],
							$clientMutationId: String
						) {
							serviceOrderAddAlert (
								input: {
									id: $id,
									alerts: $alerts,
									clientMutationId: $clientMutationId
								}) {
								serviceOrder {
									id
									status
								}
							}
						}`

export const CHANGE_ALERT_STATUS = gql`
mutation
						AlertStatusBulkUpdate(
							$ids: [ID]!,
							$status: AlertStatus!) {
							alertStatusBulkUpdate(
								input: {
									ids: $ids,
									status: $status
								}) {
								alertsUpdated {
									id
									status
								}
								errors {
									id
									cause
								}
							}
						}`

export const GET_CSV_DOWNLOAD_LINK = gql`
mutation alertReport(
				$company: ID!
				$alertRule: ID
				$startDatetime: DateTime
				$endDatetime: DateTime
				$installation: AlertInstallationFilterFields
				$status: [AlertStatus]
			){
				alertReport(input: {
					company: $company
					alertRule: $alertRule
					startDatetime: $startDatetime
					endDatetime: $endDatetime
					installation: $installation
					status: $status
				}){
					fileUrl
				}
			}`

// export const CREATE_SERVICE_ORDER = gql`
// `

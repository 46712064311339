import { gql } from "apollo-angular";

const FILTER_PERMISSIONS_GROUP = gql`
query AvailableGroups(
	$after: String
	$before: String
	$name: String
	$sort_dir: Direction!
	$sort_field: GroupOrdenationFields!
	$first: Int
	$last: Int
) {
	availableGroups(
		after: $after
		before: $before
		name: $name
		first: $first,
		last: $last
		orderBy: { direction: $sort_dir, field: $sort_field }
	) {
		edges {
			node {
				id
				name
				userSet {
					count
				}
				permissions {
					edges{
						node{
							id
							name
							codename
						}
					}
				}
			}
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const CREATE_PERMISSIONS_GROUP = gql`
mutation GroupCreate($name: String!) {
	groupCreate(input: { name: $name }) {
		group {
			id
		}
	}
}`;

const GROUP_SET_PERMISSIONS = gql`
mutation GroupSetPermission($groupId: ID!, $permissionIds: [ID]!) {
	groupSetPermission(
		input: { groupId: $groupId, permissionIds: $permissionIds }
	) {
		group {
			id
		}
	}
}`;

const GET_AVAILABLE_PERMISSIONS = gql`
query AvailablePermissions {
	availablePermissions(orderBy: { direction: ASC, field: NAME }) {
		edges {
			node {
				id
				name
				codename
			}
		}
	}
}`;

const UPDATE_PERMISSIONS_GROUP = gql`
mutation GroupUpdate($id: ID!, $name: String!) {
	groupUpdate(input: { id: $id, name: $name }) {
		group {
			id
		}
	}
}`;

const DELETE_PERMISSIONS_GROUP = gql`
mutation Delete(
    $id: ID!
){
    groupDelete(input: {
        id: $id
    }){
        message
    }
}`;

export {
	FILTER_PERMISSIONS_GROUP,
	CREATE_PERMISSIONS_GROUP,
	GROUP_SET_PERMISSIONS,
	GET_AVAILABLE_PERMISSIONS,
	UPDATE_PERMISSIONS_GROUP,
	DELETE_PERMISSIONS_GROUP
}
import { gql } from "apollo-angular";

const GET_ALL_GATEWAY = gql`
query gateway(
  $company: ID,
  $pageSize: Int
) {
  gatewayInstallation(
    company: $company,
    pageSize: $pageSize
    orderBy: { direction: ASC, field: REFERENCE }
  ) {
    edges{
      node {
      id
      reference
      device{
        serialNumber
      }
    }
  }
    total
    count
    pageInfo {
      hasNextPage
    }
  }
}`;

const GET_ALL_DIVISION = gql`
query division(
	$company: ID,
	$pageSize: Int
){
	division(
		company: $company,
		pageSize: $pageSize
	) {
			edges{
				node{
				id
				reference
			}
			}
	}
}`;

const DETAIL_CONSUPTION = gql`
query node($id: ID!) {
  node(id: $id) {
  ... on Installation {
    device{
      serialNumber
      }
    site {
      reference
      timezone
      country
      state
      city
      district
      street
      number
      details
      locationCode
      geoposition {
        latitude
        longitude
      }
    }
    }
  }
}`;

const ALERT = gql`
query Alert(
  $company: ID!
  $first: Int
  $cursor: String
  $sort_dir: RatatoskrDirection!
  $sort_field: AlertOrdenationFields!
  $installation: AlertInstallationFilterFields
  $start: DateTime
  $end: DateTime
) {
  alert(
    company: $company
    first: $first
    after: $cursor
    orderBy: { direction: $sort_dir, field: $sort_field }
    startDatetime: $start
    endDatetime: $end
    installation: $installation
  ) {
    count
    total
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        alertDatetime
        status
        alertRule {
          name
        }
      }
      cursor
    }
  }
}`;

const LAST_TRANSMISSION = gql`		query node($id: ID!) {
  node(id: $id) {
  ... on Installation {
    indicators{
      lastTransmissionTimestamp
    }
      }
    }
}`

export { GET_ALL_GATEWAY, GET_ALL_DIVISION, DETAIL_CONSUPTION, ALERT, LAST_TRANSMISSION };
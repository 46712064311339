import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_INSTALLATION } from 'src/app/graphql/components.queries';
import { FILTER_INSTALLATION_LOG } from 'src/app/graphql/installation-log.graphql';
import { FILTER_INSTALLATIONS } from 'src/app/graphql/installationQueries.graphql';

@Injectable({
  providedIn: 'root'
})
export class InstallationLogService {

  constructor(private apollo: Apollo) { }
  // Armazena o ID da empresa selecionada (Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  /**
    * Função responsável por filtrar os logs
    */
  public filterLogs(
    operation: any | null,
    startDatetime: any | null,
    endDatetime: any | null,
    username: any | null,
    installationID: string | null,
    cursor: string | null,
    pageSize: number | null
  ): any {

    return this.apollo.watchQuery({
      query: FILTER_INSTALLATION_LOG,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.companyId,
        operation,
        startDatetime,
        endDatetime,
        username,
        installationID,
        cursor,
        pageSize,
      }
    })
  }

  public getInstallationByreference(
    referenceContains: string | null,
  ) {

    return this.apollo.watchQuery({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.companyId,
        referenceContains,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }
}

import { gql } from 'apollo-angular';

/** query para filtrar as instalações **/
const FILTER_INSTALLATIONS = gql`
query Installation(
  $company: ID!
  $referenceContains: String
  $country: String
  $state: String
  $city: String
  $district: String
  $street: String
  $devices: [ID]
  $pageSize: Int
  $cursor: String
  $sort_dir: Direction!
  $sort_field: InstallationOrderFields!
  ) {
  installation(
    company: $company
    referenceContains: $referenceContains
    country: $country
    devices: $devices
    state: $state
    city: $city
    district: $district
    street: $street
    pageSize: $pageSize
    cursor: $cursor
    orderBy: {
      direction: $sort_dir
      field: $sort_field
      }
    ) {
    count
    total
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        reference
        isActive
        hasMagneticKey
        applications
        site {
		  id
          reference
          country
          state
          city
          district
          street
          number
          details
          geoposition {
            latitude
            longitude
          }
        }
        device {
          id
          serialNumber
          macAddress
          equipmentType {
            reference
            major
            minor
            revision
          }
        }
        gateway {
			id
          reference
        }
        parentInstallation {
          id
          reference
        }
        circuitBox {
          id
          sgIpc {
            id
          }
          sgGateway {
            id
          }
          sgConsumption {
            id
          }
        }
        lampType{
          id
          power
          manufacturer
          model
          brightness
          voltage
          current
          consumption
          dimmingType
          powerFactor
        }
        division{
          id
          reference
        }

      }
      cursor
    }
  }
}`;

/** Mutation para criação de instalação **/
const CREATE_INSTALLATION = gql`
mutation InstallationCreate(
	$reference: String!
	$channel: Int!
	$site: ID!
	$equipment: ID
	$gateway: ID
	$circuitBox: ID
	$parentInstallation: ID
	$lampType: ID
	$hasMagneticKey: Boolean
	$applications: [ApplicationTags],
	$division: ID
) {
	installationCreate(
		input: {
			reference: $reference
			deviceId: $equipment
			applications: $applications
			channel: $channel
			gateway: $gateway
			circuitBox: $circuitBox
			parentInstallation: $parentInstallation
			site: $site
			lampType: $lampType
			hasMagneticKey: $hasMagneticKey
			division: $division
		}
	) {
		installation {
			id
			reference
			applications
			gateway{
				id
				reference
			}
			device{
				id
				equipmentType{
					id
					reference
					major
					minor
					revision
				}
			}
			lampType {
				id
				power
				manufacturer
				model
				brightness
				voltage
				current
				consumption
				dimmingType
				powerFactor
			}
			division{
				id
				reference
			}
			circuitBox {
				id
				sgIpc {
					id
					equipmentType {
						reference
					}
					serialNumber
					macAddress
					company {
						id
						name
					}
				}
			}
		}
	}
}`;

/** Mutation para realizar a edição de instalações **/
const UPDATE_INSTALLATION = gql`
mutation InstallationUpdate(
	$id: ID!
	$reference: String!
	$channel: Int!
	$site: ID!
	$deviceId: ID
	$gateway: ID
	$circuitBox: ID
	$parentInstallation: ID
	$applications: [ApplicationTags]
	$isActive: Boolean!
	$hasMagneticKey: Boolean!
	$lampType: ID
	$division: ID
) {
	installationUpdate(
		input: {
			id: $id
			reference: $reference
			deviceId: $deviceId
			applications: $applications
			channel: $channel
			gateway: $gateway
			circuitBox: $circuitBox
			parentInstallation: $parentInstallation
			site: $site
			isActive: $isActive
			lampType: $lampType
			division: $division
			hasMagneticKey: $hasMagneticKey
		}
	) {
		installation {
			id
			reference
			hasMagneticKey
			isActive
      		applications
			site {
				id
				reference
				city
				country
				state
				street
				geoposition
				{
					latitude
					longitude
				}
			}
			device {
				id
				serialNumber
				macAddress
				equipmentType{
					id
					reference
					major
					minor
					revision
				}
			}
			lampType{
				id
				model
			}
			gateway {
				id
				reference
			}
			division{
				id
				reference
			}
		}
	}
}`;

/** Mutation para realizar a exclusão de instalações **/
const DELETE_INSTALLATION = gql`
mutation
InstallationUpdate($id: ID!) {
	installationDelete(input: { id: $id }) {
		message
	}
}`;

/** Mutation para alteração do status da instalação **/
const STATUS_INSTALLATION = gql`
mutation InstallationUpdate(
	$id: ID!
	$reference: String!
	$channel: Int!
	$site: ID!
	$deviceId: ID
	$gateway: ID
	$applications: [ApplicationTags]
	$isActive: Boolean!
) {
	installationUpdate(
		input: {
			id: $id
			reference: $reference
			deviceId: $deviceId
			applications: $applications
			channel: $channel
			gateway: $gateway
			site: $site
			isActive: $isActive
		}
	) {
		installation {
			id
			reference
			hasMagneticKey
			isActive
			applications
			site {
				id
				reference
				city
				country
				state
				street
				geoposition {
					latitude
					longitude
				}
			}
			device {
				id
				serialNumber
				macAddress
				equipmentType {
					id
					reference
					major
					minor
					revision
				}
			}
			lampType {
				id
				model
			}
			gateway {
				id
				reference
			}
			division {
				id
				reference
			}
		}
	}
}
`;

/** Query para realizar a busca dos equipamentos **/
const GET_EQUIPMENTS = gql`
query Equipment(
	$company: ID!
	$serialNumber: String
	$cursor: String
	$pageSize: Int
    $isInstalled: Boolean
) {
	equipment(
		company: $company,
		serialNumber: $serialNumber
		pageSize: $pageSize
		cursor: $cursor
        isInstalled: $isInstalled
	) {
		count
		total
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
			}
		edges {
			node {
				id
				macAddress
				serialNumber
				equipmentType {
					reference
					major
					minor
					revision
				}
			}
		}
	}
}`;

/** Query para realizar o filtro de gateways **/
const GET_GATEWAY_INSTALLATION = gql`
query
GatewayInstallation(
	$company: ID,
	$reference: String
) {
	gatewayInstallation(
		company: $company,
		referenceContains: $reference
	){
		count
		total
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		edges {
			node {
				id
				reference
			}
		}
	}
}`;

/** Query para realizar o filtro de tipos de luminárias **/
const GET_LAMPS_TYPE = gql`
query LampType(
  $manufacturer: String,
  $power: Int,
  $pageSize: Int,
  $cursor: String,
  ) {
  lampType(
      pageSize: $pageSize,
      cursor: $cursor,
      manufacturer: $manufacturer,
      power: $power,
      ) {
      edges {
          node {
              id
      power
      manufacturer
      model
      brightness
      voltage
      current
      consumption
      dimmingType
      powerFactor
          }
          cursor
      }
      total
      count
      pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
      }
  }
}`;

/** Query para realizar o filtro de local de instalação **/
const GET_INSTALLATION_SITE = gql`
query
	InstallationSite(
		$company: ID,
		$reference: String
		) {
		  installationSite (
        company: $company,
        referenceContains: $reference,
        ) {
          count
          total
          pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					  edges {
							node {
								id
								reference
								country
								state
								city
								district
								street
							}
						}
					}
				}`;

/** Query para realizar o filtro por referencia **/
const GET_INSTALLATION_BY_REFERENCE = gql`
query Installation(
	$company: ID!
	$referenceContains: String
){
  installation(
    company: $company,
		referenceContains: $referenceContains
  ){
    edges{
      node{
        id
        reference
		device{
				serialNumber
				macAddress
				}
      }
    }
  }
}`;

/** Query para filtrar as divisões **/
const GET_DIVISION = gql`
query Division($company: ID) {
	division(company: $company) {
		edges{
			node {
			id
			reference
		}
		}
	}
}`;

export {
	FILTER_INSTALLATIONS,
	GET_EQUIPMENTS,
	CREATE_INSTALLATION,
	GET_GATEWAY_INSTALLATION,
	GET_LAMPS_TYPE,
	GET_INSTALLATION_SITE,
	UPDATE_INSTALLATION,
	GET_INSTALLATION_BY_REFERENCE,
	GET_DIVISION,
	DELETE_INSTALLATION,
	STATUS_INSTALLATION
};

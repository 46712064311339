import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  CREATE_PERMISSIONS_GROUP,
  DELETE_PERMISSIONS_GROUP,
  FILTER_PERMISSIONS_GROUP,
  GET_AVAILABLE_PERMISSIONS,
  GROUP_SET_PERMISSIONS,
  UPDATE_PERMISSIONS_GROUP
} from 'src/app/graphql/permissions-group.graphql';

@Injectable({
  providedIn: 'root'
})

export class PermissionsGroupService {

  constructor(
    private apollo: Apollo,
  ) { }

  /** Realiza a consulta que busca todos os grupos de permissões **/
  public filterPermissionsGroup(
    name: string,
    after: string | null,
    before: string | null,
    first: number | null,
    last: number | null,
    pageSize: number | null
  ) {
    return this.apollo
      .watchQuery({
        query: FILTER_PERMISSIONS_GROUP,
        fetchPolicy: 'no-cache',
        variables: {
          name,
          after,
          before,
          first,
          last,
          pageSize,
          sort_dir: "ASC",
          sort_field: "NAME"
        }
      })
  }

  /** Mutation utilizada para realizar a criação de grupos de permissões **/
  public createPermissionsGroup(name: string) {
    return this.apollo.mutate({
      mutation: CREATE_PERMISSIONS_GROUP,
      variables: {
        name
      }
    })
  }

  /** Mutation utilizada para setar as permissões no grupo de permissões **/
  public setPermissionsGroup(groupId: string, permissionIds: string[]) {
    return this.apollo.mutate({
      mutation: GROUP_SET_PERMISSIONS,
      variables: {
        groupId,
        permissionIds
      }
    })
  }

  /** Consulta que filtra as permissões **/
  public getAvailablePermission() {
    return this.apollo.watchQuery({
      query: GET_AVAILABLE_PERMISSIONS,
      variables: {
      }
    })
  }

  /** Mutation que realiza a atualização dos grupos de permissões **/
  public updatePermissionsGroup(id: string, name: string) {
    return this.apollo.mutate({
      mutation: UPDATE_PERMISSIONS_GROUP,
      variables: {
        id,
        name
      }
    })
  }
  /** Mutation que realiza a deleção dos grupos de permissões **/
  public deletePermissionsGroup(id: string) {
    return this.apollo.mutate({
      mutation: DELETE_PERMISSIONS_GROUP,
      variables: {
        id
      }
    })
  }
}




import { gql } from "apollo-angular";

const FILTER_SERVICE_ORDER = gql`
query ServiceOrder(
	$company: ID!,
	$first: Int,
	$cursor: String,
	$before: String,
	$last: Int,
	$sort_dir: RatatoskrDirection!,
	$sort_field: ServiceOrderOrdenationFields!,
	$serviceIdentifier: String
	) {
	serviceOrder(
		company: $company,
		first: $first,
		after: $cursor,
		last: $last,
		serviceIdentifier: $serviceIdentifier,
		before: $before,
		orderBy:{
			direction: $sort_dir,
			field: $sort_field
			}
		) {
			edges{
				node{
				  id
				  status
				  installation{
					id
					reference
				  }
				  operator{
					id
				  }
				  serviceIdentifier
				  company{
					id
				  }
				  creationDate
				  priority
				  alerts{
					edges{
					  node{
						id
					  }
					}
				  }
				  problem{
					reference
					details
					address
					details
				  }
				  solution{
					details
					address
				  }
				  complainant{
					name
					landlineNumber
					phoneNumber
					cpf
				  }
				}
			  }
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const FILTER_ALL_SERVICE_ORDER = gql`
query ServiceOrder(
	$company: ID!,
	$first: Int,
	$cursor: String,
	$sort_dir: RatatoskrDirection!,
	$sort_field: ServiceOrderOrdenationFields!
	) {
	serviceOrder(
		company: $company,
		first: $first,
		after: $cursor,
		orderBy:{
			direction: $sort_dir,
			field: $sort_field
			}
		) {
			edges{
				node{
				  id
				  status
				  installation{
					id
					reference
				  }
				  operator{
					id
				  }
				  serviceIdentifier
				  company{
					id
				  }
				  creationDate
				  priority
				  alerts{
					edges{
					  node{
						id
					  }
					}
				  }
				  problem{
					reference
					details
					address
					details
				  }
				  solution{
					details
					address
				  }
				  complainant{
					name
					landlineNumber
					phoneNumber
					cpf
				  }
				}
			  }
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const GET_INSTALLATIONS = gql`
query Installation(
	$company: ID!,
	$reference: String,
	$first: Int,
	$sort_dir: Direction!,
	$sort_field: InstallationOrderFields!
	) {
	installation(
		company: $company,
		referenceContains: $reference,
		first: $first,
		orderBy:{
			direction: $sort_dir,
			field: $sort_field
			}
		) {
		count
		edges {
			node {
				id
				reference
			}
		}
	}
}`;

const CREATE_SERVICE_ORDER = gql`
mutation ServiceOrderCreate(
	$company: ID!,
	$installation: ID!,
	$status: ServiceOrderStatus!,
	$priority: Priority!,
	$problem_reference: String,
	$problem_details: String,
	$problem_address: String,
	$name: String!,
	$landlineNumber: String!,
	$phoneNumber: String!,
	$cpf: String!
	){
	serviceOrderCreate(input:{
	  installation: $installation,
	  priority:$priority,
	  status:$status,
	  company:$company,
	  problem:{
		reference: $problem_reference,
		details: $problem_details,
		address: $problem_address
	  },
	  complainant: {
		name: $name,
		landlineNumber: $landlineNumber,
		phoneNumber: $phoneNumber,
		cpf: $cpf
	  }
	}){
	  serviceOrder{
		id
		status
		installation{
		  id
		}
	  }
	}
  }`;


const GET_SERVICE_ORDER_CSV = gql`
query ServiceOrder(
	$company: ID!,
	$first: Int,
	$cursor: String,
	$sort_dir: RatatoskrDirection!,
	$sort_field: ServiceOrderOrdenationFields!
	) {
	serviceOrder(
		company: $company,
		first: $first,
		after: $cursor,
		orderBy:{
			direction: $sort_dir,
			field: $sort_field
			}
		) {
			edges{
				node{
				  id
				  status
				  installation{
					id
				  }
				  operator{
					id
				  }
				  serviceIdentifier
				  company{
					id
				  }
				  creationDate
				  priority
				  alerts{
					edges{
					  node{
						id
					  }
					}
				  }
				  problem{
					reference
					details
					address
					details
				  }
				  solution{
					details
					address
				  }
				  complainant{
					name
					landlineNumber
					phoneNumber
					cpf
				  }
				}
			  }
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const UPDATE_SERVICE_ORDER = gql`
mutation ServiceOrderUpdate(
	$id: ID!
	$status: ServiceOrderStatus!
	$priority: Priority!
	$problem_reference: String
	$problem_details: String
	$problem_address: String
	$name: String!
	$landlineNumber: String!
	$phoneNumber: String!
	$cpf: String!
){
serviceOrderUpdate(input:
	{
		id:$id
		status:$status
		priority:$priority
		problem:{
			reference: $problem_reference
			details: $problem_details
			address: $problem_address
	  },
	  complainant: {
			name: $name,
			landlineNumber: $landlineNumber
			phoneNumber: $phoneNumber
			cpf: $cpf
	  }

}){
	serviceOrder{
		id
		priority
		status
		installation{
			id
		}
	}
}
}`;

export {
	FILTER_SERVICE_ORDER,
	FILTER_ALL_SERVICE_ORDER,
	GET_INSTALLATIONS,
	CREATE_SERVICE_ORDER,
	GET_SERVICE_ORDER_CSV,
	UPDATE_SERVICE_ORDER
}
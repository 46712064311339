<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{'installation-site.installation-site-parent-label' | translate}}"
      pageTitle="{{'installation-site.installation-site-title-label' | translate}}">
    </app-page-header>
  </div>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <div class="form-fields-box" [ngClass]="!filterInstallationSiteBox ? 'closed' : ''" [formGroup]="filterForm">

          <h2 (click)="filterInstallationSiteOpen()">{{'installation-site.form-filter-label' | translate}}
          </h2>

          <hr [ngClass]="!filterInstallationSiteBox ? 'hr-open' : ''">

          <div class="form-step first">
            <mat-form-field appearance="outline">
              <mat-label>{{'installation-site.form-filter-adress' | translate}}</mat-label>
              <input matInput #filter formControlName="adress">
            </mat-form-field>
          </div>

          <div class="row form">
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-reference' | translate}}</mat-label>
                <input matInput formControlName="reference">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-zip-code' | translate}}</mat-label>
                <input matInput mask="00000-000" formControlName="locationCode">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-number' |
                  translate}}</mat-label>
                <input matInput type="number" formControlName="number">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-street' | translate}}</mat-label>
                <input matInput formControlName="street">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-neighborhood' | translate}}</mat-label>
                <input matInput formControlName="district">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-city' | translate}}</mat-label>
                <input matInput formControlName="city">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-state' | translate}}</mat-label>
                <input matInput formControlName="state">
              </mat-form-field>
            </div>

            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-country' | translate}}</mat-label>
                <input matInput formControlName="country">
              </mat-form-field>
            </div>
          </div>

          <!-- Botões do filtro. -->
          <div class="d-flex m-2">
            <button *ngIf="!filterInstallationSiteLoading" class="btn btn-success m-1 w-50"
              (click)="filterIntallationSite(null)">
              {{'installation-site.form-filter-button' |
              translate}}
            </button>
            <button *ngIf="filterForm.valid && !filterInstallationSiteLoading" (click)="clearFilter()"
              class="btn btn-secondary m-1 w-50">
              {{'installation-site.form-register-clean-filter' | translate}}
            </button>
            <mat-spinner *ngIf="filterInstallationSiteLoading" [diameter]="30"></mat-spinner>
          </div>
        </div>

        <div class="form-fields-box" [ngClass]="!registerInstallationSite ? 'closed' : ''" [formGroup]="createForm">

          <h2 (click)="registerInstallationSiteOpen()">{{'installation-site.form-register-label' |
            translate}}
          </h2>

          <hr [ngClass]="!registerInstallationSite ? 'hr-open' : ''">
          <div class="form-step first">
            <mat-form-field appearance="outline">
              <mat-label>{{'installation-site.form-register-adress' | translate}}</mat-label>
              <input matInput #register formControlName="address">
            </mat-form-field>
          </div>

          <div class="row form">
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-reference' |
                  translate}}</mat-label>
                <input matInput formControlName="reference">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-zip-code' |
                  translate}}</mat-label>
                <input matInput mask="00000-000" formControlName="locationCode">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-number' |
                  translate}}</mat-label>
                <input matInput type="number" formControlName="number">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-neighborhood' |
                  translate}}</mat-label>
                <input matInput formControlName="district">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-state' |
                  translate}}</mat-label>
                <input matInput formControlName="state">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-filter-city' |
                  translate}}</mat-label>
                <input matInput formControlName="city">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-street' |
                  translate}}</mat-label>
                <input matInput formControlName="street">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-Address-details' |
                  translate}}</mat-label>
                <input matInput formControlName="details">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-latitude' |
                  translate}}</mat-label>
                <input matInput formControlName="lat">
              </mat-form-field>
            </div>
            <div class="form-step">
              <mat-form-field appearance="outline">
                <mat-label>{{'installation-site.form-register-longitude' |
                  translate}}</mat-label>
                <input matInput formControlName="long">
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex m-2">
            <button class="btn btn-success m-1 w-50" (click)="checkInputsCreateInstallationSite()"
              *ngIf="!registerInstallationSiteLoading">{{'installation-site.form-register-button' |
              translate}}
            </button>

            <button (click)="clearRegister()" class="btn btn-secondary m-1 w-50" [disabled]="createForm.invalid">
              {{'installation-site.form-register-clean-filter' | translate}}
            </button>
            <mat-spinner *ngIf="registerInstallationSiteLoading" [diameter]="30"></mat-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="length > 0">
      <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
        [hasCSV]="true" [loadingData$]="fileLoading" [hasPDF]="true" (downloadCSV)="generateFileData(null,'CSV')"
        (downloadPDF)="generateFileData(null,'PDF')"></app-table-header>
      <!-- Sessão tabela -->
      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{'installation-site.column-reference' |
              translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{element.reference}} </td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> {{'installation-site.column-adress' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{ element.street }}</td>
          </ng-container>


          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>{{'installation-site.column-number' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.number}}</td>
          </ng-container>

          <ng-container matColumnDef="district">
            <th mat-header-cell *matHeaderCellDef>{{'installation-site.column-neighborhood' |
              translate}}</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.district}}</td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>{{'installation-site.column-city' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.city}}</td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>{{'installation-site.column-state' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.state}}</td>
          </ng-container>

          <ng-container matColumnDef="coordinates">
            <th mat-header-cell *matHeaderCellDef>{{'installation-site.column:-coordinates' |
              translate}}</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.lat}} {{element.long}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{"installation-site.column-actions" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="openSidebarUpdate(element, true)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="openSidebarUpdate(element, false)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Empresas" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
    <div *ngIf="sideBarOpen" class="sidebar-edit" [formGroup]="updateForm">
      <mat-icon class="component-close-icon" (click)="closeSidebarUpdate()"> clear </mat-icon>
      <h2 class="title-edit" *ngIf="viewController"> {{"installation-site.form-edit-title" | translate}} </h2>
      <h2 class="title-edit" *ngIf="!viewController"> {{"installation-site.form-view-title" | translate}}
      </h2>
      <hr>
      <mat-form-field appearance="outline">
        <mat-label> {{"installation-site.form-edit-adress" | translate}} </mat-label>
        <input matInput #update formControlName="address">
      </mat-form-field>
      <div class="container-flex">
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-street" | translate}} </mat-label>
          <input matInput formControlName="street">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-district" | translate}} </mat-label>
          <input matInput formControlName="district">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-city" | translate}} </mat-label>
          <input matInput formControlName="city">
        </mat-form-field>
      </div>
      <div class="container-flex">
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-reference" | translate}} </mat-label>
          <input matInput [formControl]="reference">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-city" | translate}} </mat-label>
          <input matInput formControlName="city">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-number" | translate}} </mat-label>
          <input matInput type="number" formControlName="number">
        </mat-form-field>
      </div>
      <div class="container-flex">
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-details" | translate}} </mat-label>
          <input matInput [formControl]="details">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-location-code" | translate}} </mat-label>
          <input matInput mask="00000-000" formControlName="locationCode">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-country" | translate}} </mat-label>
          <input matInput formControlName="country">
        </mat-form-field>
      </div>
      <div class="container-flex">
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-state" | translate}} </mat-label>
          <input matInput formControlName="state">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-lat" | translate}} </mat-label>
          <input matInput formControlName="lat">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{"installation-site.form-edit-long" | translate}} </mat-label>
          <input matInput formControlName="long">
        </mat-form-field>
      </div>

      <hr>
      <!-- Aplique um estilo de altura ao container-map para ocupar toda a altura da tela -->
      <div class="full" id="map" #map></div>

      <button (click)="updateInstallationSite()" *ngIf="viewController && !filterInstallationSiteLoading"
        class="btn btn-success m-1 w-50">
        {{"installation-site.form-edit-button-save" | translate}}
      </button>

      <button class="btn btn-danger m-1 w-50" (click)="deleteInstallationSite()">
        {{"installation-site.form-edit-button-delete" | translate}}
      </button>
      <mat-spinner *ngIf="filterInstallationSiteLoading" [diameter]="30"></mat-spinner>
    </div>
  </div>
</div>
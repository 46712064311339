export class EquipmentLog {
  constructor(
    public username: string | null,
    public operation: string | null,
    public logDatetime: string | null,
    public serialNumber: string | null,
    public macAddress: string | null,
    public company: any
  ) { }
}

export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, take, tap, map } from 'rxjs';
import {
  ACTIVATE_DEACTIVATE_ALERT_RULE,
  CREATE_ALERT_RULE, GET_ALERT_RULE_PROPERTIES,
  GET_ALL_ALERT_RULES, GET_ALL_USERS,
  GET_EQUIPMENT_TYPE,
  UPDATE_ALERT_RULE
} from 'src/app/graphql/alert-rules.graphql';
import { User } from '../components/alert-rules/models/user.model';
import { AlertProperty } from '../components/alert-rules/models/property.model';
import { AlertRuleTable } from '../components/alert-rules/models/alert-rule.model';
import { Rule } from '../components/alert-rules/models/rule.model';
import { GET_INSTALLATION_BY_REFERENCE } from 'src/app/graphql/graphql.queries';
import { Weekday } from '../components/alert-rules/models/weekday.model';
import { Installation } from '../components/alert-rules/models/installation.model';
import { EquipmentType } from '../components/alert-rules/models/equipment_type.model';


@Injectable({
  providedIn: 'root'
})
export class AlertRulesService {

  private usersSubject = new BehaviorSubject<User[]>([]);
  users$ = this.usersSubject.asObservable();

  private propertiesSubject = new BehaviorSubject<AlertProperty[]>([]);
  properties$ = this.propertiesSubject.asObservable();

  private equipmentTypesSubject = new BehaviorSubject<EquipmentType[]>([]);
  equipment_types$ = this.equipmentTypesSubject.asObservable();

  private alertRulesSubject = new BehaviorSubject<AlertRuleTable[]>([]);
  alertRules$ = this.alertRulesSubject.asObservable();

  private tableDataLoadingSubject = new BehaviorSubject<boolean>(false);
  tableDataLoading$ = this.tableDataLoadingSubject.asObservable();

  private propertiesLoadingSubject = new BehaviorSubject<boolean>(false);
  propertiesDataLoading$ = this.propertiesLoadingSubject.asObservable();

  private equipmentTypesLoadingSubject = new BehaviorSubject<boolean>(false);
  equipmentTypesDataLoading$ = this.equipmentTypesLoadingSubject.asObservable();

  private usersLoadingSubject = new BehaviorSubject<boolean>(false);
  usersDataLoading$ = this.usersLoadingSubject.asObservable();

  private installationsSubject = new BehaviorSubject<Installation[]>([]);
  installations$ = this.installationsSubject.asObservable();

  company: string | null;

  constructor(private apollo: Apollo) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
    this.getUsers();
    this.getAlertRuleProperties();
    this.getEquipmentTypes();
    this.getAlertRules(null);
  }

  private getUsers(): any {
    this.usersLoadingSubject.next(true);
    this.apollo.watchQuery({
      query: GET_ALL_USERS,
      variables: {
        company: this.company,
        sort_dir: "ASC",
        sort_field: "USERNAME"
      }
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          let userList: User[] = [];
          res.data.user.edges.forEach((user: any) => {
            userList.push(new User(user.node.id, user.node.email, user.node.firstName, user.node.lastName));
          });
          this.usersSubject.next(userList);
          this.usersLoadingSubject.next(false);
        })
      ).subscribe();
  }

  private getAlertRuleProperties(): any {
    this.propertiesLoadingSubject.next(true);
    this.apollo.watchQuery({
      query: GET_ALERT_RULE_PROPERTIES
    })
      .valueChanges
      .pipe(
        take(1),
        tap((response: any) => {
          let propertyList: AlertProperty[] = [];
          response.data.property.edges.forEach((property: any) => {
            if (property.node.name) {
              propertyList.push(new AlertProperty(property.node.id, property.node.name, property.node.measurementUnit.symbol));
            }
          });
          this.propertiesSubject.next(propertyList);
          this.propertiesLoadingSubject.next(false);
        })
      ).subscribe();
  }

  private getEquipmentTypes(): any {
    this.equipmentTypesLoadingSubject.next(true);
    this.apollo.watchQuery({
      query: GET_EQUIPMENT_TYPE
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          let eqpTypeList: EquipmentType[] = [];
          res.data.equipmentType.edges.forEach((eqpType: any) => {
            eqpTypeList.push(new EquipmentType(
              eqpType.node.id,
              eqpType.node.reference,
              eqpType.node.major,
              eqpType.node.minor,
              eqpType.node.revision
            )
            );
          });
          this.equipmentTypesSubject.next(eqpTypeList);
          this.equipmentTypesLoadingSubject.next(false);
        })
      ).subscribe();
  }

  public getAlertRules(reference: string | null) {
    this.tableDataLoadingSubject.next(true);
    return this.apollo.watchQuery({
      query: GET_ALL_ALERT_RULES,
      fetchPolicy: 'network-only',
      variables: {
        reference: reference,
        company: this.company,
      }
    })
  }

  getInstallationListByReference(reference: string | null) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATION_BY_REFERENCE,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains: reference
      }
    })
  }

  installationGetter(reference: string | null) {
    this.getInstallationListByReference(reference);
  }

  alertRulesGetter() {
    return this.alertRules$;
  }

  activeAlertRulesGetter() {
    return this.alertRules$.pipe(
      map((alertRules: AlertRuleTable[]) => alertRules.filter((alertRule: AlertRuleTable) => alertRule.isActive))
    );
  }

  inactiveAlertRulesGetter() {
    return this.alertRules$.pipe(
      map((alertRules: AlertRuleTable[]) => alertRules.filter((alertRule: AlertRuleTable) => !alertRule.isActive))
    );
  }

  usersGetter() {
    return this.users$;
  }

  alertRulespropertiesGetter() {
    return this.properties$;
  }

  bitmaskToWeekdays(bitmask: number): Weekday[] {
    let booleanArray: Weekday[] = [
      new Weekday("S", false),
      new Weekday("T", false),
      new Weekday("Q", false),
      new Weekday("Q", false),
      new Weekday("S", false),
      new Weekday("S", false),
      new Weekday("D", false),
    ];
    (bitmask >>> 0).toString(2).split("").forEach((day: any, i) => {
      if (day == 1) {
        booleanArray[i].selected = true;
      }
    });
    return booleanArray;
  }

  alertRuleCreate(
    alerteventmaxcount: number | null,
    alerttimewindow: number | null,
    company: string | null,
    dailystarttime: string | null,
    dailystoptime: string | null,
    enddate: string | null,
    fullCoverage: boolean,
    installations: (string | null)[],
    monitoredProperties: Rule[],
    name: string,
    startdate: string | null,
    users: string[] | null,
    weekdays: number
  ) {
    return this.apollo.mutate({
      mutation: CREATE_ALERT_RULE,
      variables: {
        alerteventmaxcount: alerteventmaxcount,
        alerttimewindow: alerttimewindow,
        company: company,
        dailystarttime: dailystarttime,
        dailystoptime: dailystoptime,
        enddate: enddate,
        fullCoverage: fullCoverage,
        installations: installations,
        monitoredProperties: monitoredProperties,
        name: name,
        startdate: startdate,
        users: users,
        weekdays: weekdays
      }
    })
      .pipe(
        tap(() => this.getAlertRules(null))
      )
  }

  filterInstallation(referenceContains: string) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATION_BY_REFERENCE,
      variables: {
        company: this.company,
        referenceContains: referenceContains
      }
    })
  }

  updateAlertRule(id: string | null, name: string | null, installations: string[] | null) {
    return this.apollo.mutate({
      mutation: UPDATE_ALERT_RULE,
      variables: {
        id: id,
        name: name,
        installations: installations
      }
    })
  }

  activateAlertRule(id: string | null, name: string | null, installations: string[] | null) {
    return this.apollo.mutate({
      mutation: ACTIVATE_DEACTIVATE_ALERT_RULE,
      variables: {
        id: id,
        name: name,
        installations: installations,
        isActive: true
      }
    })
      .pipe(
        tap(() => this.getAlertRules(null))
      )
  }

  deactivateAlertRule(id: string | null, name: string | null, installations: string[] | null) {
    return this.apollo.mutate({
      mutation: ACTIVATE_DEACTIVATE_ALERT_RULE,
      variables: {
        id: id,
        name: name,
        installations: installations,
        isActive: false
      }
    })
      .pipe(
        tap(() => this.getAlertRules(null))
      )
  }

}

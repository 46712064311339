import { gql } from 'apollo-angular';

/** Query para filtrar divisão **/
const FILTER_DIVISIONS = gql`
    query Division(
        $company: ID
        $reference: String
        $pageSize: Int
        $cursor: String
        ) {
        division(
            company: $company
            referenceContains: $reference
            pageSize: $pageSize
            cursor: $cursor
        ){
            edges{
                node {
                    id
                    reference
                    company {
                        id
                        name
                    }
                }
            }
            count
            total
            pageInfo{
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }`;

/** Mutation para criar divisão **/
const CREATE_DIVISION = gql`
mutation DivisionCreate(
    $reference: String!
    $company: ID!
) {
    divisionCreate(input: {
        reference: $reference,
        company: $company
    }) {
        division{
            id
            reference
        }
    }
}`;

/** Mutation para atualizar/editar divisão **/
const UPDATE_DIVISION = gql`
mutation DivisionUpdate(
    $reference: String!
    $company: ID!
    $id: ID!
    ) {
        divisionUpdate(input: {
            id: $id,
            reference: $reference,
            company: $company
        }) {
            division{
                id
                reference
            }
        }
    }`;

/** Mutation para deletar divisão */
const DELETE_DIVISION = gql`
mutation divisionDelete($id: ID!) {
	divisionDelete(
        input: { 
            id: $id 
        }
    ) {
		message
	}
}`;

export {
    FILTER_DIVISIONS,
    CREATE_DIVISION,
    UPDATE_DIVISION,
    DELETE_DIVISION,
};

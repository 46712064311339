import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CREATE_LAMPS_TYPE, FILTER_LAMPS_TYPE, UPDATE_LAMPS_TYPE } from "src/app/graphql/lamps-type.graphql";


@Injectable({
    providedIn: 'root'
})

export class LampsTypeService {

    constructor(private apollo: Apollo) { }

    /** Requisição que realiza o filtro de tipos de luminárias **/
    filterLampsType(
        cursor: string | null,
        manufacturer: string | null,
        power: number | null,
        pageSize: number | null
    ) {
        return this.apollo.watchQuery({
            query: FILTER_LAMPS_TYPE,
            fetchPolicy: 'no-cache',
            variables: {
                manufacturer,
                power,
                pageSize,
                cursor,
            }
        })
    }

    /** Realiza a requisição de tipos de luminárias **/
    createLampsType(
        brightness: number | null,
        consumption: number | null,
        current: number | null,
        dimmingType: string,
        manufacturer: string | null,
        model: string,
        power: number | null,
        powerFactor: number | null,
        voltage: number | null
    ) {
        return this.apollo.mutate({
            mutation: CREATE_LAMPS_TYPE,
            variables: {
                brightness: brightness,
                consumption: consumption,
                current: current,
                dimmingType: dimmingType,
                manufacturer: manufacturer,
                model: model,
                power: power,
                powerFactor: powerFactor,
                voltage: voltage
            }
        })
    }
    /** Método que realiza a alteração de tipos de luminária no graphql */
    updateLampsType(
        id: string,
        brightness: number | null,
        consumption: number | null,
        current: number | null,
        dimmingType: string,
        manufacturer: string | null,
        model: string,
        power: number | null,
        powerFactor: number | null,
        voltage: number | null
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_LAMPS_TYPE,
            variables: {
                id: id,
                brightness: brightness,
                consumption: consumption,
                current: current,
                dimmingType: dimmingType,
                manufacturer: manufacturer,
                model: model,
                power: power,
                powerFactor: powerFactor,
                voltage: voltage
            }
        })
    }

}
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

//Arquivos do ngrx
import { Store } from '@ngrx/store';
import { LoginPageActions } from '../../../actions-index';
import { getError, getLoading } from '../../../state';
//Services
import { LoginService } from 'src/shared/services/login.service';
//Others
import { Observable, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  inputUser = new FormControl('', Validators.required);
  inputPassword = new FormControl('', Validators.required);

  loading$: Observable<boolean>;
  error$: Observable<string | null>;
  isShowingPassword: boolean = false;

  constructor(private store: Store, private loginService: LoginService) {
    this.error$ = store.select(getError);
    this.loading$ = store.select(getLoading);
  }

  ngOnInit(): void {
    this.store.dispatch(LoginPageActions.enter());
    this.loginService.showSideBar.emit(false);
    this.loginService.showSideBarUpdateUser.emit(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('tokenMap');
    localStorage.removeItem('expiredPassword');
    localStorage.removeItem('geoserverPassword');
    localStorage.removeItem('selectedInstallations');
  }

  async login() {

    this.store.dispatch(LoginPageActions.login(
      {
        login:
          { username: this.inputUser.value, password: this.inputPassword.value }
      }
    ));

  }

  showPassword() {
    this.isShowingPassword = !this.isShowingPassword;
  }
}





import { gql } from "apollo-angular";

export const CREATE_CIRCUIT_BOX = gql`
mutation circuitBoxCreate(
  $reference: String!,
  $sgIpc: ID!,
  $sgGateway: ID!,
  $sgConsumption: ID!
) {
    circuitBoxCreate(input:{
      reference: $reference
      sgIpc: $sgIpc
      sgGateway: $sgGateway
      sgConsumption: $sgConsumption
    }){
      circuitBox{
        id
        reference
        sgIpc{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
            commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        sgGateway{
          id
          reference
          site{
            id
            reference
            geoposition{
              latitude
              longitude
            }
            company{
              id
              name
            }
            country
            state
            city
            district
            street
            number
            details
            locationCode
          }
          device{
            id
            serialNumber
            macAddress
            company{
              id
              name
            }
            equipmentType{
              id
              reference
              major
              minor
              revision
              commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            }
          }
        }
        sgConsumption{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
            commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CIRCUIT_BOX = gql`
  query all($company: ID!, $reference: String, $first: Int, $cursor: String, $before: String, $last: Int){
    circuitBox(company: $company, reference:$reference, first: $first, after: $cursor, last: $last, before: $before) {
      edges {
        node {
          id
          reference
          sgIpc{id, serialNumber, macAddress}
          sgGateway{id, reference}
          sgConsumption{id, serialNumber}
        }
        cursor
      }
      total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
  }
`;

export const UPDATE_CIRCUIT_BOX = gql`
mutation circuitBoxUpdate(
  $id: ID!,
  $reference: String!,
  $sgIpc: ID!,
  $sgGateway: ID!,
  $sgConsumption: ID!
) {
  circuitBoxUpdate(input:{
      id: $id
      reference: $reference
      sgIpc: $sgIpc
      sgGateway: $sgGateway
      sgConsumption: $sgConsumption
    }){
      circuitBox{
        id
        reference
        sgIpc{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
            commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        sgGateway{
          id
          reference
          site{
            id
            reference
            geoposition{
              latitude
              longitude
            }
            company{
              id
              name
            }
            country
            state
            city
            district
            street
            number
            details
            locationCode
          }
          device{
            id
            serialNumber
            macAddress
            company{
              id
              name
            }
            equipmentType{
              id
              reference
              major
              minor
              revision
              commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            }
          }
        }
        sgConsumption{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
            commandSheet{
              id
              channelsCount
              schedulingSlotsCountPerChannel
              schedulingSlotsCount
              hasActuation
              hasClockSync
              hasPowerQuality
              queryCommands{
                edges{
                  node{
                    type
                    responseProperties
                  }
                }
              }
              parameters{
                edges{
                  node{
                    reference
                    maxValue
                    minValue
                    targetReference
                    type
                    choices{
                      edges{
                        node{
                          description
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const FILTER_EQUIPMENT_TYPE_CIRCUIT_BOX = gql`
  query eqtp($reference:String!){
    equipmentType(reference:$reference){
        edges{
            node{
                id
                reference
                major
                minor
            }
        }
      }
  }
`

export const FILTER_EQUIPMENT_CIRCUIT_BOX = gql`
query equipment($company: ID!, $serialNumber:String){
	equipment(
		company:$company
    serialNumber: $serialNumber
    ){
		edges{
			node{
				id
				serialNumber
        equipmentType{
          id
          reference
          minor
          major
          revision
        }
			}
		}
	}
}
`

import { gql } from "apollo-angular";

const FILTER_INSTALLATION_LOG = gql`
query InstallationLog(
	$company: ID!
	$operation: Operations
	$startDatetime: DateTime
	$endDatetime: DateTime
	$username: String
	$pageSize: Int
	$cursor: String
	$installationID: ID
) {
	installationLog(
		company: $company
		operation: $operation
		startDatetime: $startDatetime
		endDatetime: $endDatetime
		username: $username
		pageSize: $pageSize
		cursor: $cursor
		installationId: $installationID
	) {
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges {
			node {
				id
				installationId
				operation
				equipmentSerialNumber
				previousEquipmentSerialNumber
				reference
				siteReference
				gatewayReference
				username
				isActive
				lampModel
				logDatetime
				divisionReference
				company {
					name
				}
			}
		}
	}
}`;

export {
	FILTER_INSTALLATION_LOG
}

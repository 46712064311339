import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_INSTALLATION, DELETE_INSTALLATION, FILTER_INSTALLATIONS, GET_DIVISION, GET_EQUIPMENTS, GET_GATEWAY_INSTALLATION, GET_INSTALLATION_BY_REFERENCE, GET_INSTALLATION_SITE, GET_LAMPS_TYPE, STATUS_INSTALLATION, UPDATE_INSTALLATION } from 'src/app/graphql/installationQueries.graphql';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {

  constructor(private apollo: Apollo) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Armazena ID da empresa selecionada **/
  company: string | null;

  /** Algumas páginas usam essa consulta aqui. **/
  getInstallationByReference(referenceContains: string | null, cursor: string | null = null,
    pageSize: number | null = null) {
    return this.apollo.watchQuery({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains: referenceContains,
        pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  /** Chama a requisição do filtro de instalações **/
  filterInstallations(
    referenceContains: string | null,
    devices: string | null = null, // Tipo de equipamento -> serialnumber
    country: string | null = null,
    state: string | null = null,
    city: string | null = null,
    district: string | null = null,
    street: string | null = null,
    cursor: string | null = null,
    pageSize: number | null = null
  ) {
    return this.apollo.watchQuery({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        referenceContains,
        devices,
        country,
        state,
        city,
        district,
        street,
        pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  /** Chama a requisição de criação de instalações **/
  createInstallation(
    reference: string | null,
    site: string | null,
    equipment: string | null,
    gateway: string | null,
    lampType: string | null,
    hasMagneticKey: boolean,
    applications: ['PUBLIC_LIGHTING', 'ELECTRICITY_CONSUMPTION', 'WATER_CONSUMPTION', 'GAS_CONSUMPTION'],
    division: string | null,
  ) {
    return this.apollo.mutate({
      mutation: CREATE_INSTALLATION,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        reference,
        site,
        equipment,
        gateway,
        lampType,
        hasMagneticKey,
        applications,
        division,
        channel: 0
      }
    })
  }

  /**  Chama a requisição de edição de instalações **/
  updateInstallation(
    id: string | null,
    reference: string | null,
    site: string | null,
    deviceId: string,
    gateway: string,
    applications: ['light', 'electricity', 'water', 'gas'] | null,
    isActive: boolean,
    hasMagneticKey: boolean,
    lampType: string | null,
    division: string | null
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_INSTALLATION,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        reference,
        site,
        deviceId,
        gateway,
        applications,
        isActive,
        hasMagneticKey,
        lampType,
        division,
        channel: 0
      }
    })
  }

  /** Chama a requisição de exclusão de instalações **/
  deleteInstallation(id: string | null,) {
    return this.apollo.mutate({
      mutation: DELETE_INSTALLATION,
      fetchPolicy: 'network-only',
      variables: {
        id,
        company: this.company,
      }
    })
  }

  /** Chama a requisição de alteração do status inactive/active da instalação **/
  statusInstallations(
    id: string | null,
    reference: string | null,
    isActive: boolean,
    site: string | null,
    applications: ['light', 'electricity', 'water', 'gas'] | null,
    gateway: string
  ) {
    return this.apollo.mutate({
      mutation: STATUS_INSTALLATION,
      fetchPolicy: 'network-only',
      variables: {
        id,
        reference,
        channel: 0,
        isActive,
        site,
        applications,
        gateway
      }
    })
  }

  /**Chama a requisição de filtro de equipamentos **/
  getEquipments(
    serialNumber: string | null,
  ) {
    return this.apollo.watchQuery({
      query: GET_EQUIPMENTS,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        serialNumber,
        cursor: null,
        pageSize: 20,
        sort_dir: "ASC",
        sort_field: "MAC_ADDRESS"
      }
    })
  }

  /** Chama a requisição do filtro de gateways da instalação **/
  getGateways(reference: string | null) {
    return this.apollo.watchQuery({
      query: GET_GATEWAY_INSTALLATION,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        reference
      }
    })
  }

  /** Chama a requisição do filtro de tipos de luminároas **/
  getLampsType() {
    return this.apollo.watchQuery({
      query: GET_LAMPS_TYPE,
      fetchPolicy: 'no-cache',
      variables: {
        cursor: null,
        pageSize: 500
      }
    })
  }

  /** Chama a requisição do filtro de local de instalação **/
  getInstallationsSite(
    reference: string | null,
  ) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATION_SITE,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        reference
      }
    })
  }

  /** Chama a requisição do filtro de divisão (subprefeituras) **/
  getDivision() {
    return this.apollo.watchQuery({
      query: GET_DIVISION,
      variables: {
        company: this.company
      }
    })
  }
}

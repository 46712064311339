import { gql } from "apollo-angular";

const FILTER_INSTALLATION_SITE_LOG = gql`
query InstallationSiteLog(
  $company: ID!,
  $operation: Operations,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $reference: String,
  $username: String,
  $pageSize: Int,
  $cursor: String
  ){
installationSiteLog(
	company: $company,
	operation: $operation,
	startDatetime: $startDatetime,
	endDatetime: $endDatetime,
	reference: $reference,
	username: $username,
  pageSize: $pageSize,
  cursor: $cursor
		) {
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges{
			node{
				geoposition{
					latitude
					longitude
				}
				operation
				reference
				username
				isActive
				logDatetime
				company{
					name
				}
			}
		}
	}
}`;

export {
    FILTER_INSTALLATION_SITE_LOG
}

<div class="component-main-container">
    <app-page-header parentTitle="{{'report-audit.report-audit-manage.parentTitle' |
    translate}}" pageTitle="{{'report-audit.report-audit-manage.pageTitle' |
    translate}}"></app-page-header>
    <div *ngIf="!editingAudit" class="comp-body">
        <div class="row">
            <div class="col-lg-5 col-xl-4 col-sm-12">
                <div *ngIf="!seeingAuditReportDownloads" class="component-forms-box">
                    <div class="form-fields-box">
                        <h2 class="header-link">{{'report-audit.report-audit-manage.generate-report' | translate}}</h2>
                        <hr>
                        <!-- Passo de preencher referencia -->
                        <div class="form-step">
                            <div class="circle-number"> 1
                            </div>
                            <mat-form-field class="example-form-field" appearance="outline">
                                <mat-label>{{'report-audit.report-audit-manage.report-name-label' |
                                    translate}}</mat-label>
                                <input [formControl]="auditReferenceForm" matInput type="text">
                            </mat-form-field>
                        </div>
                        <p *ngIf="referenceAlreadyExists" class="text-danger">
                            {{'report-audit.report-audit-manage.report-duplicate-name-danger-text' | translate}}</p>
                        <p *ngIf="referenceContainSpecialCharacters" class="text-danger">
                            {{'report-audit.report-audit-manage.report-special-char-danger-text' | translate}}</p>
                        <button [disabled]="!auditReferenceForm.valid" (click)="createAudit()"
                            class="btn btn-success mb-2 w-100">{{'report-audit.report-audit-manage.create-report-audit-button'
                            | translate}}</button>
                    </div>

                    <div class="form-fields-box">
                        <button (click)="toggleDownloadManage()" class="btn btn-success">
                            {{'report-audit.report-audit-manage.icvi-icti-button' | translate}}
                        </button>
                    </div>
                </div>

                <div *ngIf="seeingAuditReportDownloads" class="component-forms-box">
                    <div class="form-fields-box">
                        <p class="hint">{{'report-audit.report-audit-manage.icvi-icti-hint-message' | translate}}</p>
                        <button (click)="toggleDownloadManage()" class="btn btn-success">
                            {{'report-audit.report-audit-manage.report-audit-table-button' | translate}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-xl-8 col-sm-12">
                <!-- Table box -->
                <div *ngIf="!seeingAuditReportDownloads" class="component-table-box" #datatable>

                    <div *ngIf="tableIsLoading; else auditListTable" class="component-spinner-box">
                        <mat-spinner class="mt-4" [diameter]="80"></mat-spinner>
                    </div>

                    <ng-template #auditListTable>
                        <table mat-table [dataSource]="dataSourceAudit" class="mat-elevation-z8">

                            <ng-container matColumnDef="reference">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.name-header' | translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false">
                                    <h3><b>{{element.reference}}</b></h3>
                                    <p>{{'report-audit.report-audit-manage.table-labels.equipments-header' | translate}}
                                        {{element.installationTotal}}</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="data">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.data-header' | translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false">
                                    <div class="row">

                                        <div class="col-md-4 d-flex flex-column p-0">
                                            <span><b>ICTI: </b>{{element.data.icti | number : '1.1-2'}}</span>
                                            <span><b>ICVI: </b>{{element.data.icvi | number : '1.1-2'}}</span>
                                            <span><b>IDT: </b>{{element.data.idt | number : '1.1-2'}}</span>
                                            <span><b>SDOT: </b>{{element.data.sdot | number : '1.1-2'}}</span>
                                        </div>
                                        <div class="col-md-4 d-flex flex-column">
                                            <span><b>OPDIU: </b>{{element.data.opdiu | number : '1.1-2'}}</span>
                                            <span><b>OPNOT: </b>{{element.data.opnot | number : '1.1-2'}}</span>
                                            <span><b>SDFT: </b>{{element.data.sdft | number : '1.1-2'}}</span>
                                            <span><b>MPREV: </b>{{ element.data.mprev | number :'1.1-2'}}</span>

                                        </div>

                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="accountable">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.author-header' | translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false"> {{element.accountable}} </td>
                            </ng-container>

                            <ng-container matColumnDef="creationDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.report-creation-date-header' |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false"> {{element.creationDate}} </td>
                            </ng-container>


                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.report-start-date-header' |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false"> {{element.startDate}} </td>
                            </ng-container>


                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.report-end-date-header' |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false"> {{element.endDate}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.report-status-header' | translate}}
                                </th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false"> {{element.status}} </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'report-audit.report-audit-manage.table-labels.report-actions-header' |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="m-2"><a>
                                            <mat-icon routerLink="/audit/auditEdit/{{element.id}}">visibility</mat-icon>
                                        </a></span>
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>Ações</th>
                                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                                    on-mouseleave="element.view = false">

                                    <button routerLink="/audit/auditEdit/{{element.id}}"
                                        [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                            <path
                                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                        </svg>
                                    </button>
                                    <button *ngIf="!element.isOpen" (click)="getAuditorshipInstallations(element.id)"
                                        [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsAudit"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsAudit;"></tr>
                        </table>
                        <mat-paginator [length]="auditCount" [pageSize]="10"
                            [pageSizeOptions]="[10, 14, 20, 25, 30, 40, 100]"
                            aria-label=report-audit.report-audit-manage.table-labels.report-actions-header
                            class="mat-paginator-sticky" showFirstLastButtons>
                            >
                        </mat-paginator>
                    </ng-template>
                </div>

                <!-- Table dos downloads dos relatórios -->
                <div *ngIf="seeingAuditReportDownloads" class="component-table-box">
                    <app-report-audit-download></app-report-audit-download>
                </div>

            </div>
        </div>
    </div>
</div>
import { gql } from "apollo-angular";

const FILTER_COMPANIES = gql`
query Company(
    $first: Int,
    $cursor: String,
    $name: String,
    $before: String,
    $last: Int,
    $sort_dir: Direction!,
    $sort_field: CompanyOrdenationFields!
    ) {
    company(
        first: $first,
        after: $cursor,
        last: $last,
        before: $before,
        name_Icontains: $name,
        orderBy:{
            direction: $sort_dir,
            field: $sort_field
          }
        ) {
        edges {
            node {
                id
                name
                cnpj
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

const CREATE_COMPANIES = gql`mutation
companyCreate($name: String!, $cnpj: String!) {
    companyCreate(
        input: {
            name: $name,
            cnpj: $cnpj
        }) {
        company {
            id
            name
            cnpj
        }
    }
}`

const UPDATE_COMPANY = gql`mutation
companyUpdate($id: ID!, $name: String!, $cnpj: String!) {
  companyUpdate(
      input: {
          id: $id,
          name: $name,
          cnpj: $cnpj
      }) {
      company {
          id
          name
          cnpj
      }
  }
}`

export {
    FILTER_COMPANIES, CREATE_COMPANIES, UPDATE_COMPANY
}

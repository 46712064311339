import { gql } from "apollo-angular";

/** Filtro de Firmwares **/
const FILTER_FIRMWARES = gql`
query firmwares(
    $major: Int, 
    $minor: Int, 
    $name: String, 
    $revision: Int,
	$pageSize: Int,
	$cursor: String
    ) {
	firmware(
        major: $major, 
        minor: $minor, 
        name: $name, 
        revision: $revision,
		pageSize: $pageSize,
		cursor: $cursor
		) {
		edges {
			node {
				id
				name
				major
				minor
				revision
				description
				releaseDate
			}
		}
		count
		total
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

/** Criar Firmwares **/
const CREATE_FIRMWARES = gql`
mutation
	FirmwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date,
		$compatibleHardwares:[ID]) {
		firmwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
				compatibleHardwares: $compatibleHardwares
			}) {
			firmware {
				id
			}
		}
	}`;

/** Lista todos os Hardwares  **/
const GET_ALL_HARDWARES = gql`
query
	Hardware {
		hardware (
			orderBy:{
				direction: ASC,
				field: NAME
				}
		){
		edges {
			node {
				id
				name
				minor
				major
				revision
				description
				releaseDate
			}
		}
	}
}`;

/** Criar hardwares compatíveis */
const CREATE_NEW_COMPATIBLE_HARDWARE = gql`
mutation
	HardwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		hardwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			hardware {
				id
			}
		}
	}`;

export {
	FILTER_FIRMWARES,
	CREATE_FIRMWARES,
	GET_ALL_HARDWARES,
	CREATE_NEW_COMPATIBLE_HARDWARE
}
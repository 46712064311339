import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { ALERT, DETAIL_CONSUPTION, GET_ALL_DIVISION, GET_ALL_GATEWAY } from 'src/app/graphql/map.graphql';
import { environment } from 'src/environments/environment';
import { CHANGE_FAVORITE } from 'src/app/graphql/favorites.graphql';
import { GatewayManager } from '../components/map/models/mapModel';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { decrypt } from 'src/util/cryptography.util';

@Injectable({
  providedIn: 'root'
})
export class MapjsService {

  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) { }


  // Variável utilizada para realizar o unsubscribe da subscrição do webSocket
  public websocketSubject: WebSocketSubject<any> | any;

  // Endereço do geoserver, é alterado de acordo com o ambiente
  private geoServerUrl: string = environment.geoServerUrl;
  private geoRedisConnectorUrl: string = environment.geoRedisConnectorUrl;

  // Propriedade que cuida do Gateway Socket
  private gatewaySubject = new BehaviorSubject<GatewayManager>(new GatewayManager);
  private gatewayManage$ = this.gatewaySubject.asObservable();

  /** Subject utilizado para manipular o estado da exibição dos logs,
   * onde será passado o valor que foi atribuido no componente de mapa para o componente de logs **/
  private logsSubject = new BehaviorSubject<boolean>(false);

  /** Observable responsável por ouvir e compartilhar o estado da propriedade subject para o componente de logs **/
  public logsSubject$ = this.logsSubject.asObservable();

  /** Método que altera o estado da propriedade para true (ou seja, momento em que os logs deverão ser exibidos) **/
  public setLogsTrue() {
    this.logsSubject.next(true);
  }

  /** Método que altera o estado da propriedade para false (ou seja, momento em que os logs não deverão ser exibidos) **/
  public setLogsFalse() {
    this.logsSubject.next(false);
  }

  /**Headers responsaveis pela autenticação do endereço do geoserver*/
  public username = localStorage.getItem('username');
  public password = decrypt(localStorage.getItem("geoserverPassword")!, localStorage.getItem("token")!);

  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Authorization': 'Basic ' + btoa(`${this.username}:${this.password}`)
  });

  // Função responsável por trazer todas as divisões da empresa selecionada
  public getAllDivisions(company: string): any {
    return this.apollo.watchQuery<any>({
      query: GET_ALL_DIVISION,
      fetchPolicy: "network-only",
      variables: {
        company,
        pageSize: 1000
      }
    })
  }

  // Função responsável por trazer todos os alertas da instalação selecionada(Aparece na sidebar de mais informações)
  public getAlert(
    company: string,
    installation: any,
  ): any {
    return this.apollo.watchQuery<any>({
      query: ALERT,
      fetchPolicy: "network-only",
      variables: {
        company,
        installation,
        first: 50,
        sort_dir: "DESC",
        sort_field: "ALERT_DATETIME"
      }
    })
  }

  /*  Função responsável por trazer mais informações
   sobre o a instalação selecionada assim como o consumo detalhado */
  public getDetailConsuption(
    id: string
  ): any {
    return this.apollo.watchQuery<any>({
      query: DETAIL_CONSUPTION,
      fetchPolicy: "network-only",
      variables: {
        id
      }
    })
  }

  public changeFavorite(): any {
    return this.apollo.mutate<any>({
      mutation: CHANGE_FAVORITE,
      fetchPolicy: "network-only",
      variables: {
        json: "{}",
        preferencesGroup: "{}"
      }
    })
  }

  // Filtro das instalações, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public filterByElements(filter: string, companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=map_nodes&maxFeatures=150000&outputFormat=application%2Fjson&CQL_FILTER=${filter}`;

    return this.http.get(url, { headers: this.headers });

  }
  // Filtro das roles, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public isCompanyInRoles() {
    let url = `${this.geoServerUrl}geoserver/rest/security/roles/user/${localStorage.getItem("username")}/`;
    return this.http.get(url, { headers: this.headers, responseType: 'text' });
  }


  // Filtra instalações pelo poligono selecionado no mapa
  public filterByPolygons(filter: string, companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=map_nodes&maxFeatures=150000&outputFormat=application%2Fjson&CQL_FILTER=${filter}`;

    return this.http.get(url, { headers: this.headers });

  }

  // Filtra  todas os concentradores da empresa selecionada atraves do geoserver
  public getGateways(companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=gateways&maxFeatures=50000&outputFormat=application%2Fjson&CQL_FILTER=is_active=true`;

    return this.http.get(url, { headers: this.headers });
  }

  // Filtro das instalações, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public getCircuitBox(companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=circuitbox&maxFeatures=50000&outputFormat=application%2Fjson`;
    return this.http.get(url, { headers: this.headers });
  }

  public getStatusAndLastTransmissions(macs: any, filter: any) {

    let url = `${this.geoRedisConnectorUrl}last_transmissions${filter}`;
    // Captura o token do cliente.
    const token = localStorage.getItem('token');

    // Transforma o objeto formData em uma string no formato application/x-www-form-urlencoded
    const body = new URLSearchParams(macs).toString();

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,  // Insere o token do client no header da requisição.
    });

    return this.http.post(url, body, { headers });

  }

  public getStatusAndLastTransmission(mac: any) {

    let url = `http://127.0.0.1:3081/last_transmission?mac=${mac}`;

    return this.http.get(url);

  }

  public watchGatewaysRequest(serialNumbers: Array<string>, token: string | null): void {
    let _url = `${environment.gatewayWebSocketUrl}gateways/?token=Bearer ${token}&serial_numbers=${serialNumbers.join(',')}`;
    this.websocketSubject = webSocket(_url);

    this.websocketSubject.subscribe({
      next: (data: any): void => {
        let gateway: GatewayManager = new GatewayManager(
          data.data?.serial_number,
          data.data?.received_datetime,
          data.data?.rtc_datetime,
          data.data?.last_reboot,
          data.data?.reboots,
          data.data?.uptime,
          data.data?.transmission_datetime,
          data.data?.cpu_percent,
          data.data?.cpu_count,
          data.data?.ram_total,
          data.data?.ram_used,
          data.data?.disk_total,
          data.data?.disk_used,
          data.data?.disk_free,
          data.data?.disk_percent,
          data.data?.temperature_current,
          data.data?.temperature_high,
          data.data?.ovpn_alive,  // Indica se o gateway está ou não ativo na rede.
          data.data?.devices,
          data.data?.ovpn_datetime
        );
        this.gatewaySubject.next(gateway);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        console.log('Close websocket connection');
      }
    })
  }

  public watchGateway(): Observable<GatewayManager> {
    return this.gatewayManage$;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';

import { StoreModule } from '@ngrx/store';
import { SharedStateLoginModule } from './state';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

//Modulos do Angular Material importar aqui
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { UsereditComponent } from './components/userprofile/useredit/pages/useredit.component';
import { ReportComponent } from './components/report/pages/report.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';


// Biblioteca de mask
import { NgxMaskModule } from 'ngx-mask';

import { ReportChartsComponent } from './components/report-charts/pages/report-charts.component';
import { ManageUserComponent } from './components/manage-user/pages/manage-user.component';
import { ReportAuditComponent } from './components/report-audit/pages/report-audit.component';
import { ReportAuditDownloadComponent } from './components/report-audit-download/pages/report-audit-download.component';
import { AuditEditComponent } from './components/report-audit/audit-edit/pages/audit-edit.component';
import { ReportAuditManageComponent } from './components/report-audit/report-audit-manage/pages/report-audit-manage.component';
import { CommandsModalComponent } from './components/commands-modal/commands-modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CompaniesComponent } from './components/register/pages/companies/companies.component';
import { UnitMeasurementComponent } from './components/register/pages/unit-measurement/unit-measurement.component';
import { PropertiesComponent } from './components/register/pages/properties/properties.component';
import { HardwaresComponent } from './components/register/pages/hardwares/hardwares.component';
import { FirmwaresComponent } from './components/register/pages/firmwares/firmwares.component';
import { EquipmentsComponent } from './components/register/pages/equipments/equipments.component';
import { EquipmentTypeComponent } from './components/register/pages/equipment-type/equipment-type.component';
import { InstallationSiteComponent } from './components/register/pages/installation-site/installation-site.component';
import { InstallationsComponent } from './components/register/pages/installations/installations.component';
import { ParametersComponent } from './components/register/pages/parameters/parameters.component';
import { RouterModule } from '@angular/router';
import { AlertRulesComponent } from './components/alert-rules/pages/alert-rules.component';
import { AlertRulePipePipe } from './pipes/alert-rule-pipe.pipe';
import { SidebarComponent } from './components/sidebar/pages/sidebar.component';
import { UserprofileComponent } from './components/userprofile/userprofile/pages/userprofile.component';
import { UserComponent } from './components/userprofile/user/pages/user.component';
import { MapSidebarFilterComponent } from './components/map-sidebar-filter/map-sidebar-filter.component';
import { CommandsComponent } from './components/commands/commands.component';
import { InstallationsModalComponent } from './components/installations-modal/installations-modal.component';
import { ServiceOrderComponent } from './components/service-order/service-order.component';
import { Unsubscriber } from './components/unsubscriber/unsubscriber.component';
import { ManageAlertRuleComponent } from './components/alert-rules/pages/manage-alert-rules/manage-alert-rule/manage-alert-rule.component';
import { ConfirmationCallComponent } from './components/confirmation-call/confirmation-call.component';
import { BrowserModule } from '@angular/platform-browser';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ComponentsComponent } from './components/register/pages/components/components.component';
import { CircleNumberSubstepComponent } from './helper-components/circle-number-substep/circle-number-substep.component';
import { QueryCommandsModalComponent } from './components/register/pages/equipment-type/equipment-type-modal/equipment-type-modal.component';
import { CustomTableComponent } from './helper-components/custom-table/custom-table.component';
import { AlertsComponent } from './components/alerts/pages/alerts.component';
import { CustomDatePipe } from './pipes/customDate.pipe';
import { AlertEditComponent } from './components/alerts/pages/alert-edit/alert-edit.component';
import { MetersComponent } from './components/register/pages/meters/meters.component';
import { LampsTypeComponent } from './components/register/pages/lamps-type/lamps-type.component';
import { ComponentsModalComponent } from './components/register/pages/components/components.modal/components.modal.component';
import { TableHeaderModule } from './helper-components/table-header/table-header.module';
import { RegisterFormModule } from './components/register/pages/register-form/register-form.module';
import { MapComponent } from './components/map/pages/map.component';
import { DivisionComponent } from './components/register/pages/division/division.component';
import { GatewaysComponent } from './components/register/pages/gateways/gateways.component';
import { LogsComponent } from './components/logs/logs.component';
import { LogsFormComponent } from './components/logs/pages/logs-form/logs-form.component';
import { InstallationLogComponent } from './components/logs/pages/installation-log/installation-log.component';
import { InstallationSiteLogComponent } from './components/logs/pages/installation-site-log/installation-site-log.component';
import { EquipmentLogComponent } from './components/logs/pages/equipment-log/equipment-log.component';
import { UserLogComponent } from './components/logs/pages/user-log/user-log.component';
import { PainelFavComponent } from './components/painel-fav/painel-fav.component';
import { RegisterComponent } from './components/register/register.component';
import { GraphQlErrorLib } from 'src/shared/pipes/graphql-error-lib.pipe';
import { LoginComponent } from './components/login/pages/login.component';
import { HelperComponent } from './helper-components/helper/helper.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { HelperModalComponent } from './helper-components/helper-modal/helper-modal.component';
import { FavoriteReducer } from './components/map/ngrx/map.reducer';
import { CircuitBoxComponent } from './components/register/pages/circuit-box/circuit-box.component';
import { UserchangepasswordComponent } from './components/manage-user/userchangepassword/userchangepassword.component';
import { ImportsCSVComponent } from './components/imports-csv/imports-csv.component';
import { ImportsCsvFormComponent } from './components/imports-csv/pages/imports-csv-form/imports-csv-form.component';
import { ImportsCsvInstallationsComponent } from './components/imports-csv/pages/imports-installations/imports-csv-equipment-installation/imports-csv-equipment-in-installations.component';
import { ImportsCsvUpdateInstallationsComponent } from './components/imports-csv/pages/imports-installations/imports-csv-update-installations/imports-csv-update-installations/imports-csv-update-installations.component';
import { ImportsCsvCreateEquipmentsComponent } from './components/imports-csv/pages/imports-equipments/imports-csv-create-equipments/imports-csv-create-equipments/imports-csv-create-equipments.component';
import { ImportsCsvCreateInstallationsComponent } from './components/imports-csv/pages/imports-installations/imports-csv-create-installations/imports-csv-create-installations/imports-csv-create-installations.component';
import { PermissionsGroupComponent } from './components/permissions-group/permissions-group.component';
import { ImportsCsvUpdateEquipmentComponent } from './components/imports-csv/pages/imports-equipments/imports-csv-update-equipments/imports-csv-update-equipment/imports-csv-update-equipment.component';
import { ConfigCommandsModalComponent } from './components/register/pages/equipment-type/equipment-type-modal-config-commands/config-commands-modal/config-commands-modal.component';
import { ImportsCsvInactivateInstallationsComponent } from './components/imports-csv/pages/imports-installations/imports-csv-inactivate-installations/imports-csv-inactivate-installations/imports-csv-inactivate-installations.component';
@NgModule({
  declarations: [
    HomeComponent,
    SidebarComponent,
    UserprofileComponent,
    UserComponent,
    UsereditComponent,
    ManageUserComponent,
    ReportComponent,
    MapSidebarFilterComponent,
    MapComponent,
    CommandsComponent,
    ReportChartsComponent,
    ReportAuditComponent,
    ReportAuditDownloadComponent,
    AuditEditComponent,
    ReportAuditManageComponent,
    InstallationsModalComponent,
    ServiceOrderComponent,
    CommandsModalComponent,
    NotFoundComponent,
    LogsComponent,
    LogsFormComponent,
    InstallationLogComponent,
    InstallationSiteLogComponent,
    EquipmentLogComponent,
    UserLogComponent,
    CompaniesComponent,
    UnitMeasurementComponent,
    PropertiesComponent,
    HardwaresComponent,
    FirmwaresComponent,
    EquipmentsComponent,
    EquipmentTypeComponent,
    InstallationSiteComponent,
    InstallationsComponent,
    ParametersComponent,
    AlertRulesComponent,
    AlertRulePipePipe,
    CustomDatePipe,
    Unsubscriber,
    ManageAlertRuleComponent,
    ConfirmationCallComponent,
    ComponentsComponent,
    CircleNumberSubstepComponent,
    QueryCommandsModalComponent,
    CustomTableComponent,
    AlertsComponent,
    AlertEditComponent,
    MetersComponent,
    LampsTypeComponent,
    ComponentsModalComponent,
    DivisionComponent,
    GatewaysComponent,
    RegisterComponent,
    PainelFavComponent,
    LoginComponent,
    GraphQlErrorLib,
    HelperComponent,
    PageHeaderComponent,
    HelperModalComponent,
    CircuitBoxComponent,
    UserchangepasswordComponent,
    ImportsCSVComponent,
    ImportsCsvInstallationsComponent,
    ImportsCsvFormComponent,
    ImportsCsvUpdateInstallationsComponent,
    ImportsCsvCreateEquipmentsComponent,
    ImportsCsvInstallationsComponent,
    ImportsCsvCreateInstallationsComponent,
    PermissionsGroupComponent,
    ImportsCsvUpdateEquipmentComponent,
    ConfigCommandsModalComponent,
    ImportsCsvInstallationsComponent,
    ImportsCsvInactivateInstallationsComponent
  ],
  imports: [
    TableHeaderModule,
    RegisterFormModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatChipsModule,
    MatDividerModule,
    MatDatepickerModule,
    MatCardModule,
    MatButtonModule,
    MatPaginatorModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatExpansionModule,
    CdkAccordionModule,
    MatTooltipModule,
    MatButtonModule,
    MatStepperModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    SharedStateLoginModule,
    StoreModule.forRoot({ favorite: FavoriteReducer }),
    BrowserModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    SidebarComponent,
    UserprofileComponent,
    UsereditComponent,
    CommandsComponent,
    PainelFavComponent,
    UserchangepasswordComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-br' }],
})
export class SharedModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

import { gql } from "apollo-angular";

/** Filtrar todos os medidores da tabela (filtro inicial) **/
const GET_ALL_METERS_TABLE = gql`
query Material(
    $company: ID!,
    $pageSize: Int,
    $cursor: String
    $search: String,
    $materialTypes: [ID],
    $sort_dir: Direction!,
    $sort_field: MaterialOrderFields!
    ) {
    material(
        company: $company,
        pageSize: $pageSize,
        cursor: $cursor,
        search: $search,
        materialTypes: $materialTypes,
        isMeter: true,
        orderBy:{
            direction: $sort_dir,
            field: $sort_field
            }
        ) {
        edges {
            node {
                id
                serialNumber
                type {
                    id
                    reference
                }
                installation {
                    reference
                }
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

/** Filtrar Medidores **/
const FILTER_METERS = gql`
query Material(
    $company: ID!,
    $pageSize: Int,
    $cursor: String
    $search: String,
    $materialTypes: [ID],
    $sort_dir: Direction!,
    $sort_field: MaterialOrderFields!
    ) {
    material(
        company: $company,
        pageSize: $pageSize,
        cursor: $cursor,
        search: $search,
        materialTypes: $materialTypes,
        isMeter: true,
        orderBy:{
            direction: $sort_dir,
            field: $sort_field
            }
        ) {
        edges {
            node {
                id
                serialNumber
                type {
                    id
                    reference
                }
                installation {
                    reference
                }
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}
`;

/** Criar Medidores **/
const CREATE_METERS = gql`
mutation
	MaterialCreate(
		$installationId: ID!,
		$company: ID!,
		$serialNumber: String!,
		$materialType:ID!) {
		materialCreate(
			input: {
				installationId: $installationId,
				companyId: $company,
				serialNumber: $serialNumber
				typeId: $materialType
			}) {
			material {
				id
			}
		}
}`;

/** Filtrar os tipos de medidores */
const GET_METERS_TYPE = gql`
query MaterialType(
    $pageSize: Int,
    $cursor: String,
    $company: ID!,
    $manufacturer: String,
    $model: String,
    $sort_dir: Direction!,
    $sort_field: MaterialTypeOrderFields!
    ) {
    materialType(
        pageSize: $pageSize,
        cursor: $cursor,
        company: $company,
        manufacturer: $manufacturer,
        model: $model,
        isMeter: true,
        orderBy:{
            direction: $sort_dir,
            field: $sort_field
            }
        ) {
        edges {
            node {
                id
                reference
                description
                manufacturer
                model
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

/** Filtrar instalações **/
const GET_INSTALLATION = gql`
query Installation(
    $company: ID!
    $reference: String
    $sort_dir: Direction!
    $sort_field: InstallationOrderFields!
    ) {
    installation(
        company: $company
        referenceContains: $reference
        orderBy: {
            direction: $sort_dir
            field: $sort_field
            }
        ) {
        count
        total
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                id
                reference				
            }
            cursor
        }
    }
}`;

export {
    GET_ALL_METERS_TABLE,
    FILTER_METERS,
    CREATE_METERS,
    GET_METERS_TYPE,
    GET_INSTALLATION
} 

export class Installation {
    constructor(
        public id: string, //id da instalaçao
        public reference: string | null, // referencia
        public site: Site, //local de instalação
        public device: Device, // device que vai referenciar o tipo de equipamento
        public isActive: boolean, // status
        public hasMagneticKey: boolean, // chave magnética
        public applications: string,
        public lampType: LampsType,
        public gateway: Gateway,
        public division: Division
    ) { }
}

export class Device {
    constructor(
        public id: string | null,
        public serialNumber: string | null
    ) { }
}

export class Site {
    constructor(
        public id: string | null,
        public reference: string | null
    ) { }
}

export class LampsType {
    constructor(
        public id: string,
        public model: string
    ) { }
}

export class Gateway {
    constructor(
        public id: string,
        public reference: string

    ) { }
}
export class Division {
    constructor(
        public id: string | null,
        public reference: string | null
    ) { }
}

export class EquipmentType {
    constructor(
        public id: string | null,
        public reference: string | null,
        public major: string | null,
        public minor: string | null,
        public revision: string | null,
    ) { }
}

export class Equipment {
    constructor(
        public id: string | null,
        public macAddress: string | null,
        public serialNumber: string | null,
        public equipmentType: EquipmentType
    ) { }
}
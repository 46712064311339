import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AlertRules, ALertsTable, ServiceOrder, PaginationInfo, AlertFilter, ServiceOrderInformation } from '../components/alerts/models/alerts.model';
import { ErrorLibService } from './error-lib.service';
import { BehaviorSubject, catchError, map, switchMap, take, tap, Subject, takeUntil } from 'rxjs';
import { ADD_SERVICE_ORDER_TO_ALERT, CHANGE_ALERT_STATUS, GET_ALERT_BY_ID, GET_ALERT_RULES, GET_ALL_ALERTS, GET_CSV_DOWNLOAD_LINK, CREATE_SERVICE_ORDER, GET_SERVICE_ORDERS, GET_SERVICE_ORDER_INFO } from 'src/app/graphql/alerts.graphql';
import { Installation } from '../components/alert-rules/models/installation.model';
import { GET_INSTALLATION_BY_REFERENCE } from 'src/app/graphql/alert-rules.graphql';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  company: string | null = '';
  constructor(private apollo: Apollo, private errorLib: ErrorLibService) {
    this.company = localStorage.getItem('lastCompanySelected');
    this.getAlertRules();
  }

  //Para pegar os dados da tabela é necessário criar uma variável behaviorSubject e uma variável observable
  //BehaviorSubject é um tipo de observable que permite que você emita um valor inicial antes que qualquer outro observador se inscreva nele.
  //Observable é um tipo de dado que permite que você receba notificações quando um valor é emitido.
  alertsBehaviorSubject = new BehaviorSubject<ALertsTable[]>([]);
  alerts$ = this.alertsBehaviorSubject.asObservable();
  //Para controlar o processor de loading dos dados da tabela usasse uma variável behaviorSubject e uma variável observable da mesma forma
  loadingAlertsBehaviorSubject = new BehaviorSubject<boolean>(false);
  loadingAlerts$ = this.loadingAlertsBehaviorSubject.asObservable()

  loadingAlertDataBehaviorSubject = new BehaviorSubject<boolean>(false);
  loadingAlertData$ = this.loadingAlertDataBehaviorSubject.asObservable()

  serviceOrdersBehaviorSubject = new BehaviorSubject<ServiceOrder[]>([]);
  serviceOrders$ = this.serviceOrdersBehaviorSubject.asObservable();

  serviceOrdersLoadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  serviceOrdersLoading$ = this.serviceOrdersLoadingBehaviorSubject.asObservable();

  loadingAlertRulesBehaviorSubject = new BehaviorSubject<boolean>(false);
  loadingAlertRules$ = this.loadingAlertRulesBehaviorSubject.asObservable()

  alertsRulesBehaviorSubject = new BehaviorSubject<AlertRules[]>([]);
  alertRules$ = this.alertsRulesBehaviorSubject.asObservable();

  private installationsSubject = new BehaviorSubject<Installation[]>([]);
  searchedInstallations$ = this.installationsSubject.asObservable();

  loadingInstallationsSubject = new BehaviorSubject<boolean>(false);
  loadingInstallations$ = this.loadingInstallationsSubject.asObservable()

  private cancelTokenSource = new Subject<void>();

  //Para controlar a paginação da tabela é necessário criar uma variável pagination
  paginatorEventEmitter = new EventEmitter();
  filterPagination = { cursor: null, First: 20, end: false, locked: false }
  alertRulesPagination = { cursor: null, First: 20, end: false, locked: false }

  ruleStatus = {
    "NEW": "novo",
    "AWARE": "Inativo",
    "OPENEDSO": "Aberto O.S",
    "RESOLVED": "Resolvido"
  }

  //Pegando todos os dados da tabela
  getAlerts(cursor: string | null, alertFilter: AlertFilter | null) {
    this.loadingAlertsBehaviorSubject.next(true);

    const variables = {
      company: this.company,
      ...(alertFilter?.installationReference ? { installation: alertFilter.installationReference } : {}),
      ...(alertFilter?.startDateTime ? { startDateTime: alertFilter.startDateTime } : {}),
      ...(alertFilter?.endDateTime ? { endDateTime: alertFilter.endDateTime } : {}),
      ...(alertFilter?.status ? { status: alertFilter.status } : {}),
      ...(alertFilter?.alertRuleId ? { alertRule: alertFilter.alertRuleId } : {}),
      ...(cursor ? { cursor } : {}),
      First: 20,
      sort_dir: "DESC",
      sort_field: "ALERT_DATETIME"
    };

    this.apollo.watchQuery({
      query: GET_ALL_ALERTS,
      fetchPolicy: 'network-only',
      variables
    })
      .valueChanges
      .pipe(
        take(1),
        switchMap((res: any) => {
          this.paginatorEventEmitter.emit(new PaginationInfo(
            res.data.alert.count,
            res.data.alert.total,
            res.data.alert.pageInfo.hasNextPage,
            res.data.alert.pageInfo.hasPreviousPage,
            res.data.alert.pageInfo.startCursor,
            res.data.alert.pageInfo.endCursor
          ));

          let alertsList: ALertsTable[] = [];

          res.data.alert.edges.forEach((alert: any) => {
            alertsList.push(new ALertsTable(
              alert.node.id,
              alert.node.alertDatetime,
              alert.node.installation.reference,
              alert.node.alertRule?.name,
              alert.node.occurrences,
              alert.node.status,
              res.data.alert.total
            ));
          });

          this.alertsBehaviorSubject.next(alertsList);
          this.alertsGetter();
          this.loadingAlertsBehaviorSubject.next(false);

          if (alertsList.length === 0) {
            // Retorna um aviso de erro ao usuário
            Swal.fire({
              title: 'Não Encontrado',
              text: 'Nenhum alerta encontrado.',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          }
          return [];
        }),
        catchError((err) => { throw err }),
        takeUntil(this.cancelTokenSource)
      ).subscribe({
        error: (error) => {
          this.loadingAlertsBehaviorSubject.next(false);
          this.errorLib.errorAlert(error);
        }
      });
  };

  alertsGetter(): any {
    return this.alerts$.pipe(
      map((alerts: ALertsTable[]) => alerts.filter((alerts: ALertsTable) => alerts))
    );
  }

  alertsRefresh(cursor: string | null, alertFilter: AlertFilter | null) {
    this.cancelTokenSource.next();
    this.getAlerts(cursor, alertFilter);
  }

  private getAlertRules() {
    this.apollo.watchQuery({
      query: GET_ALERT_RULES,
      variables: {
        company: this.company,
        First: this.alertRulesPagination.First,
        cursor: this.alertRulesPagination.cursor,
        sort_dir: "ASC",
        sort_field: "NAME"
      }
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          let alertRulesList: AlertRules[] = [];
          res.data.alertRules.edges.forEach((element: any) => {
            alertRulesList.push({
              id: element.node.id,
              name: element.node.name,
            })
          });
          this.alertsRulesBehaviorSubject.next(alertRulesList);
          this.loadingAlertRulesBehaviorSubject.next(false);
        }
        ))
      .subscribe({
        error: (err: any) => {
          this.errorLib.errorAlert(err);
        }
      });
  }

  getInstallationListByReference(reference: string | null) {
    this.loadingInstallationsSubject.next(true);
    this.apollo.watchQuery({
      query: GET_INSTALLATION_BY_REFERENCE,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        reference
      }
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          let installationList: Installation[] = [];
          res.data.installation.edges.forEach((element: any) => {
            installationList.push({
              installationId: element.node.id,
              installationReference: element.node.reference,
              installationMacAddress: element.node.macAddress
            })
          });
          this.installationsSubject.next(installationList);
          this.loadingInstallationsSubject.next(false);

        })
      ).subscribe();
  }

  getAlertById(id: string) {
    this.loadingAlertDataBehaviorSubject.next(true);
    return this.apollo.watchQuery({
      query: GET_ALERT_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          this.loadingAlertDataBehaviorSubject.next(false);
        })
      )
  }

  createServiceOrder(
    priority: any,
    status: any,
    installation: any,
    alerts: any,
    problem_reference: any,
    problem_details: any,
    problem_address: any,
    complainant_name: any,
    complainant_landlineNumber: any,
    complainant_phoneNumber: any,
    complainant_cpf: any,
    solution_closure_date: any,
    solution_details: any,
    solution_address: any
  ) {
    return this.apollo.mutate({
      mutation: CREATE_SERVICE_ORDER,
      variables: {
        priority,
        status,
        company: this.company,
        installation,
        alerts: [alerts],
        problem_reference,
        problem_details,
        problem_address,
        complainant_name,
        complainant_landlineNumber,
        complainant_phoneNumber,
        complainant_cpf,
        solution_closure_date,
        solution_details,
        solution_address,
      }
    })
  }

  getServiceOrder() {
    this.serviceOrdersLoadingBehaviorSubject.next(true);
    this.apollo.watchQuery({
      query: GET_SERVICE_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        direction: 'DESC',
        field: 'CREATION_DATE'
      }
    })
      .valueChanges
      .pipe(
        take(1),
        tap((res: any) => {
          console.log(res);
          let serviceOrderList: ServiceOrder[] = [];
          res.data.serviceOrder.edges.forEach((serviceOrder: any) => {
            if (serviceOrder.node.status != "CLOSED") {
              serviceOrderList.push(new ServiceOrder(
                serviceOrder.node.id,
                serviceOrder.node.serviceIdentifier,
                serviceOrder.node.creationDate,
                serviceOrder.node.status,
              ))
            }
          })
          this.serviceOrdersBehaviorSubject.next(serviceOrderList);
          this.serviceOrdersLoadingBehaviorSubject.next(false);
        })
      ).subscribe();
  }

  getServiceOrderOnAlertInformation(alerts: string) {
    return this.apollo.watchQuery({
      query: GET_SERVICE_ORDER_INFO,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        alerts: [alerts]
      }
    })
  }

  getServiceOrderInformation(identifier: string) {
    return this.apollo.watchQuery({
      query: GET_SERVICE_ORDER_INFO,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        identifier
      }
    })
  }

  loggedUser = localStorage.getItem('userId');

  addServiceOrderToAlert(alertId: string, serviceOrderId: string) {
    return this.apollo.mutate({
      mutation: ADD_SERVICE_ORDER_TO_ALERT,
      variables: {
        alerts: [alertId],
        id: serviceOrderId,
        clientMutationId: this.loggedUser
      }
    })
  }

  changeAlertStatus(id: string, status: string) {
    return this.apollo.mutate({
      mutation: CHANGE_ALERT_STATUS,
      variables: {
        ids: [id],
        status
      }
    })
  }

  getCSVDownloadLink(installationSelect: string | null, startDate: string | null, endDate: string | null, status: string | null, alertRule: string | null) {
    console.log('called getCSVDownloadLink');

    return this.apollo.mutate({
      mutation: GET_CSV_DOWNLOAD_LINK,
      variables: {
        company: this.company,
        alertRule: alertRule,
        startDateTime: startDate,
        endDateTime: endDate,
        installation: { reference: installationSelect },
        status: []
      }
    })
  }

  searchedInstallationGetter() {
    return this.searchedInstallations$;
  }

  resetPaginations() {
    this.filterPagination = { cursor: null, First: 20, end: false, locked: false }
    this.alertRulesPagination = { cursor: null, First: 20, end: false, locked: false }
  }

}

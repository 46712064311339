import { gql } from 'apollo-angular';

//Query Usuários
const GET_ALL_USERS = gql`
  query User(
    $company: ID!
    $username_Icontains: String
    $email_Icontains: String
    $isSuperuser: Boolean
  ) {
    user(
      isSuperuser: $isSuperuser
      company: $company
      username_Icontains: $username_Icontains
      email_Icontains: $email_Icontains
    ) {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          lastLogin
          isActive
          isSuperuser
          cpf
        }
        cursor
      }
      total
      count
    }
  }
`;

const USER_INFO = gql`
  query User($username: String) {
    user(username: $username) {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          lastLogin
          isSuperuser
          isActive
          cpf
          expiredPassword
        }
      }
    }
  }
`;

//Mutation Criar Usuários
const USER_CREATE = gql`
  mutation UserCreate(
    $firstname: String!
    $lastname: String
    $companiesIds: [ID]
    $cpf: String
    $username: String!
    $password: String!
    $email: String!
    $isSuperuser: Boolean!
  ) {
    userCreate(
      input: {
        firstName: $firstname
        lastName: $lastname
        companiesIds: $companiesIds
        cpf: $cpf
        username: $username
        password: $password
        email: $email
        isSuperuser: $isSuperuser
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        lastLogin
        cpf
        isActive
      }
    }
  }
`;
const GET_ALL_COMPANIES = gql`
  query All {
    company(orderBy: { field: NAME, direction: DESC }) {
      edges {
        node {
          id
          name
          cnpj
        }
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation userUpdate(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $companiesIds: [ID]
    $cpf: String
    $username: String!
    $email: String!
    $password: String
    $isSuperuser: Boolean!
    $expiredPassword: Boolean!
  ) {
    userUpdate(
      input: {
        id: $id
        firstName: $firstname
        lastName: $lastname
        companiesIds: $companiesIds
        cpf: $cpf
        username: $username
        email: $email
        password: $password
        isSuperuser: $isSuperuser
        expiredPassword:$expiredPassword
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        lastLogin
        cpf
        expiredPassword
        isActive
      }
    }
  }
`;

const USER_PROFILE_UPDATE = gql`
  mutation
  UserProfileUpdate(
    $firstname: String,
    $username: String,
    $email: String,
    $password: String
  ) {
    userProfileUpdate (
      input: {
        firstName: $firstname
        username: $username
        email: $email
        password: $password
        expiredPassword: false
      })
    {
      user {
        id
      }
    }
  }
`;
const USER_DEACTIVATION_QUERY = gql`
  mutation userDeactivate(
    $userId: ID!

  ){
    userDeactivate(input: {
      userId: $userId,

    }){
      user {
        id
      }
    }
  }
`;

const USER_ACTIVATION_QUERY = gql`
  mutation userReactivate(
    $userId: ID!,
    $password: String!

  ){
    userReactivate(input: {
      userId: $userId,
      password: $password

    }){
      user {
        id
      }
    }
  }
`;

export {
  USER_CREATE,
  GET_ALL_COMPANIES,
  GET_ALL_USERS,
  USER_UPDATE,
  USER_INFO,
  USER_PROFILE_UPDATE,
  USER_DEACTIVATION_QUERY,
  USER_ACTIVATION_QUERY
};

import { gql } from "apollo-angular";

const FILTER_EQUIPMENT_LOG = gql`
query EquipmentLog(
  $company: ID!,
  $operation: Operations,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $serialNumber: String,
  $macAddress: String,
  $username: String,
  $pageSize: Int,
  $cursor: String
  ){
    equipmentLog(
      company: $company,
      operation: $operation,
      startDatetime: $startDatetime,
      endDatetime: $endDatetime,
      serialNumber: $serialNumber,
      macAddress: $macAddress,
      username: $username,
      pageSize: $pageSize,
      cursor: $cursor
      ){
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        count
		    total
        edges{
            node{
                operation
                serialNumber
                macAddress
                username
                logDatetime
                company{
                    id
                    name
                    cnpj
                }
            }
        }
    }
}`;

export {
    FILTER_EQUIPMENT_LOG
}

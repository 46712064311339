export class UserLog {
  constructor(
    public username: string | null,
    public operationType: string | null,
    public requestDatetime: string | null,
    public requestedField: string | null
  ){}
}


export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ){}
}

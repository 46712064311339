import { DateTime } from 'luxon'; // Biblioteca do js utilizada para manipular as datas com o timezone correto e converter para o formato ISO.

//Utilizado para converter dateTime para o fuso horário do navegador do usuário
export const tzConvertUTC2Local = (dateTime: string): string => {
    if (dateTime) {
        //Cria um objeto de data
        const originalDateTime = new Date(dateTime);
        //Obter o fuso horário atual do navegador
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        //Abreviação do nome do fuso horário atual do navegado
        const timezoneAbbr = new Date().toLocaleTimeString('en', { timeZoneName: 'short' }).split(' ')[2];

        //Adicione a abreviação (timezoneAbbr) e converte o objeto de Date (originalDateTime) para um fuso horário especificado
        const convertedDateTime = originalDateTime.toLocaleString(undefined, {
            timeZone: timeZone,
        }) + ` (${timezoneAbbr})`;

        //Retorna o valor convertido
        return convertedDateTime;
    } else {
        return ''
    }
}

/** Utilizado para converter a data inicial das entradas do usuário, converter para o formato ISO e pegar o timezone de cada local **/
export const tzConvertISOStartDate = (dateTime: string | any) => {

    /** Caso alguma propriedade de data seja utilizada no front pelo usuário **/
    if (dateTime) {

        /** Utilizado para obter o timezone do usuário **/
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        /** Seta o timezone, converte a data no formato ISO e define a data inicial para 00:00 como padrão **/
        let convertedDateTime: any = DateTime.fromJSDate(dateTime).setZone(timeZone).set({ hour: 0, minute: 0, second: 0 }).toISO({ includeOffset: true });

        /** Retorna a data convertida **/
        return convertedDateTime;
    }
}

/** Utilizado para converter a data final das entradas do usuário, converter para o formato ISO e pegar o timezone de cada local **/
export const tzConvertISOEndDate = (dateTime: string | any) => {

    /** Caso alguma propriedade de data seja utilizada no front pelo usuário **/
    if (dateTime) {

        /** Utilizado para obter o timezone do usuário **/
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        /** Seta o timezone, converte a data no formato ISO e define a data final para 23:59:59 como padrão **/
        const convertedDateTime: any = DateTime.fromJSDate(dateTime).setZone(timeZone).set({ hour: 23, minute: 59, second: 59 }).toISO({ includeOffset: true });

        /** Retorna a data convertida **/
        return convertedDateTime;
    }
}
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  ALERT_MUTATION,
  COMMAND_LIST_REPORT_MUTATION,
  EVENTS_REPORT_MUTATION,
  FIRST_ON_FIRST_OFF_REPORT_MUTATION,
  GET_ALL_REPORTS,
  GET_INSTALLATION_BY_REFERENCE,
  GET_REPORT_DOWNLOAD_URL,
  INDIVIDUAL_CONSUMPTION_MUTATION,
  INDIVIDUAL_STATUS_MUTATION,
  INSTALLATION_LIST_REPORT,
  LAST_DATA_MUTATION,
  LAST_TRANSMISSION_MUTATION,
  METEOROLOGICAL_REPORT_MUTATION,
  NO_TRANSMISSION_REPORT_MUTATION,
  REGISTER_INSTALLATIONS_REPORT,
  REPORT_DELETE,
  GENERATE_GATEWAY_CONSUMPTION,
  POSSIBLE_STATUS_REPORT_MUTATION,
  INDIVIDUAL_READ_CONSUMPTION_MUTATION,
  INDIVIDUAL_PULSE_CONSUMPTION_MUTATION,
  GET_CIRCUIT_BOXES,
  CIRCUIT_BOX_CONSUMPTION_MUTATION
} from 'src/app/graphql/graphql.queries';
import { Gateway } from '../components/report/models/installation.model';
import { CommandReportModel, EventsModel, IndividualConsumptionModel, IndividualStatusReportModel, InstallationListModel, LastTransmissionModel, MetereologicalReportModel, NoTransmissionReportModel, ReportTable, PaginationInfo, StatusModel, InstallationRecordModel, LatestDataModel, FirstOnFirstOff } from '../components/report/models/report.model';
import { ReportFilter } from '../components/report/models/reportFilter.model';
import { FILTER_INSTALLATIONS } from 'src/app/graphql/installationQueries.graphql';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private apollo: Apollo) { }

  company = localStorage.getItem('lastCompanySelected');

  public getAllReports(cursor: string | null, reportFilter: ReportFilter | null, pageSize: number | null): any {

    const variables = {
      company: this.company,
      ...(reportFilter?.reference ? { reference: reportFilter.reference } : {}),
      ...(reportFilter?.reportType ? { reportType: reportFilter.reportType } : {}),
      ...(reportFilter?.requestedBy ? { requestedBy: reportFilter.requestedBy } : {}),
      first: pageSize,
      ...(cursor ? { after: cursor } : {}),
    };

    return this.apollo.watchQuery({
      query: GET_ALL_REPORTS,
      fetchPolicy: 'network-only',
      variables
    })
  }

  handlePreviousPage(cursor: string | null, reportFilter: ReportFilter | null, pageSize: number | null) {
    const variables = {
      company: this.company,
      ...(reportFilter?.reference ? { reference: reportFilter.reference } : {}),
      ...(reportFilter?.reportType ? { reportType: reportFilter.reportType } : {}),
      ...(reportFilter?.requestedBy ? { requestedBy: reportFilter.requestedBy } : {}),
      last: pageSize,
      ...(cursor ? { before: cursor } : {}),
    };

    return this.apollo.watchQuery({
      query: GET_ALL_REPORTS,
      fetchPolicy: 'network-only',
      variables
    })
  }


  dateFormatter(date: any): string {
    let year = date.slice(0, 4);
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    let time = date.slice(11, 16);
    return (day + '/' + month + '/' + year + ' ' + time);
  }

  getReportDownloadLink(reportId: any, companyId: any) {
    return this.apollo.watchQuery<any>({
      query: GET_REPORT_DOWNLOAD_URL,
      variables: {
        report: reportId,
        company: companyId
      }
    })
  }

  deleteReport(reportId: any, companyId: any) {
    return this.apollo.mutate({
      mutation: REPORT_DELETE,
      variables: {
        report: [reportId],
        company: companyId
      }
    })
  }

  generateLastTransmissionReport(lastTransmission: LastTransmissionModel) {
    return this.apollo.mutate({
      mutation: LAST_TRANSMISSION_MUTATION,
      variables: {
        company: this.company,
        reference: lastTransmission.reference
      }
    })
  }

  generateInstallationRecordReport(installationRecord: InstallationRecordModel) {
    return this.apollo.mutate({
      mutation: REGISTER_INSTALLATIONS_REPORT,
      variables: {
        company: this.company,
        reference: installationRecord.reference
      }
    })
  }

  generateLatestDataReport(latestData: LatestDataModel) {
    return this.apollo.mutate({
      mutation: LAST_DATA_MUTATION,
      variables: {
        company: this.company,
        reference: latestData.reference
      }
    })
  }

  generateAlertReport(statusReport: StatusModel) {
    return this.apollo.mutate({
      mutation: ALERT_MUTATION,
      variables: {
        company: this.company,
        reference: statusReport.reference,
        installations: statusReport.installationIdList,
        startDatetime: statusReport.startDatetime,
        endDatetime: statusReport.endDatetime,
      }
    })
  }

  generateIndividualConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_CONSUMPTION_MUTATION,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        kind: individualConsumptionReport.kind,
        macAddress: individualConsumptionReport.macAddress
      }
    })
  }

  generateReadConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_READ_CONSUMPTION_MUTATION,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        macAddress: individualConsumptionReport.macAddress
      }
    })
  }

  generatePulseConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_PULSE_CONSUMPTION_MUTATION,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        macAddress: individualConsumptionReport.macAddress
      }
    })
  }

  generateCircuitConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: CIRCUIT_BOX_CONSUMPTION_MUTATION,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        macAddress: individualConsumptionReport.macAddress
      }
    })
  }

  generateIndividualStatusReport(individualStatusReport: IndividualStatusReportModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_STATUS_MUTATION,
      variables: {
        company: this.company,
        reference: individualStatusReport.reference,
        startDatetime: individualStatusReport.startDatetime,
        endDatetime: individualStatusReport.endDatetime,
        macAddress: individualStatusReport.macAddress
      }
    })
  }

  generateEventsReport(events: EventsModel) {
    return this.apollo.mutate({
      mutation: EVENTS_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: events.reference,
        startDatetime: events.startDatetime,
        endDatetime: events.endDatetime,
        macAddress: events.macAddress
      }
    })
  }

  generateMeteorologicalReport(metereologicalReport: MetereologicalReportModel) {
    return this.apollo.mutate({
      mutation: METEOROLOGICAL_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: metereologicalReport.reference,
        startDate: metereologicalReport.startDatetime,
        endDate: metereologicalReport.endDatetime,
        macAddress: metereologicalReport.macAddress
      }
    })
  }

  generateCommandsListReport(commandReport: CommandReportModel) {
    return this.apollo.mutate({
      mutation: COMMAND_LIST_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: commandReport.reference,
        startDate: commandReport.startDatetime,
        endDate: commandReport.endDatetime
      }
    })
  }

  generateInstallationListReport(installationList: InstallationListModel) {
    return this.apollo.mutate({
      mutation: INSTALLATION_LIST_REPORT,
      variables: {
        company: this.company,
        reference: installationList.reference
      }
    })
  }

  generateNoTransmissionReport(noTransmissionReport: NoTransmissionReportModel) {
    return this.apollo.mutate({
      mutation: NO_TRANSMISSION_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: noTransmissionReport.reference,
        hours: noTransmissionReport.hours
      }
    })
  }

  generateFirstOnFirstOff(reference: string | null, targetDate: string) {
    return this.apollo.mutate({
      mutation: FIRST_ON_FIRST_OFF_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: reference,
        targetDate: targetDate
      }
    })
  }

  generatePossibleStatus(reference: string | null, targetDate: string) {
    return this.apollo.mutate({
      mutation: POSSIBLE_STATUS_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: reference,
        targetDate: targetDate
      }
    })
  }

  generateGatewayConsumption(reference: string, gateway: Gateway, startDatetime: String, endDatetime: String) {
    return this.apollo.mutate({
      mutation: GENERATE_GATEWAY_CONSUMPTION,
      variables: {
        company: this.company,
        reference: reference,
        gateway: gateway.id,
        startDatetime: startDatetime,
        endDatetime: endDatetime
      }
    })
  }

  /** Algumas páginas usam essa consulta aqui. **/
  getInstallationByReference(referenceContains: string | null, cursor: string | null = null,
    pageSize: number | null = null) {
    return this.apollo.query({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains: referenceContains,
        pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  // Filtra instalação por empresa
  getCircuitBoxesByReference(company: string | null, referenceContains: string | null) {
    return this.apollo.query({
      query: GET_CIRCUIT_BOXES,
      fetchPolicy: 'network-only',
      variables: {
        company: company,
        referenceContains: referenceContains,
        first: 15
      }
    })
  }

}

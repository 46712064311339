import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, catchError, take, tap } from 'rxjs';
import {
  FILTER_COMPONENTS,
  FILTER_COMPONENTS_TYPE,
  FILTER_INSTALLATION,
  CREATE_COMPONENT,
  GET_COMPONENTS_TABLE,
  UPDATE_COMPONENT,
} from 'src/app/graphql/components.queries';
import Swal from 'sweetalert2';
import {
  Components,
  ComponentType,
  Installation,
  PaginationInfo,
} from '../components/register/pages/components/components-assets/components.model';
import { ErrorLibService } from './error-lib.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  constructor(
    private apollo: Apollo,
    private errorLibService: ErrorLibService
  ) {
    /** Verifica se a empresa foi selecioanada pelo usuário **/
    this.company = localStorage.getItem('lastCompanySelected')
      ? localStorage.getItem('lastCompanySelected')
      : null;
    /** chama a requisição de tipos de componentes **/
    this.getComponentsType();
    /** chama a requisição de tipos de instalações **/
    this.getInstallations();
  }

  /**  Variável responsável por armazenar o ID da empresa selecionada **/
  company: string | null;

  //Emite o evento de paginação
  paginatorFilterEvent = new EventEmitter();

  /** Observables responsáveis por manipular as variáveis assincronas **                                                                                                                                                                                                                                                   ***/
  private componentsSubject = new BehaviorSubject<Components[]>([]);
  components$ = this.componentsSubject.asObservable();

  private componentsLoadingSubject = new BehaviorSubject<boolean>(false);
  componentsLoading$ = this.componentsLoadingSubject.asObservable();

  private componentsTypeSubject = new BehaviorSubject<ComponentType[]>([]);
  componentsType$ = this.componentsTypeSubject.asObservable();

  private componentsTypeLoadingSubject = new BehaviorSubject<boolean>(false);
  componentsTypeLoading$ = this.componentsTypeLoadingSubject.asObservable();

  private installationsSubject = new BehaviorSubject<Installation[]>([]);
  componentsInstallation$ = this.installationsSubject.asObservable();

  updateComponentLoading = new BehaviorSubject<boolean>(false);
  updateComponentLoading$ = this.installationsSubject.asObservable();

  private componentsInstallationLoadingSubject = new BehaviorSubject<boolean>(
    false
  );
  componentsInstallationLoading$ =
    this.componentsInstallationLoadingSubject.asObservable();

  /** Evento responsável pelo loading de filtro de componentes **/
  componentFilterEvent = new EventEmitter();

  /** Requisição para o filtro de componentes **/
  private filterComponents(
    company: string,
    cursor: string | null,
    search: string,
    materialTypes: string[]
  ): any {
    /** Ativa o loading na tela **/
    this.componentFilterEvent.emit(true);
    /** Inicia a requsição **/
    this.apollo
      .watchQuery({
        query: FILTER_COMPONENTS,
        fetchPolicy: 'network-only',
        variables: {
          company,
          pageSize: 10,
          search,
          materialTypes,
          cursor,
        },
      })
      .valueChanges.pipe(
        take(1),
        /** Trata a resposta de sucesso **/
        tap((res: any) => {
          //variavel que pega o evento de paginação
          let paginator = new PaginationInfo(
            res.data.material.count,
            res.data.material.total,
            res.data.material.pageInfo.hasNextPage,
            res.data.material.pageInfo.hasPreviousPage,
            res.data.material.pageInfo.startCursor,
            res.data.material.pageInfo.endCursor
          );

          this.paginatorFilterEvent.emit(paginator);

          /** Variável que recebe uma lista de componentes **/
          let componentsList: Components[] = [];
          /** Adiciona cada elemento a lista de componentes **/
          res.data.material.edges.forEach((node: any) => {
            componentsList.push(
              new Components(
                node.node?.id,
                node.node?.serialNumber,
                new Installation(
                  node.node?.installation?.id,
                  node.node?.installation?.reference
                ),
                new ComponentType(node.node.type?.id, node.node.type?.reference)
              )
            );
          });

          /** Atualizando a variável assincrona **/
          this.componentsSubject.next(componentsList);
          /** Desativa o loading na tela **/
          this.componentFilterEvent.emit(false);
          /** Atualiza os valores na tabela **/
          this.componentsGetter();

          if (!res.data.material.pageInfo.hasNextPage) {
            /** Caso os campos informados pelo usuário não exista **/
            if (componentsList.length === 0) {
              /** Exibe mensagem de falha ao usuário **/
              Swal.fire({
                title: 'Sua pesquisa não retornou resultados',
                icon: 'warning',
                confirmButtonText: 'Ok',
              });
            } else {
              /** Caso a pequisa feita pelo usuário retorne algum resultado **/
              /** Exibe mensagem de sucesso ao usuário **/
              Swal.fire({
                title: 'Pesquisa realizada com sucesso',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            }
          }
        }),
        /** Tratamento de erros **/
        catchError((err) => {
          throw err;
        })
      )
      .subscribe({
        error: (error) => {
          console.log(error);
          /** Desativa o loading **/
          this.componentFilterEvent.emit(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        },
      });
  }

  /** Função que atualiza a lista de componentes **/
  componentsRefresh(
    company: string,
    cursor: string | null,
    search: string,
    materialType: string[]
  ): any {
    this.filterComponents(company, cursor, search, materialType);
  }

  /** Função utilizada no TS para manipular a variável assincrona **/
  componentsGetter() {
    return this.components$;
  }

  /** Requisição de tipos de componentes **/
  private getComponentsType(): any {
    /** Ativa o loading na tela **/
    this.componentsTypeLoadingSubject.next(true);
    /** Inicia a requisição **/
    this.apollo
      .watchQuery({
        query: FILTER_COMPONENTS_TYPE,
        fetchPolicy: 'network-only',
        variables: {
          company: this.company,
          pageSize: 100
        },
      })
      .valueChanges.pipe(
        take(1),
        /** Trata resposta de sucesso **/
        tap((res: any) => {
          /** Variável que recebe uma lista de tipos de componentes **/
          let componentsTypeList: ComponentType[] = [];
          /** Adiciona cada elemento a lista de tipos de componentes **/
          res.data.materialType.edges.forEach((node: any) => {
            componentsTypeList.push(
              new ComponentType(node.node?.id, node.node?.reference)
            );
          });
          /** Atualiza a variável assincrona **/
          this.componentsTypeSubject.next(componentsTypeList);
          /** Desativa o loading na tela **/
          this.componentsTypeLoadingSubject.next(false);
          /** **/
          this.componentsTypeGetter();
        }),
        catchError((err) => {
          throw err;
        })
      )
      .subscribe({
        error: (error) => {
          console.log(error);
          /** Desativa o loading na tela **/
          this.componentsTypeLoadingSubject.next(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        },
      });
  }

  componentsTypeGetter() {
    return this.componentsType$;
  }
  /** Função que realiza a requisição de instalações **/
  private getInstallations(): any {
    /** Ativa o loading na tela **/
    this.componentsInstallationLoadingSubject.next(true);
    /** Inicia a requisição **/
    this.apollo
      .watchQuery({
        query: FILTER_INSTALLATION,
        fetchPolicy: 'network-only',
        variables: {
          company: this.company,
          sort_dir: 'ASC',
          sort_field: 'REFERENCE',
        },
      })
      .valueChanges.pipe(
        take(1),
        /** Trata o resultado de sucesso **/
        tap((res: any) => {
          /** Cria variavel que recebe uma lista de instalações **/
          let installationList: Installation[] = [];
          /** Adiciona cada elemento dentro da lista de instalações **/
          res.data.installation.edges.forEach((node: any) => {
            installationList.push(
              new Installation(node.node?.id, node.node?.reference)
            );
          });

          /** Atualiza a variavel assincrona **/
          this.installationsSubject.next(installationList);
          /** Desativa o loading na tela **/
          this.componentsInstallationLoadingSubject.next(false);
          /** */
          this.installationGetter();
        }),
        /** Tratamento de erros **/
        catchError((err) => {
          throw err;
        })
      )
      .subscribe({
        error: (error) => {
          console.log(error);
          /** Desativa o loading na tela **/
          this.componentsInstallationLoadingSubject.next(false);
          /** Exibe um alerta de erro  ao usuário **/
          this.errorLibService.errorAlert(error);
        },
      });
  }
  /** Função para utilizar no TS manipulando a variavel assincrona **/
  installationGetter() {
    return this.componentsInstallation$;
  }

  /** Função responsável por realizar a requisição de criação de componentes **/
  createComponents(
    company: string,
    installationId: string[],
    serialNumber: string,
    materialType: string[]
  ) {
    /** Ativa o loading na tela */
    this.componentsLoadingSubject.next(true);
    /** Inicia a requisição **/
    this.apollo
      .mutate({
        mutation: CREATE_COMPONENT,
        variables: {
          company: company,
          installationId: installationId,
          serialNumber: serialNumber,
          materialType: materialType,
        },
      })
      .pipe(
        tap(() => {
          /** Desativa o loading na tela **/
          this.componentsLoadingSubject.next(false);

          /** Atualiza o filtro **/
          this.filterComponents(company, null, serialNumber, materialType);
          /** Exibe mensagem de sucesso ao usuário assim que o componente é criado **/
          Swal.fire({
            title: 'Componente Criado',
            text: 'Componente criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        }),
        /** Tratamento de erros **/
        catchError((err) => {
          throw err;
        })
      )
      .subscribe({
        error: (error) => {
          /** Desativa o loading na tela **/
          this.componentsLoadingSubject.next(false);
          /** Exibe alerta do erro ao usuário **/
          this.errorLibService.errorAlert(error);
        },
      });
  }

  getCreateComponent(
    company: string,
    installationId: string[],
    serialNumber: string,
    materialType: string[]
  ) {
    this.createComponents(company, installationId, serialNumber, materialType);
  }

  /** Função para utilizar no TS manipulando a variável assincrona **/
  componentsTableGetter() {
    return this.components$;
  }

  updateComponent(
    id: string,
    company: string,
    installationId: string,
    serialNumber: string,
    componentType: string
  ) {
    /** Ativa o loading na tela */
    this.updateComponentLoading.next(true);
    /** Inicia a requisição **/
    this.apollo
      .mutate({
        mutation: UPDATE_COMPONENT,
        variables: {
          id: id,
          company: company,
          installationId: installationId,
          serialNumber: serialNumber,
          materialType: componentType,
        },
      })
      .pipe(
        tap(() => {
          /** Desativa o loading na tela **/
          this.updateComponentLoading.next(false);

          /** Atualiza o filtro **/
          this.filterComponents(company, null, serialNumber, [componentType]);

          /** Exibe mensagem de sucesso ao usuário assim que o componente é criado **/
          Swal.fire({
            title: 'Componente atualizado',
            text: 'Componente atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        }),
        /** Tratamento de erros **/
        catchError((err) => {
          throw err;
        })
      )
      .subscribe({
        error: (error) => {
          /** Desativa o loading na tela **/
          this.updateComponentLoading.next(false);
          /** Exibe alerta do erro ao usuário **/
          this.errorLibService.errorAlert(error);
        },
      });
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_INSTALLATION_SITE, DELETE_INSTALLATION_SITE, FILTER_INSTALLATION_SITE, UPDATE_INSTALLATION_SITE } from 'src/app/graphql/installation-location.graphql';

@Injectable({
  providedIn: 'root'
})
export class InstallationSiteService {

  constructor(private apollo: Apollo) {
    //Verifica se uma empresa foi selecionada pelo usuário
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  company: string | null;

  /**
    * Função responsável por fazer a requisição de tipos de equipamentos
    */
  getInstallationSite(
    reference: string | null,
    country: string | null,
    state: string | null,
    city: string | null,
    district: string | null,
    street: string | null,
    locationCode: string | null,
    cursor: string | null,
    pageSize: number | null,
  ): any {
    return this.apollo.watchQuery({
      query: FILTER_INSTALLATION_SITE,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        reference,
        country,
        state,
        city,
        district,
        street,
        locationCode,
        pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "REFERENCE",
      }
    })
  }

  //--------------------------- Session create Location -------------------------------------------

  createIntallationSite(
    reference: string | null,
    country: string | null,
    state: string | null,
    city: string | null,
    district: string | null,
    street: string | null,
    number: string | null,
    details: string | null,
    locationCode: string | null,
    latitude: number | null,
    longitude: number | null
  ) {
    return this.apollo.mutate({
      mutation: CREATE_INSTALLATION_SITE,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        reference,
        country,
        state,
        city,
        district,
        street,
        number,
        details,
        locationCode,
        latitude,
        longitude,
        first: null,
        cursor: String,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  updateInstallationSite(
    id: string | null,
    latitude: number | null,
    longitude: number | null,
    reference: string | any,
    country: string | null,
    district: string | null,
    state: string | null,
    city: string | null,
    street: string | null,
    number: string | null,
    details: string | null,
    locationCode: string | null,
    isActive: boolean = true
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_INSTALLATION_SITE,
      fetchPolicy: 'network-only',
      variables: {
        id,
        latitude,
        longitude,
        reference,
        country,
        district,
        state,
        city,
        street,
        number,
        details,
        locationCode,
        isActive,
        company: this.company
      }
    })
  }

  /** Retorna a mutation de deletar local de instalação **/
  deleteInstallationSite(id: string) {
    return this.apollo.mutate({
      mutation: DELETE_INSTALLATION_SITE,
      fetchPolicy: 'network-only',
      variables: {
        id,
        company: this.company
      }
    })
  }
}

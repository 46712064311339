import { gql } from "apollo-angular";

// Filtra os tipos de equipamentos
const filterEquipmentType = gql`
query EquipmentType(
	$pageSize: Int
	$cursor: String
	$major: Int
	$minor: Int
	$reference: String
	$revision: Int
) {
	equipmentType(
		pageSize: $pageSize
		cursor: $cursor
		major: $major
		minor: $minor
		reference: $reference
		revision: $revision
	) {
		edges {
			node {
				id
				reference
				major
				minor
				revision
				description
				releaseDate
				equipmentParts {
					edges {
						node {
							id
							hardware {
								id
								name
								major
								minor
								revision
							}
							firmwares {
								edges {
									node {
										id
										name
										major
										minor
										revision
									}
								}
							}
						}
					}
				}
				commandSheet {
					id
					hasActuation
					hasClockSync
					hasPowerQuality
					channelsCount
					oldCrc
					schedulingSlotsCountPerChannel
					schedulingSlotsCount
					parameters {
						edges {
							node {
								reference
								maxValue
								minValue
								targetReference
								type
								choices {
									edges {
										node {
											value
											description
										}
									}
								}
							}
						}
					}
					queryCommands {
						edges {
							node {
								type
								responseProperties
							}
						}
					}
				}
			}
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

// Filtra parametros de resposta para os comandos
const FilterParametersConfig = gql`
query Parameters($pageSize: Int){
    commandParameter(pageSize: $pageSize){
        edges {
            node {
                reference
                targetReference
            }
        }
    }
}`;

// Filtra propriedades para os comandos
const FilterProperty = gql`
query Property ($pageSize: Int) {
    property (
        pageSize: $pageSize
        ){
        edges {
            node {
                id
                sourceName
                name
                shortName
                measurementUnit{
                    id
                    name
                    symbol
                }
            }
        }
    }
}`;

// Filtra hardware
const FILTER_HARDWARE = gql`

query
	Hardware ($pageSize: Int) {
		hardware (pageSize: $pageSize){
			edges {
				node {
					id
					name
					minor
					major
					revision
					description
					releaseDate
					compatibleFirmware{
						edges{
							node{
								id
								name
								major
								minor
								revision
							}
						}
					}
				}
			}
		}
	}`;

// Filtra firmwares compativeis com o hardware selecionado pelo usuário
const FILTER_COMPATIBLE_FIRMWARE = gql`
query node($id: ID!) {
    node(id: $id) {
        ... on Hardware {
            id
            major
            minor
            revision
            description
            releaseDate
            name
            compatibleFirmware {
                edges {
                    node {
                        id
                        major
                        minor
                        revision
                        name
                    }
                }
            }
        }
    }
}`;

// Cria tipos de equipamentos
const CREATE_EQUIPMENT_TYPE = gql`
mutation
			EquipmentTypeCreate(
				$reference: String!,
				$major: Int!,
				$minor: Int!,
				$revision: Int!,
				$description: String,
				$releaseDate: Date,
				$hardware:ID,
				$firmwares:[ID],
				$commandSheet: CommandSheetInput
			) {
			equipmentTypeCreate(
				input: {
					reference: $reference
					major: $major
					minor: $minor
					revision: $revision
					description: $description
					releaseDate: $releaseDate
					equipmentParts: {
						hardware: $hardware
						firmwares: $firmwares
					}
					commandSheet: $commandSheet
				}) {
				equipmentType {
					id
				}
				operationErrors
			}
		}`;

// Criar Hardwares
const CREATE_HARDWARES = gql`
mutation
	HardwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date,
		$compatibleFirmwares:[ID]) {
		hardwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
				compatibleFirmwares: $compatibleFirmwares
			}) {
			hardware {
				id
			}
		}
	}`;

// Criar Firmwares
const FIRMWARE_CREATE = gql`
mutation
	FirmwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		firmwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			firmware {
				id
			}
		}
	}`;

/** Lista os Firmwares */
const FILTER_FIRMWARES = gql`
query
	Firmware ($pageSize: Int){
		firmware (pageSize: $pageSize){
			edges {
				node {
					id
					name
					minor
					major
					revision
					description
					releaseDate
				}
			}
		}
}`;

const UPDATE_COMMANDS_EQUIPMENT_TYPE = gql`
mutation EquipmentTypeUpdate(
	$id: ID!
	$description: String
	$releaseDate: Date
	$hardware: ID
	$firmwares: [ID]
	$commandSheet: CommandSheetInput
) {
	equipmentTypeUpdate(
		input: {
			id: $id
			description: $description
			releaseDate: $releaseDate
			equipmentParts: { hardware: $hardware, firmwares: $firmwares }
			commandSheet: $commandSheet
		}
	) {
		equipmentType {
			id
		}
	}
}`;

const GET_EQUIPMENT_TYPE = gql`
query node($id: ID!) {
	node(id: $id) {
		... on EquipmentType {
			id
			major
			minor
			revision
			description
			releaseDate
			reference
			equipmentParts {
				edges {
					node {
						id
						hardware {
							id
						}
						firmwares {
							edges {
								node {
									id
								}
							}
						}
					}
				}
			}
			commandSheet {
				id
				channelsCount
				hasActuation
				schedulingSlotsCount
				schedulingSlotsCountPerChannel
				hasPowerQuality
				hasClockSync
				oldCrc
				queryCommands {
					edges {
						node {
							type
							responseProperties
						}
					}
				}
				parameters {
					edges {
						node {
							reference
							maxValue
							minValue
							targetReference
							type
							choices {
								edges {
									node {
										description
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
}`;

const UPDATE_EQUIPMENT_TYPE = gql`
mutation EquipmentTypeUpdate(
	$id: ID!
	$description: String
	$releaseDate: Date
	$commandSheet: CommandSheetInput
	$equipmentParts: [PartsInput]
) {
	equipmentTypeUpdate(
		input: {
			id: $id
			description: $description
			releaseDate: $releaseDate
			equipmentParts: $equipmentParts
			commandSheet: $commandSheet
		}
	) {
		equipmentType {
			id
			reference
			releaseDate
			description
			major
			minor
			revision
			equipmentParts {
				edges {
					node {
						id
						hardware {
							id
						}
						firmwares {
							edges {
								node {
									id
									major
									minor
									revision
								}
							}
						}
					}
				}
			}
			commandSheet {
				id
				channelsCount
				schedulingSlotsCount
				schedulingSlotsCountPerChannel
				hasActuation
				hasClockSync
				hasPowerQuality
				oldCrc
				parameters {
					edges {
						node {
							reference
							type
							targetReference
							maxValue
							minValue
							choices {
								edges {
									node {
										description
										value
									}
								}
							}
						}
					}
				}
				queryCommands {
					edges {
						node {
							type
							responseProperties
						}
					}
				}
			}
		}
	}
}`;

export {
	filterEquipmentType,
	FilterParametersConfig,
	FilterProperty,
	FILTER_HARDWARE,
	FILTER_COMPATIBLE_FIRMWARE,
	CREATE_EQUIPMENT_TYPE,
	CREATE_HARDWARES,
	FIRMWARE_CREATE,
	FILTER_FIRMWARES,
	UPDATE_COMMANDS_EQUIPMENT_TYPE,
	GET_EQUIPMENT_TYPE,
	UPDATE_EQUIPMENT_TYPE
}
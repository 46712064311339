import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertsService } from 'src/shared/services/alerts.service';
import { ALertsTable, AlertFilter, PaginationInfo } from '../models/alerts.model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Installation } from '../../alert-rules/models/installation.model';
import { DateformatterService } from 'src/shared/services/dateformatter.service';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import jsPDF from 'jspdf';
import { tzConvertISOEndDate, tzConvertISOStartDate } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.less']
})
export class AlertsComponent extends Unsubscriber implements OnInit {

  constructor(

    private alertsService: AlertsService,
    private errorLibService: ErrorLibService,
    private dateFormatterService: DateformatterService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions = this.alertsService.alertsGetter()
      .subscribe((res: ALertsTable[]) => {
        res.forEach((res) => {
          this.initialTableData.push(res);
        });
        this.setDataSourceAttributes();

        // Subscreve para pegar as informações do pageinfo.
        // Se ele não ficar aqui dentro ele não pega os valores.
        this.subscriptions = this.alertsService.paginatorEventEmitter.subscribe(
          (pageInfo: PaginationInfo) => {
            this.pageInfo = pageInfo;
          }
        );

        if (this.pageInfo.hasNextPage) {
          // Valida se existe uma próxima página na API para continuar as requisições.
          // Caso haja uma próxima página ele manda uma requisição com o cursor para a mesma.
          this.alertsService.alertsRefresh(
            this.pageInfo.endCursor,
            this.alertFilter
          );
        } else {
          // Limpa o cursor e a lista de dados.
          this.pageInfo.endCursor = null;
          // this.initialTableData = [];
          this.alertFilter = null;
        }
      });

    this.subscriptions = this.alertsService.searchedInstallationGetter()
      .subscribe({
        next: ((installations: Installation[]) => {
          this.searchedInstallations = installations;
        })
      })
  }

  public initialTableData: ALertsTable[] = []
  public loadingAlerts$ = this.alertsService.loadingAlerts$;
  public loadingAlertRules$ = this.alertsService.loadingAlertRules$;
  public alertRules$ = this.alertsService.alertRules$;
  public loadingInstallations$ = this.alertsService.loadingInstallations$
  public displayedColumns: string[] = ['date', 'point', 'rule', 'occurrences', 'status', 'actions'];
  public dataSource = new MatTableDataSource<ALertsTable>(this.initialTableData);
  public searchedInstallations: Installation[] = [];
  public isFiltering: boolean = false;
  public editedAlertId: string = '';
  public isEditingAlert: boolean = false;
  public resultsLength = this.initialTableData.length;
  public pageInfo: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);
  public alertFilter: AlertFilter | null;

  //Variáveis usadas para o paginator e para o sort.
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /**
   * Função que configura o paginator.
   */
  public setDataSourceAttributes() {
    this.dataSource.data = this.initialTableData;
    this.dataSource.paginator = this.paginator;
  }

  //Variáveis formControl
  public installationSearchFormControl = new FormControl('');
  public installationSelectFormControl = new FormControl('');

  public filterForm: FormGroup = this.formBuilder.group({
    startDate: [null],
    endDate: [null],
    status: [null],
    alertRule: [null],
  });

  public filter: boolean = false;

  public FilterBox(): void {
    this.filter = !this.filter;
  }

  public searchInstallation(): void {
    this.alertsService.getInstallationListByReference(this.installationSearchFormControl.value);
  }

  public filterAlerts(): void {
    console.log('called filtered alerts');

    this.initialTableData = [];
    this.isFiltering = true;

    this.alertFilter = new AlertFilter(
      this.installationSelectFormControl.value,
      tzConvertISOStartDate(this.filterForm.value.startDate),
      tzConvertISOEndDate(this.filterForm.value.endDate),
      this.filterForm.value.status ? this.filterForm.value.status : null,
      this.filterForm.value.alertRule ? this.filterForm.value.alertRule : null
    );

    this.alertsService.alertsRefresh(null, this.alertFilter);
  }

  public editAlert(alertId: string): void {
    this.editedAlertId = alertId;
    this.isEditingAlert = true;
  }

  public onEditAlert(event: any): void {
    if (event) {
      this.alertsService.resetPaginations();
      this.initialTableData = [];
      this.alertsService.alertsRefresh(null, null);
    }
  }

  public onCancelEdit(event: any): void {
    if (event) {
      this.isEditingAlert = false;
      this.editedAlertId = '';
    }
  }

  // Função que faz a conversão e substitui , por ;
  private convertToCSV(arr: any): string {
    const content = [Object.keys(arr[0])].concat(arr);
    // Índice da coluna que tem a vírgula (algumas empresas usam no nome da regra)
    const indexColumnComma = 2;

    return content.map((item) => {
      const values = Object.values(item);
      return values.map((_, index) => {
        const rowValue = values[index].toString();
        return index === indexColumnComma ? rowValue : rowValue.replace(/,/g, ';');
      }).join(';');
    }).join('\n');
  }


  public onDownloadCSV(): void {

    Swal.fire({
      title: 'Download',
      text: 'O download do ficheiro CSV foi iniciado, você terá seu arquivo em instantes.',
      icon: 'success',
    })
    let content: any = [];

    this.dataSource.data.forEach((alert) => {
      console.log(alert)
      content.push({
        'Data': alert.date,
        'Instalação': alert.point,
        'Regra de alerta': alert.rule,
        'Ocorrências': alert.occurrences,
        'Status': alert.status
      });
    });

    let csvData = this.convertToCSV(content);
    let fileCSV = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(fileCSV);
    let a = document.createElement('a');
    a.href = url;

    a.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-alerts.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);

    console.log('Downloaded csv');
  }

  public onDownloadPDF(): void {
    Swal.fire({
      title: 'Download',
      text: 'O download do ficheiro PDF foi iniciado, você terá seu arquivo em instantes.',
      icon: 'success',
    })

    const header = ['date', 'point', 'rule', 'occurrences', 'status'];

    let content: any = [];

    this.dataSource.data.forEach((alert) => {
      content.push([
        alert.date,
        alert.point,
        alert.rule,
        alert.occurrences,
        alert.status
      ]);
    });

    let file: any = new jsPDF('l', 'mm', 'a4');

    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });

    file.save(`alerts.pdf`);
  }
}

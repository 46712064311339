import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserLogService } from 'src/shared/services/user-log.service';
import { jsPDF } from 'jspdf';
import { UserLog, PaginationInfo } from './user-log-assets/user-log-model';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { tzConvertISOEndDate, tzConvertISOStartDate } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-user-log',
  templateUrl: './user-log.component.html',
  styleUrls: ['./user-log.component.less']
})
export class UserLogComponent extends Unsubscriber implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private userLogService: UserLogService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngOnInit() { }

  // Armazena o ID da empresa selecionada (Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected')
    ? localStorage.getItem('lastCompanySelected')
    : null;

  // Variaveis responáveis por abrir e fechar as boxes
  public filterBox: boolean = false;
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  // Variaveis responsáveis pelos loadings da tela
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;

  // Função responsável por mudar o estado da box de filtro
  public filterBoxOpen(): void {
    this.filterBox = !this.filterBox;
  }

  /** Variáveis utilizadas para armazenar o filtro do usuário **/
  public operation: string;
  public startDatetime: any;
  public endDatetime: any;
  public serialNumber: string;
  public macAddress: string;
  public username: string;
  public requestedField: string;

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: UserLog[] = [];

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ["username", "operationType", "requestDatetime", "requestedField"];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<UserLog>(this.ELEMENT_DATA);

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterUserLogs(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterUserLogs(this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public FILE_DATA: UserLog[] = [];
  public isReady: boolean = false;

  /**
   * Função que configura o paginator.
   */
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Armazena os dados do formulário de filtro de logs, o valor é setado automáticamente pelo HTML
  public filterLogsForm: FormGroup = this.formBuilder.group({
    operation: null,
    startDateForm: null,
    endDateForm: null,
    requestedField: null,
    username: null,
  });

  /**
   * Função responsavel por filtrar logs
   */
  public filterUserLogs(cursor: string | null) {
    this.operation = this.filterLogsForm.get('operation')?.value || null;

    this.startDatetime = this.filterLogsForm.get('startDateForm')?.value
      ? tzConvertISOStartDate(this.filterLogsForm.get('startDateForm')?.value)
      : null;

    this.endDatetime = this.filterLogsForm.get('endDateForm')?.value
      ? tzConvertISOEndDate(this.filterLogsForm.get('endDateForm')?.value)
      : null;

    this.requestedField = this.filterLogsForm.get('requestedField')?.value || null;

    this.username = this.filterLogsForm.get('username')?.value || null;

    this.filterLoading = true;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscriptions = this.userLogService.filterLogs(
      this.companyId,
      this.operation,
      this.startDatetime,
      this.endDatetime,
      this.requestedField,
      this.username,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data.userLogs.count,
          response.data.userLogs.total,
          response.data.userLogs.pageInfo.hasNextPage,
          response.data.userLogs.pageInfo.hasPreviousPage,
          response.data.userLogs.pageInfo.startCursor,
          response.data.userLogs.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.isReady = false;
        this.length = this.paginationProperties.total;

        // Adiciona cada log retornado pela requisição na lista
        response.data.userLogs.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(new UserLog(
            node.node.user.username,
            node.node.operationType,
            node.node.requestDatetime,
            node.node.requestedField,
          ));
        });

        this.setDataSourceAttributes();

        if (this.ELEMENT_DATA.length === 0) {
          // Retorna um aviso de erro ao usuário
          Swal.fire({
            title: 'Não Encontrado',
            text: 'Não há registros encontrados para esta busca.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        this.filterLoading = false;
      })
    });
  }

  // Método para obter a chave de tradução correta com base em 'element.operation'
  public getTranslationKey(element: any): string {
    return `logs.form-${element.operationType}-label`;
  }

  // Método para obter a chave de tradução correta com base em 'element.operation'
  public getTranslationRequestedField(element: any): string {
    if (element.operationType === "query") {
      return `query.${element.requestedField}`;
    } else if (element.operationType === "mutation") {
      return `mutation.${element.requestedField}`;
    }

    return "";
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.
  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.userLogService.filterLogs(
        this.companyId,
        this.operation,
        this.startDatetime,
        this.endDatetime,
        this.requestedField,
        this.username,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.userLogs.edges.forEach((node: any) => {

            /** Tratamento de dados retornados da API **/
            this.FILE_DATA.push(
              new UserLog(
                node.node.user.username,
                node.node.operationType,
                node.node.requestDatetime,
                node.node.requestedField,
              )
            );
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.userLogs?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.userLogs?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).toString();
      })
      .join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.dataSource.data.forEach((log) => {
      content.push({
        Username: log.username,
        OperationType: log.operationType,
        RequestDatetime: log.requestDatetime,
        RequestedField: log.requestedField
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-user-log.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do CSV.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = ["Username", "OperationType", "RequestDatetime", "RequestedField"];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.dataSource.data.forEach((log) => {
      content.push([
        log.username,
        log.operationType,
        log.requestDatetime,
        log.requestedField
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-user-log.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
